import React from "react";
import Layout from "../components/AdmInterfaceEstadosCuenta";
import Sisbar from "../components/SisNavbar";

function SisAdmEstadosdeCuenta() {
  return (
    <div>
      <Sisbar />
      <Layout />
    </div>
  );
}

export default SisAdmEstadosdeCuenta;
