import React, { useState } from "react";
import Basicos from "../planeshome/Basico";
import Intermedios from "../planeshome/Intermedio";
import Elites from "../planeshome/Elite";
import Premiums from "../planeshome/Premium";

const Beneficiario = () => {
  const [activeStatus, setActiveStatus] = useState(1);
  return (
    <div>
      <div className="justify-between flex-wrap  sm:block bg-white  shadow ">
        <div className="xl:w-full xl:mx-0 pl-5 pr-5 h-12 ">
          <ul className="flex">
            <li
              onClick={() => setActiveStatus(1)}
              className={
                activeStatus === 1
                  ? "text-sm text-indigo-700 flex flex-col justify-between border-indigo-700 pt-3 rounded-t mr-10 font-normal"
                  : "text-sm text-gray-600 py-3 mr-10 font-normal cursor-pointer hover:text-gray-800"
              }
            >
              <span className="mb-3 cursor-pointer">
                {activeStatus === 1 ? "REQUISITOS" : "REQUISITOS"}
              </span>
              {activeStatus === 1 && (
                <div className="w-full h-1 bg-[#92d468] rounded-t-md" />
              )}
            </li>

            <li
              onClick={() => setActiveStatus(2)}
              className={
                activeStatus === 2
                  ? "text-sm text-indigo-700 flex flex-col justify-between border-indigo-700 pt-3 rounded-t mr-10 font-normal"
                  : "text-sm text-gray-600 py-3 mr-10 font-normal cursor-pointer hover:text-gray-800"
              }
            >
              <span className="mb-3 cursor-pointer">
                {activeStatus === 2 ? "BASICO" : "BASICO"}
              </span>
              {activeStatus === 2 && (
                <div className="w-full h-1 bg-[#92d468] rounded-t-md" />
              )}
            </li>
            <li
              onClick={() => setActiveStatus(3)}
              className={
                activeStatus === 3
                  ? "text-sm text-indigo-700 flex flex-col justify-between border-indigo-700 pt-3 rounded-t mr-10 font-normal"
                  : "text-sm text-gray-600 py-3 mr-10 font-normal cursor-pointer hover:text-gray-800"
              }
            >
              <span className="mb-3 cursor-pointer">
                {activeStatus === 3 ? "INTERMEDIO" : "INTERMEDIO"}
              </span>
              {activeStatus === 3 && (
                <div className="w-full h-1 bg-[#92d468] rounded-t-md" />
              )}
            </li>
            <li
              onClick={() => setActiveStatus(4)}
              className={
                activeStatus === 4
                  ? "text-sm text-indigo-700 flex flex-col justify-between border-indigo-700 pt-3 rounded-t mr-10 font-normal"
                  : "text-sm text-gray-600 py-3 mr-10 font-normal cursor-pointer hover:text-gray-800"
              }
            >
              <span className="mb-3 cursor-pointer">
                {activeStatus === 4 ? "ELITE" : "ELITE"}
              </span>
              {activeStatus === 4 && (
                <div className="w-full h-1 bg-[#92d468] rounded-t-md" />
              )}
            </li>

            <li
              onClick={() => setActiveStatus(5)}
              className={
                activeStatus === 5
                  ? "text-sm text-indigo-700 flex flex-col justify-between border-indigo-700 pt-3 rounded-t mr-10 font-normal"
                  : "text-sm text-gray-600 py-3 mr-10 font-normal cursor-pointer hover:text-gray-800"
              }
            >
              <span className="mb-3 cursor-pointer">
                {activeStatus === 5 ? "PREMIUM" : "PREMIUM"}
              </span>
              {activeStatus === 5 && (
                <div className="w-full h-1 bg-[#92d468] rounded-t-md" />
              )}
            </li>
          </ul>
          <td className=" pt-5"></td>
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full  mb-6 shadow-lg rounded  ">
            <div className="px-4 py-5 flex-auto">
              <div className="tab-content tab-space">
                <div
                  className={activeStatus === 1 ? "block" : "hidden"}
                  id="link1"
                >
                  <h1
                    role="heading"
                    className="md:text-3xl text-3xl font-bold leading-10 mt-3 text-gray-800"
                  >
                    REQUISITOS PARA SOLICITAR LOS SERVICIOS
                  </h1>
                  <ol class="list-decimal pt-10 leading-6 px-5">
                    <li>
                      Los servicios que ofrece el PRESTADOR se podrán solicitar
                      a través del Centro de Atención CA, de manera telefónica
                      al 555 747 9101 las 24 hrs. Del día los 365 días del año,
                      o al{" "}
                      <a
                        href="https://www.app-quam.net/whatsapp.html"
                        class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                      >
                        WhatsApp.
                      </a>
                    </li>
                    <li>
                      Los servicios serán prestados de acuerdo a las condiciones
                      generales del servicio.
                    </li>
                    <li>
                      El <strong>BENEFICIARIO</strong> o quien solicite, deberá
                      proporcionar su nombre, celular de contacto, número de
                      certificado, así como una breve descripción de las
                      circunstancias que originan el servicio.
                    </li>
                    <li>
                      El <strong>PRESTADOR</strong> se reserva el derecho de
                      solicitar según el caso, la identificación del
                      BENEFICIARIO. En caso necesario a consideración de El
                      PRESTADOR, se solicitará una reclamación por escrito bajo
                      protesta de decir la verdad.
                    </li>
                    <li>
                      Los servicios se prestarán dentro del territorio de la
                      República Mexicana y en las ciudades o localidades que
                      cuenten con la infraestructura de los servicios
                      contratados y de acuerdo a los alcances que se describen
                      en estas condiciones generales de la membresía de su
                      programa de beneficios.
                    </li>
                    <li>
                      Los programas de beneficios y asistencias, no es un
                      contrato de seguro. Por lo que respecto a estos servicios
                      los derechos de los usuarios están protegidos por la Ley
                      Federal de Protección al Consumidor.
                    </li>
                    <li>
                      Para la asistencia de MASCOTA: se debe entender como tal
                      al canino "perro" que pertenezca a el{" "}
                      <strong>BENEFICIARIO</strong> del programa y viva en el
                      mismo domicilio.
                    </li>
                    <li>
                      En la asistencia Funeraria, existe la figura de{" "}
                      <strong>DEUDO RESPONSABLE:</strong> Es la persona nombrada
                      por los deudos que comunicará al personal del{" "}
                      <strong>CA</strong> todas las decisiones tomadas por la
                      familia respecto al servicio. Los ejecutivos del{" "}
                      <strong>CA</strong> no atenderán instrucciones respecto a
                      la asistencia funeraria que no sean las indicadas por el{" "}
                      <strong>DEUDO RESPONSABLE</strong>.
                    </li>
                    <li>
                      NO tenemos ninguna exclusión de nuestros servicios por
                      profesión o actividad de los{" "}
                      <strong>BENEFICIARIOS</strong>.
                    </li>
                  </ol>
                </div>
                <div
                  className={activeStatus === 2 ? "block" : "hidden"}
                  id="link2"
                >
                  <Basicos />
                </div>
                <div
                  className={activeStatus === 3 ? "block" : "hidden"}
                  id="link3"
                >
                  <Intermedios />
                </div>
                <div
                  className={activeStatus === 4 ? "block" : "hidden"}
                  id="link3"
                >
                  <Elites />
                </div>
                <div
                  className={activeStatus === 5 ? "block" : "hidden"}
                  id="link3"
                >
                  <Premiums />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Beneficiario;
