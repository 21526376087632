import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const conservadora = [
  { fecha: "ene-23", anualizada: 4.92, cetes: 10.61 },
  { fecha: "feb-23", anualizada: 7.56, cetes: 10.92 },
  { fecha: "mzo-23", anualizada: 7.08, cetes: 11.23 },
  { fecha: "abr-23", anualizada: 13.8, cetes: 11.29 },
  { fecha: "may-23", anualizada: 19.2, cetes: 11.32 },
  { fecha: "jun-23", anualizada: 23.1, cetes: 11.16 },
  { fecha: "jul-23", anualizada: 45.6, cetes: 11.22 },
  { fecha: "ago-23", anualizada: 27.24, cetes: 11.16 },
  { fecha: "sep-23", anualizada: 35.4, cetes: 11.08 },
  { fecha: "oct-23", anualizada: 46.8, cetes: 11.08 },
  { fecha: "nov-23", anualizada: 40.8, cetes: 10.86 },
  { fecha: "dic-23", anualizada: 18, cetes: 11.21 },
];

export const Charts = () => {
  return (
    <div className=" justify-items-center  sm:relative lg:grid">
      <div className=" sm:w-90 w-90 lg:w-2/3 ">
        <ResponsiveContainer aspect={2}>
          <LineChart
            data={conservadora}
            width={500}
            height={300}
            margin={{ top: 10, right: 10, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis dataKey="fecha" />
            <YAxis />
            <Tooltip />
            <Legend />

            <Line
              type="monotone"
              dataKey="anualizada"
              name="SMART FINANCES tasa anualizada 
              "
              stroke="#14de2c"
              strokeWidth={2}
            />
            <Line
              type="monotone"
              dataKey="cetes"
              name="CETES a 28 días, tasa anualizada 
              "
              stroke="#e3971e"
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default Charts;
