import React from "react";
import { useNavigate } from "react-router-dom";

const PlanesEmpresariales = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="xl:mx-auto xl:container py-20 2xl:px-0 px-6">
        <div className="lg:flex items-center justify-between">
          <div className=" lg:w-1/2 w-full px-5">
            <h1
              role="heading"
              className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800"
            >
              Planes Empresariales
            </h1>
            <p className="text-bold font-bold leading-4 text-gray-600 mt-5">
              Condiciones Especiales
            </p>

            <ol class="list-decimal pt-10 ">
              <li>
                Colectivos genuinos: de al menos 10 titulares comprobables con
                una relación laboral con la Empresa.
              </li>
              <li>
                Suma Asegurada de un millon y dos millones de pesos. Aplica
                selección de riesgo médica sin rechazos.
              </li>
              <li>Edad máxima de cobertura: 69 años.</li>
              <li>
                Se manda la solicitud de los empleados mediante un layout. Se
                excluyen ocupaciones de alto riesgo.
              </li>
              <li>
                Se pueden incluir a todos los empleados nuevos, con el mismo
                precio sin importar edad y género.
              </li>
              <li>
                Con experiencia: tarifa según experiencia, suscripción de
                acuerdo a censo.
              </li>
              <li>
                Para dar cumplimiento al Art. 429 de la LISF y dar cabal
                cumplimiento a la identificación de los integrantes la Empresa
                (El Contratante) se obliga expresamente a identificar mediante
                sus controles internos a cada uno de los asegurados miembros de
                la colecctividad.
              </li>
              <li>
                Si el empleado deja de trabajar con la Empresa, puede mantener
                su membresía mediante un derecho de conversión y continuar con
                su protección en un plan individual. Conservando su antigüedad.
              </li>
            </ol>
            <div className="flex relative items-center cursor-pointer pb-4 md:pb-4">
              <button
                onClick={() => navigate("/formempresarial")}
                className="bg-[#1e49a6] w-[300px] rounded-md font-medium my-6 mx-auto py-3 text-white"
              >
                {" "}
                Solicita tú plan empresarial
              </button>
            </div>
          </div>

          <div
            className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8 "
            role="list"
          >
            <img
              src="https://i.ibb.co/0n6DSS3/bgimg.png"
              className="absolute w-full -ml-12 mt-24"
              alt="background circle images"
            />

            <div
              role="listitem"
              className="bg-white cursor-pointer shadow rounded-lg p-8 relative z-30 "
            >
              <div className="md:flex items-center justify-between">
                <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                  PLAN 1{" "}
                </h2>
                <p className=" text-xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800">
                  Suma Asegurada $ 1,000,000
                  <span className=" text-sm"> MXN</span>
                </p>
              </div>
              <div className="md:flex md:flex-row">
                <p className=" md:w-80 text-base leading-6 mt-4 text-gray-600 ">
                  <strong>De 10 a 50 personas</strong> <td></td>pago mensual -{" "}
                  <strong>
                    $ 1,070.00<span className=" text-xs">MXN</span>
                  </strong>{" "}
                  <td></td>Total -{" "}
                  <strong>
                    $ 12,840.00<span className=" text-xs">MXN</span>
                  </strong>{" "}
                  <td></td>Anual -{" "}
                  <strong>
                    $ 12,200.00<span className=" text-xs">MXN</span>
                  </strong>
                </p>
                <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                  <strong>De 51 a 100 personas</strong> <td></td>pago mensual -
                  <strong>
                    $ 1,030.00<span className=" text-xs">MXN</span>
                  </strong>{" "}
                  <td></td>Total -{" "}
                  <strong>
                    $ 12,360.00<span className=" text-xs">MXN</span>
                  </strong>{" "}
                  <td></td>Anual -{" "}
                  <strong>
                    $ 11,850.00<span className=" text-xs">MXN</span>
                  </strong>
                </p>
                <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                  <strong>Mas de 100 personas</strong> <td></td>pago mensual -{" "}
                  <strong>
                    $ 1,000.00<span className=" text-xs">MXN</span>
                  </strong>{" "}
                  <td></td>Total -{" "}
                  <strong>
                    $ 12,000.00<span className=" text-xs">MXN</span>
                  </strong>{" "}
                  <td></td>Anual -{" "}
                  <strong>
                    $ 11,500.00<span className=" text-xs">MXN</span>
                  </strong>
                </p>
              </div>
              <h2 className="mt-2">* Costos por Persona</h2>
            </div>
            <div
              role="listitem"
              className="bg-white cursor-pointer shadow rounded-lg mt-3 flex relative z-30"
            >
              <div className="w-2.5  h-auto bg-[#1e49a6] rounded-tl-md rounded-bl-md" />
              <div className="w-full p-8">
                <div className="md:flex items-center justify-between">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                    PLAN 2
                  </h2>
                  <p className=" text-xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800">
                    Suma Asegurada $ 2,000,000
                    <span className=" text-xs"> MXN</span>
                  </p>
                </div>
                <div className="md:flex md:flex-row">
                  <p className=" md:w-80 text-base leading-6 mt-4 text-gray-600 ">
                    <strong>De 10 a 50 personas</strong> <td></td>pago mensual -
                    <strong>
                      $ 1,350.00<span className=" text-xs">MXN</span>
                    </strong>{" "}
                    <td></td>Total -{" "}
                    <strong>
                      $ 16,200.00<span className=" text-xs">MXN</span>
                    </strong>{" "}
                    <td></td>Anual -{" "}
                    <strong>
                      $ 15,400.00<span className=" text-xs">MXN</span>
                    </strong>
                  </p>
                  <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                    <strong>De 51 a 100 personas</strong> <td></td>pago mensual
                    -
                    <strong>
                      $ 1,290.00<span className=" text-xs">MXN</span>
                    </strong>{" "}
                    <td></td>Total -{" "}
                    <strong>
                      $ 15,480.00<span className=" text-xs">MXN</span>
                    </strong>{" "}
                    <td></td>Anual -
                    <strong>
                      $ 15,000.00<span className=" text-xs">MXN</span>
                    </strong>
                  </p>
                  <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                    <strong>Mas de 100 personas</strong> <td></td>pago mensual -
                    <strong>
                      $ 1,200.00<span className=" text-xs">MXN</span>
                    </strong>{" "}
                    <td></td>Total -{" "}
                    <strong>
                      $ 14,400.00<span className=" text-xs">MXN</span>
                    </strong>{" "}
                    <td></td>Anual -{" "}
                    <strong>
                      $ 13,800.00<span className=" text-xs">MXN</span>
                    </strong>
                  </p>
                </div>
                <h2 className="mt-2">* Costos por Persona</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanesEmpresariales;
