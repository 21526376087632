import React from "react";
import Form from "../components/FormRegalo";
import { Toaster } from "react-hot-toast";

function FormRegalo() {
  return (
    <div>
      <Toaster position="top-center " duration="6000" />;
      <Form />
    </div>
  );
}

export default FormRegalo;
