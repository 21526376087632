import React from "react";
import { AiFillStar } from "react-icons/ai";

export default function index() {
  return (
    <div>
      <div className="">
        <section className="mx-auto container w-full py-20 px-1 ">
          <div className="flex flex-col justify-center items-center ">
            <div className="md:text-6xl text-4xl font-black text-center text-[#1e49a6]  leading-snug lg:w-3/4">
              <h2>LA DECISIÓN INTELIGENTE PARA TU VIDA</h2>
              <p className="  text-2xl leading-8 text-gray-500    mt-5">
                Te ofrecemos un concepto innovador de SALUD INTEGRAL para tus
                finanzas, salud física, mental y mucho más . . .
              </p>
            </div>
          </div>
          <div class="relative flex py-5 items-center">
            <div class="flex-grow border-t border-[#1e49a6]"></div>
            <span class="flex-shrink mx-4 text-yellow-300">
              <AiFillStar />
            </span>
            <div class="flex-grow border-t border-[#92d468]"></div>
          </div>
        </section>
      </div>
    </div>
  );
}
