import React from "react";
import { useNavigate, Link } from "react-router-dom";
import IndiBasic from "../assets/logospaquetes/1938377B-C631-4471-9B0D-402A1D32D131.png";
import IndiInter from "../assets/logospaquetes/451F90FA-DD27-4CC0-BF74-9EC34753D467.png";
import IndiElite from "../assets/logospaquetes/A0B0F58E-472B-48E6-BB4A-75942E7B1518.png";
import IndiPremium from "../assets/logospaquetes/A2E0E633-1754-421B-A92A-96CEF8574E7C.png";

function Preciosindi() {
  const navigate = useNavigate();
  return (
    <div>
      <section className="px-6 xl:px-0">
        <div className="mt-20 mx-auto container">
          <div className="flex flex-col lg:items-center justify-center w-full">
            <h1 className="font-semibold text-[#1e49a6] text-3xl md:text-4xl">
              PLANES INDIVIDUALES
            </h1>
            <p className="mt-2.5 lg:w-1/2 lg:text-center text-2xl font-semibold">
              Tenemos 4 planes que se acomodan a tus necesidades.
            </p>
            <p className="mt-2.5 lg:w-1/2 lg:text-center text-1xl font-semibold">
              Al solicitar tu producto, es necesario que llenes un formulario
              simple para recibir el certificado Con tus datos.
            </p>
          </div>
          <div className="flex items-center justify-center w-full">
            <div className="pt-14">
              <div className="container mx-auto">
                <div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                      <h4 className="text-2xl text-[#1e49a6] font-semibold pb-0">
                        Básico{" "}
                      </h4>
                      <h1 className=" font-semibold">
                        A quienes incluimos: Titular{" "}
                      </h1>

                      <div className=" flex justify-center pt-4   ">
                        <img width={90} src={IndiBasic} />
                      </div>

                      <ul className="flex flex-col mb-6 pt-7">
                        <h1 className=" font-bold">6 Beneficios: </h1>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Bienestar Financiero
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Telemedicina activa
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Nutricional
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Ayuda psicológica
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Fitness
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Club de descuentos
                          </p>
                        </li>

                        {/* NADAA SOLO ESTETICO */}
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>

                        {/* NADAA SOLO ESTETICO */}
                      </ul>

                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          49.00 <span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/Mensual</span>
                      </p>

                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          588.00<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/Anual</span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          529.20<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/De contado</span>
                      </p>

                      <button
                        className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3 "
                        onClick={() => navigate("/planesin")}
                      >
                        Mas Informacion
                      </button>
                      <a href="https://shopsf.smartsfinances.info/">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Elegir
                        </button>
                      </a>
                    </div>
                  </div>

                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                      <h4 className="text-2xl text-[#1e49a6] font-semibold pb-0">
                        Intermedio
                      </h4>
                      <h1 className="font-semibold">
                        A quienes incluimos: Titular{" "}
                      </h1>
                      <div className=" flex justify-center pt-4   ">
                        <img width={90} src={IndiInter} />
                      </div>
                      <ul className="flex flex-col mb-6 pt-7">
                        <h1 className="font-bold">10 Beneficios: </h1>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-bold">
                            Los Básicos mas :{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Ambulancia
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Teleconsulta legal
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Localización de mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        {/* NADAA SOLO ESTETICO */}
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        {/* NADAA SOLO ESTETICO */}
                      </ul>
                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          114.00<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/Mensual</span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          1,368.00<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/Anual</span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          1,231.20<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/De contado</span>
                      </p>
                      <button
                        className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3 "
                        onClick={() => navigate("/planesin")}
                      >
                        Mas Informacion
                      </button>
                      <a href="https://shopsf.smartsfinances.info/">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Elegir
                        </button>
                      </a>
                    </div>
                  </div>
                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                      <h4 className="text-2xl text-[#1e49a6] font-semibold pb-0">
                        Elite
                      </h4>
                      <h1 className="font-semibold">
                        A quienes incluimos: <td></td>a).-Titular soltero y sus
                        padres <td></td>b).-Titular casado, cónyuge e hijos
                        menores de 25 años.
                      </h1>
                      <div className=" flex justify-center pt-4   ">
                        <img width={90} src={IndiElite} />
                      </div>
                      <ul className="flex flex-col mb-6 pt-7">
                        <h1 className="font-bold">31 Beneficios: </h1>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-bold">
                            Los Básicos, intermedios, más:{" "}
                          </p>
                        </li>

                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Dental, visión, descuentos en laboratorios,
                            medicamentos, clinicas y hospitales.
                          </p>
                        </li>

                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Costo preferente en medico general, especialista y
                            alta especialidad{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Servicio integral funerario{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Plataforma E-Learning{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Asistencia emergencia en el hogar y legal
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Importantes Beneficios para tu mascota
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Dispositivos digitales y servicio de grúa{" "}
                          </p>
                        </li>
                      </ul>
                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          274.00<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/Mensual</span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          3,288.00<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/Anual</span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          2,959.20<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/De contado</span>
                      </p>
                      <button
                        className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3 "
                        onClick={() => navigate("/planesin")}
                      >
                        Mas Informacion
                      </button>
                      <a href="https://shopsf.smartsfinances.info/">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Elegir
                        </button>
                      </a>
                    </div>
                  </div>

                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-gray-400 border border-gray-200 shadow rounded-lg text-left">
                      <h4 className="text-2xl text-black font-semibold pb-0">
                        Premium
                      </h4>
                      <h1 className="font-semibold text-white">
                        A quienes incluimos: <td></td> a).-Titular soltero y sus
                        padres <td></td>b).-Titular casado, cónyuge e hijos
                        menores de 25 años.
                      </h1>
                      <div className=" flex justify-center pt-4   ">
                        <img width={90} src={IndiPremium} />
                      </div>
                      <ul className="flex flex-col mb-6 pt-7">
                        <h1 className="font-bold text-black">
                          54 Beneficios:{" "}
                        </h1>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-bold">
                            Los Básicos, intermedios, Elite, más:{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Medico a domicilio
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Servicios complementarios Funerarios, Seguros de
                            vida, muerte accidental y GM por accidente.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Mayores beneficios para tu mascota.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4 "
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Asistencia vial integral.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4 "
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Asistencia integral en viajes nacionales.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4 "
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Asistencia en viajes nacionales aéreos.{" "}
                          </p>
                        </li>
                      </ul>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          979.00<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/Mensual</span>
                      </p>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          11,748.00<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/Anual</span>
                      </p>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          10,573.20<span className=" text-sm">MXN</span>
                        </span>
                        <span className="font-light text-lg">/De contado</span>
                      </p>
                      <button
                        className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3 "
                        onClick={() => navigate("/planesin")}
                      >
                        Mas Informacion
                      </button>
                      <a href="https://shopsf.smartsfinances.info/">
                        <button className="mt-5 w-full text-[#1e49a6] focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-gray-100 px-8 py-3 text-base font-semibold py-3">
                          Elegir
                        </button>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html: "",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <style>
        {` 
            .checkbox:checked {
                right: 0;
                background-color: #4338ca;
            }
            `}
      </style>
    </div>
  );
}

export default Preciosindi;
