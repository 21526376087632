import React, { useContext } from "react";
import Infoespecial from "../components/sishome/Especial";
import Infobeneficiario from "../components/sishome/Beneficiario";
import Infoinversionista from "./sishome/Inversionista";
import { AuthContext } from "../context/AuthContext";

const HomeInterface = () => {
  const { onHandleLogOut, currentUser } = useContext(AuthContext);
  return (
    <div>
      <div className="absolute   w-full   h-full bg-[#2b3e50] ">
        {/* Page title starts */}
        {currentUser.rol === "especial" && <Infoespecial />}
        {currentUser.rol === "beneficiario" && <Infobeneficiario />}
        {currentUser.rol === "inversionista" && <Infoinversionista />}
        {/* Page table  ends */}
      </div>
    </div>
  );
};

export default HomeInterface;
