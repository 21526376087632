import React, { useState } from "react";

const Premium = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [open14, setOpen14] = useState(false);
  const [open15, setOpen15] = useState(false);
  const [open16, setOpen16] = useState(false);
  const [open17, setOpen17] = useState(false);
  const [open18, setOpen18] = useState(false);
  const [open19, setOpen19] = useState(false);
  const [open20, setOpen20] = useState(false);
  const [open21, setOpen21] = useState(false);
  const [open22, setOpen22] = useState(false);

  return (
    <div className=" lg:container lg:mx-auto lg:py-16 md:py-12 md:px-6 py-12 px-4 ">
      <h1 className="text-center lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 font-semibold">
        PROGRAMA PREMIUM
      </h1>

      <div className=" lg:mt-12 bg-gray-100 md:mt-10 mt-8 lg:py-7 lg:px-6 md:p-6 py-6 px-4 lg:w-8/12 w-full mx-auto ">
        <div className=" flex justify-between md:flex-row flex-col ">
          <div className=" md:mb-0 mb-8 md:text-left text-center">
            <h2 className=" font-medium text-xl leading-5 text-gray-800 lg:mb-2 mb-4">
              53 Beneficios
            </h2>
            <p className=" font-normal text-lg leading-5 text-gray-600   ">
              Los siguientes apartados son todos los beneficios de los planes{" "}
              <strong>BASICO, INTERMEDIO, ELITE</strong>, más:
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-8/12 w-full mx-auto">
        {/* <!-- Question 1 --> */}
        <hr className=" w-full lg:mt-10 md:mt-12 md:mb-8 my-8" />

        <div className="w-full md:px-6  ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className=" ">
              <p className="flex justify-center items-center font-medium text-lg leading-6 md:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  1.
                </span>{" "}
                Medico general a domicilio
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen(!open)}
            >
              <svg
                className={"transform " + (open ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año.</li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> pone a disposición del{" "}
                <strong>BENEFICIARIO</strong>, en las principales ciudades del
                territorio nacional, su servicio de consulta médica a domicilio
                para pacientes que por alguna razón no puedan trasladarse a
                consultorio. El mismo deberá ser solicitado al{" "}
                <strong>CA</strong>, para coordinar el envío de un médico
                general certificado.
              </li>
              <li>
                El médico valorará al paciente o al{" "}
                <strong>BENEFICIARIO</strong> y en caso necesario le extenderá
                la receta médica correspondiente. Este servicio tendrá un costo
                preferencial de $200 MXN, el cual estará a cargo del{" "}
                <strong>BENEFICIARIO</strong>, por cada consulta médica que
                solicite.
              </li>
              <li>
                Tiempo estimado de arribo del médico es de 90 minutos, pero este
                puede variar, dependiendo de la zona en la que se encuentre el
                domicilio del <strong>BENEFICIARIO</strong> y de las
                circunstancias del traslado. El <strong>PRESTADOR</strong>{" "}
                proporcionará la mejor opción y dará solución para salvaguardar
                la salud del <strong>BENEFICIARIO</strong>.{" "}
              </li>
              <li>
                Este servicio tiene un costo preferencial de $200 (doscientos
                pesos 00/100 M.N.) y está limitado a 2 eventos en el año de
                vigencia. El <strong>PRESTADOR</strong> se hará cargo del
                servicio hasta un costo de $800 (ochocientos pesos M.N.) por
                cada consulta médica. En caso de qué se trate de un paciente
                sospechoso o confirmado de COVID se le informará a “EL{" "}
                <strong>BENEFICIARIO</strong> el costo excedente del cual deberá
                hacerse cargo.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> quedará eximido de cualquier
                responsabilidad o del cumplimiento de sus obligaciones derivadas
                de los servicios que integran la asistencia médica a domicilio,
                cuando:
              </li>
              <li>
                Cuando el <strong>BENEFICIARIO</strong> solicite medicamentos,
                tratamientos, estudios, análisis o radiografías.
              </li>
              <li>
                Cuando se solicite el servicio en lugares inhóspitos, rancherías
                o sin infraestructura médica o se trate de casos infecciosos que
                por su severidad deban ser atendidos directamente en un
                hospital.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 2 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  2.
                </span>{" "}
                Repatriación Funeraria
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen2(!open2)}
            >
              <svg
                className={"transform " + (open2 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open2 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año.</li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> pone a disposición del{" "}
                <strong>DEUDO RESPONSABLE</strong> su red internacional de casas
                funerarias para el servicio de repatriación de restos humanos en
                caso de fallecimiento del EL
                <strong> MIGRANTE</strong>, siempre que su deceso haya ocurrido
                en los Estados Unidos Continental, es decir que se encuentran
                excluidos todos los territorios insulares u otras posesiones
                como Alaska, Hawái, Puerto Rico, entre otros.{" "}
              </li>
              <li>
                El <strong>DEUDO RESPONSABLE</strong> es la persona designada
                como responsable que deberá comunicar al personal del CA todas
                las decisiones de los deudos relacionados con el servicio
                funerario.
              </li>
              <li>
                Repatriación del cuerpo al territorio nacional. Este servicio
                ampara lo siguiente: Previa entrega del certificado de defunción
                extendido por el hospital o dependencia de gobierno a los
                familiares y/o a nuestro asesor, se procede sin ningún costo a:
                <td></td>a. Recolección del cuerpo en la ciudad de origen
                (Residencia).
                <td></td>b. Trámites legales, consulares para la salida del país
                <td></td>c. Traducción de toda la documentación al español.
                <td></td>d. Preparación del cuerpo embalsamamiento y
                tanatopraxia.
                <td></td>e. Proveer un féretro hermético para la repatriación.
                <td></td>f. Funda de protección para el revestimiento del
                féretro para transporte aéreo.
                <td></td>g. Embalaje especial del féretro para el transporte
                aéreo.
                <td></td>h. Transporte aéreo o terrestre del cuerpo desde la
                ciudad de origen al destino.
                <td></td>i. Trámites legales de aduana para la entrega del
                cuerpo en el Aeropuerto Internacional.
                <td></td>j. Traslado hasta el lugar de velación.
              </li>
              <li>
                El servicio de traslado de restos humanos tiene una cobertura de
                1 evento con un límite de $5,000 USD topado a un máximo de tipo
                de cambio con el peso mexicano de $21 pesos (Veintiún pesos
                00/100 M.N.)
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> queda facultado para declinar la
                solicitud de servicio o quedará eximido de cualquier
                responsabilidad o del cumplimiento de sus obligaciones derivadas
                de los servicios que integran la asistencia funeraria, cuando:{" "}
              </li>
              <li>
                Los Familiares o <strong>DEUDO RESPONSABLE</strong> no reporten
                con oportunidad del deceso y el <strong>PRESTADOR</strong> se
                encuentre en imposibilidad material de cumplir con el servicio,
                porque el servicio ya se encuentra en operación previa
                contratación de los familiares con otro proveedor o ya se
                hubiera realizado el servicio sin conocimiento previo y oportuno
                por parte del <strong>PRESTADOR</strong>.
              </li>
              <li>
                Los familiares o <strong>DEUDO RESPONSABLE</strong> no tengan la
                documentación solicitada.
              </li>
              <li>
                Que el fallecimiento se deba a enfermedades infectocontagiosas
                de alto riesgo y que las autoridades no permitan que se efectúe
                el traslado.
              </li>
              <li>Exista algún impedimento legal para efectuar el traslado.</li>
              <li>
                Que la muerte del <strong>MIGRANTE</strong> es debido a que
                atento contra su vida.
              </li>
              <li>
                Que el <strong> MIGRANTE</strong> no este dado de alta en la
                base de datos vigente del <strong>PRESTADOR</strong>.
              </li>
              <li>
                Si no se contaran con los permisos de las autoridades
                correspondientes.{" "}
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 3 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  3.
                </span>
                Seguro de accidentes personales: muerte accidental
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen3(!open3)}
            >
              <svg
                className={"transform " + (open ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open3 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                El <strong>PRESTADOR</strong> cuenta con una pre suscripción de
                parte de su agente de seguros, de una póliza de seguro colectivo
                de AP (accidentes personales) emitida por una Compañía
                Aseguradora legalmente autorizada.
              </li>
              <li>
                En la póliza referida se establecen las siguientes coberturas
                para este programa de beneficios:
              </li>
              <li>
                Suma Asegurada por muerte accidental $150,000 (ciento cincuenta
                mil pesos 00/100 m.n.) sin que se aplique deducible en los casos
                de reclamación.
              </li>
            </ul>
          </div>
        </div>

        {/* <!-- Question 4 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6  ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  4.
                </span>
                Gastos médicos por accidente
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen4(!open4)}
            >
              <svg
                className={"transform " + (open4 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open4 ? "block" : "hidden")}
          >
            <ul class="list-decimal">
              <li>
                Suma Asegurada de reembolso de gastos médicos por $20,000
                (veinte mil pesos 00/100 m.n.) sin que se aplique deducible.{" "}
              </li>
              <li>
                Una vez que la <strong>ASEGURADORA</strong> reciba por conducto
                del <strong>PRESTADOR</strong> los listados de los beneficiarios
                a asegurar, ésta procederá a sumarlos a la Póliza Colectiva.{" "}
              </li>
              <li>
                De todos los servicios que integran el programa esta prestación
                es la única cobertura de seguro y está sujeta a las condiciones
                particulares y generales de la póliza de referencia y pueden ser
                consultadas en la URL:{" "}
                <a className=" text-cyan-500">https://bit.ly/3x1qdCR</a>
              </li>
              <li>
                En caso de siniestro favor de tener a la mano el número de
                póliza AP30001688, el nombre del contratante es el{" "}
                <strong>PRESTADOR</strong>.
              </li>
              <li>
                Es importante que conozcas las limitaciones y excluyentes
                especiales de tu póliza, por eso te invitamos a consultar los
                Endosos. (
                <a className=" text-cyan-500">https://bit.ly/3Ob4uTN</a>)
              </li>
              <li>
                En caso de que requieras hacer uso de la cobertura de gastos
                médicos por accidente, deberás acudir a alguno de los hospitales
                de la red. ({" "}
                <a className=" text-cyan-500">https://bit.ly/46NWj70</a>)
              </li>
              <li>
                Si no te es posible puedes acudir al hospital de tu preferencia,
                tomando en cuenta que la aseguradora revisará las facturas, ya
                que no tiene convenio con todos los hospitales y se aplicarán
                las condiciones generales y endosos mencionados anteriormente.{" "}
              </li>
              <li>
                Los formatos para la reclamación se pueden obtener en{" "}
                <a className=" text-cyan-500">https://bit.ly/3NSpoG0 </a> las
                coberturas del seguro están sujeta a las condiciones
                particulares y generales de la póliza del seguro.
                <td></td>Al momento de solicitarlo te pedimos que te comuniques
                con nuestro “CA” para realizar la reclamación por causa de
                muerte accidental, nuestro personal capacitado te guiará y
                asistirá durante este proceso.<td></td>Para llevar a cabo este
                trámite te pedimos seas tan amable de descargar el siguiente
                Formulario (
                <a className=" text-cyan-500">https://bit.ly/3Z6Q0bv</a>)
              </li>
            </ul>
          </div>
        </div>

        {/* <!-- Question 5 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  5.
                </span>
                Seguro de vida
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen5(!open5)}
            >
              <svg
                className={"transform " + (open5 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open5 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                El <strong>PRESTADOR</strong> cuenta con una presuscripción de
                parte de su agente de seguros, de seguro de vida emitida por una
                compañía aseguradora legalmente autorizada.{" "}
              </li>
              <li>
                Este Beneficio o Asistencia, es la única que aplica sólo para el
                titular del PROGRAMA DE BENEFICIOS.
              </li>
              <li>
                En la póliza se establecerá la siguiente cobertura para este
                programa de beneficios: Suma Asegurada por $100,000 pesos (cien
                mil pesos 00/100 m.n.) en caso de muerte del titular.
              </li>
              <li>
                Una vez que la <strong>ASEGURADORA</strong> reciba por conducto
                de el <strong>PRESTADOR</strong> los listados de los
                beneficiarios a asegurar, éste procederá a sumarlos a la póliza
                colectiva.
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> podrá solicitar al{" "}
                <strong>CA</strong> el número de póliza y los formatos de
                reclamación y así como las condiciones generales y particulares
                del seguro de vida.{" "}
              </li>
              <li>
                De todos los servicios que integran el programa, las coberturas
                de estas pólizas, son las únicas prestaciones que están sujetas
                a las condiciones particulares y generales de la póliza del
                seguro.{" "}
              </li>
            </ul>
          </div>
        </div>

        <hr className=" w-full lg:mt-10 my-8" />
        {/* <!-- Question 5 --> */}

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  6.
                </span>
                Orientación veterinaria telefónica
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen6(!open6)}
            >
              <svg
                className={"transform " + (open6 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open6 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año</li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ul class="list-decimal">
              <li>
                El <strong>BENEFICIARIO</strong> dueño de la mascota (perro) en
                caso de requerir información u orientación acerca de su mascota,
                el <strong>PRESTADOR</strong> pondrá a su disposición vía
                telefónica o WhatsApp un veterinario pueda ser consultado
                respecto a las molestias, comportamiento o síntomas que presente
                su mascota.{" "}
              </li>
              <li>
                También podrá obtener información referente a alimentos, vacunas
                o medicamentos.
              </li>
              <li>
                Este servicio no tiene costo ni límite de eventos para el
                <strong>BENEFICIARIO</strong>, durante la vigencia de su
                programa de beneficios.
              </li>
            </ul>
          </div>
        </div>

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  7.
                </span>
                Pet funeral
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen7(!open7)}
            >
              <svg
                className={"transform " + (open7 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open7 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Cuando la mascota (perro) propiedad del{" "}
                <strong>BENEFICIARIO</strong> fallezca a consecuencia de un
                accidente o enfermedad, se podrá comunicar al PRESTADOR para que
                organice el traslado a primera hora disponible, desde el lugar
                en que se encuentre la mascota fallecida (casa o centro
                veterinario), al crematorio especializado en mascotas en la
                misma ciudad.{" "}
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                La mascota será cremada en forma individual y se entregarán sus
                cenizas en una urna tipo estándar con el nombre de la mascota en
                las instalaciones del proveedor asignado.{" "}
              </li>
              <li>
                Este servicio en todo momento se brindará con el máximo respecto
                al animal y a sus dueños.{" "}
              </li>
              <li>
                Para hacer efectivo este servicio, el{" "}
                <strong>BENEFICIARIO</strong> deberá enviar al correo
                electrónico que se le indique, los siguientes documentos
                escaneados:
                <td></td>Carta firmada, mencionando el nombre, edad y peso
                aproximado del perro, relatará brevemente el día, la hora, así
                como la forma en que murió la mascota, nombre y celular del
                propietario del animal, además deberá mencionar explícitamente
                que el dueño ha dado su consentimiento para cremar a la mascota.
                <td></td>Identificación oficial del{" "}
                <strong>BENEFICIARIO</strong>.<td></td>Fotos de la mascota.
              </li>
              <li>
                El <strong>PRESTADOR</strong> se reserva el derecho de verificar
                a través de uno de sus veterinarios la veracidad de la
                declaración escrita enviada por el <strong>BENEFICIARIO</strong>
                .{" "}
              </li>
              <li>
                Esta cobertura tiene un límite máximo de hasta $1,500 (Un mil
                quinientos pesos 00/100 M.N.) y 1 evento al año.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> queda facultado para declinar la
                solicitud de servicio o quedará eximido de cualquier
                responsabilidad o del cumplimiento de sus obligaciones derivadas
                de los servicios que integran la asistencia Pet funeral, cuando:
                <td></td>La mascota al momento de su fallecimiento cuente con
                una edad mayor a 10 años.
                <td></td>Cuando la mascota haya fallecido en una competencia de
                cualquier tipo.
                <td></td>Cuando el peso de la mascota exceda los 10 kilos.
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  8.
                </span>
                Auxilio vial: legal automovilística
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen8(!open8)}
            >
              <svg
                className={"transform " + (open8 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open8 ? "block" : "hidden")}
          >
            <h1 className="mt-3 font-semibold">AUXILIO VIAL </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> enviará un técnico especializado a
                el <strong>BENEFICIARIO</strong> dentro del territorio de la
                República Mexicana cuando su vehículo requiera alguno de los
                siguientes servicios:
                <td></td>Paso de corriente.
                <td></td>Envío de gasolina de hasta 10 litros, con cargo a el{" "}
                <strong>BENEFICIARIO</strong> al precio vigente.
                <td></td>Cambio de llanta.
              </li>
              <li>
                Este servicio tiene un límite de cobertura de $500 (quinientos
                pesos 00/100 M.N.) por evento y un límite de 2 eventos
                combinados por año de vigencia.{" "}
              </li>
              <li>
                En el caso de arrastre de grúa y auxilio vial, el costo de las
                casetas de peaje estará a cargo del{" "}
                <strong>BENEFICIARIO</strong>.{" "}
              </li>
              <li>
                Para los servicios antes descritos el <strong>PRESTADOR</strong>{" "}
                recibirá la llamada del <strong>BENEFICIARIO</strong> y
                organizará la prestación del servicio.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> vigilará el desarrollo del
                servicio y supervisará que el mismo cumpla con los estándares de
                calidad en cuanto a tiempo y eficiencia.{" "}
              </li>
              <li>
                Este servicio sólo es procedente para vehículos cuyo peso no
                exceda de las 2.5 toneladas, quedan excluidos los vehículos que
                no sean de servicio particular, como taxis, uber, carga, etc.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">LEGAL AUTOMOVILISTICA </h1>
            <ul class="list-disc">
              <li>
                Horario: el CA le atiende los 365 días del año, las 24 horas del
                día, para solicitar el servicio.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> pone a disposición del{" "}
                <strong>BENEFICIARIO</strong> su plantilla de abogados para que
                lo coadyuven en caso de que haya sufrido un accidente de
                tránsito que requiera la intervención de la autoridad.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> asignará a uno de sus abogados
                para que actúe como defensor del <strong>BENEFICIARIO</strong>{" "}
                cuando se haya aperturado una carpeta de investigación.
                <td></td>El abogado se encargará de conocer los hechos y las
                circunstancias del accidente, así como del interés o pretensión
                del <strong>BENEFICIARIO</strong> para formular una estrategia
                legal adecuada al objetivo señalado por este.
              </li>
              <li>
                Si en el curso de la investigación se requiere la libertad del
                conductor o de la unidad involucrada en el accidente, el Abogado
                orientará al el <strong>BENEFICIARIO</strong> sobre las
                garantías que deben ser aportadas para obtener la liberación
                correspondiente. El costo de las garantías será a cargo del{" "}
                <strong>BENEFICIARIO</strong>.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> se encargará de gestionar una
                tarifa preferencial respecto de los honorarios del abogado,
                vigilará la prestación del servicio y supervisará que el mismo
                cumpla con los estándares de calidad en cuanto a tiempo y
                eficiencia.{" "}
              </li>
              <li>
                Este servicio es con costo preferencial para el usuario sin
                límite de eventos.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  9.
                </span>
                Taxi seguro por accidente o robo del automóvil
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen9(!open9)}
            >
              <svg
                className={"transform " + (open9 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open9 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Horario: el CA le atiende los 365 días del año, las 24 horas del
                día, para solicitar el servicio.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                En caso de que el <strong>BENEFICIARIO</strong> sufra el robo de
                su vehículo o a causa de un accidente que impida su traslado
                regular en este, el <strong>PRESTADOR</strong> se encargará de
                enviar un taxi para que pueda regresar a su domicilio de manera
                segura.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> se encargará de supervisar el
                traslado para asegurar que se cumplan con los estándares de
                calidad.{" "}
              </li>
              <li>
                Este servicio es ilimitado durante la vigencia de su programa de
                beneficios y está limitado a $200 pesos (doscientos pesos 00/100
                m.n.) por evento{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  10.
                </span>
                Cerrajero automotriz
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen10(!open10)}
            >
              <svg
                className={"transform " + (open10 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open10 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Horario: de 9 am. A 6 pm. CDMX de lunes a domingo. Se puede
                solicitar en horario nocturno y se brindará el servicio el día
                siguiente lo más temprano posible.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> brindará los servicios de técnicos
                especializados en cerrajería automotriz, en las principales
                ciudades del país.{" "}
              </li>
              <li>
                Previa solicitud del <strong>BENEFICIARIO</strong> para abrir el
                automóvil propiedad de éste, en caso de que haya olvidado las
                llaves en el interior del vehículo, para lo cual deberá enviar
                por email o WhatsApp fotos del vehículo, así como copia de su
                identificación.{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> deberá de comunicarse al{" "}
                <strong>CA</strong>, para hacer de su conocimiento la situación,
                proporcionándole la ubicación exacta de donde se encuentre el
                vehículo para poder agendar la llegada de un cerrajero de la red
                del <strong>PROVEEDOR</strong>.{" "}
              </li>
              <li>
                El personal del <strong>CA</strong> coordinará el servicio
                enviando al técnico en cerrajería que se encuentre más cercano y
                disponible, el tiempo estimado de arribo del especialista es de
                90 minutos, sin embargo, este tiempo puede variar. Si durante el
                horario nocturno no es posible enviar a un técnico, se
                concertará una cita a la mayor brevedad con el primer proveedor
                disponible.{" "}
              </li>
              <li>
                El técnico del <strong>PRESTADOR</strong> realizará la revisión
                del vehículo, tomará fotografías o video y comunicará su reporte
                al <strong>CA</strong> para verificar la procedencia del
                servicio y el costo cotizado por el especialista, antes de
                proceder a abrir el vehículo sin desarmar o dañar la cerradura,
                con herramientas especializadas.{" "}
              </li>
              <li>
                Este servicio tiene un límite de costo de $800 (ochocientos
                pesos 00/100 M.N.) y un límite de cobertura de 1 evento durante
                la vigencia del programa.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> queda facultado para declinar la
                solicitud de servicio o quedará eximido del cumplimiento de sus
                obligaciones derivadas de los servicios que integran esta
                asistencia, cuando:{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> no acredito con documento
                fehaciente como tarjeta de circulación, así como presentar su
                identificación oficial, para determinar que el vehículo para el
                cual se solicitó el servicio sea de su propiedad. El documento
                debe de ser aquel que avale que el auto es propiedad del titular
              </li>
              <li>
                Se trate de la fabricación de duplicados de llaves de cualquier
                tipo o cambios de combinación.
              </li>
              <li>
                Este servicio únicamente se limita a la apertura de la puerta
                del vehículo, sin estar obligado en ningún momento el personal
                asignado a apoyar en el encendido del vehículo.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  11.
                </span>
                Viajes nacionales: arrastre de grúa
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen11(!open11)}
            >
              <svg
                className={"transform " + (open11 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open11 ? "block" : "hidden")}
          >
            <h1 className="mt-3 font-semibold">VIAJES NACIONALES </h1>
            <ul class="list-disc">
              <li>
                Horario: el <strong>CA</strong> le atiende los 365 días del año,
                las 24 horas del día, para solicitar el servicio.
              </li>
              <li>
                El <strong>PRESTADOR</strong> brindará este servicio única y
                exclusivamente para el TITULAR del programa de beneficios,
                siempre que el viaje tenga una duración máxima de 7 días y se
                encuentre a 100 kms. Como mínimo respecto del domicilio regular
                del titular y de acuerdo a los siguientes alcances y coberturas:{" "}
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <h1 className="mt-3 font-semibold">
              ARRASTRE DE GRÚA AL TALLER MÁS CERCANO{" "}
            </h1>
            <ol class="list-decimal">
              <li>
                En caso de que el vehículo del <strong>BENEFICIARIO</strong>{" "}
                sufra una avería que no pueda ser reparada durante su viaje, el
                PRESTADOR organizará y tomará a su cargo los servicios de
                remolque hasta el taller más cercano, designado de común acuerdo
                con el <strong>BENEFICIARIO</strong>.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> sólo se hará cargo de este
                servicio hasta un arrastre máximo de 80 kilómetros o el
                equivalente a $1,500 (un mil quinientos pesos 00/100 m.n.), lo
                que ocurra primero. El
                <strong> BENEFICIARIO</strong> se hará cargo del costo excedente
                el cual le será informado al momento de solicitar el servicio.{" "}
              </li>
              <li>
                En todos los casos el <strong>BENEFICIARIO</strong> deberá
                acompañar al proveedor de la grúa durante el traslado.{" "}
              </li>
              <li>
                El servicio de arrastre de grúa en viajes nacionales está
                limitado a 1 evento por vigencia anual del contrato.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> vigilará el desarrollo del
                servicio y supervisará que el mismo cumpla con los estándares de
                calidad en cuanto a tiempo y eficiencia.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                Este servicio solo es procedente siempre y cuando el{" "}
                <strong>BENEFICIARIO</strong> no se encuentre en su ciudad de
                residencia.{" "}
              </li>
              <li>
                Exclusivo para vehículos cuyo peso no exceda de las 2.5
                toneladas.{" "}
              </li>
              <li>
                Quedan excluidos los vehículos que no sean de servicio
                particular, como taxis, uber, carga, etc.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  12.
                </span>
                Referencia de talleres mecánicos y eléctricos
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen12(!open12)}
            >
              <svg
                className={"transform " + (open12 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open12 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                El <strong>BENEFICIARIO</strong> podrá solicitar vía telefónica
                sin límite de eventos durante su viaje, referencia de talleres
                mecánicos y eléctricos, horario de labores, ubicación y
                concertación de una cita por medio del <strong>CA</strong> de el{" "}
                <strong>PRESTADOR</strong>.{" "}
              </li>
              <li>
                Este servicio no tiene límite de eventos ni costo durante el
                viaje del <strong>BENEFICIARIO</strong>.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  13.
                </span>
                Localización de refacciones
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen13(!open13)}
            >
              <svg
                className={"transform " + (open13 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open13 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                Si durante el viaje, el vehículo del{" "}
                <strong>BENEFICIARIO</strong> requiere reparación y se necesitan
                piezas específicas para su arreglo, el
                <strong> PRESTADOR</strong> se encargará de localizar las
                tiendas que cuenten con dichas refacciones. El{" "}
                <strong>PRESTADOR</strong> proporcionará a el{" "}
                <strong>BENEFICIARIO </strong>
                información precisa sobre los horarios y ubicaciones de las
                tiendas para que pueda adquirir las piezas necesarias y
                continuar su trayecto.{" "}
              </li>
              <li>
                Este servicio de ubicación de tiendas no tiene límite de eventos
                durante la duración del viaje del <strong>BENEFICIARIO</strong>.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> queda excluido de sus
                responsabilidades cuando el
                <strong> BENEFICIARIO</strong> solicite el pago de las
                refacciones o precios preferenciales.
              </li>
              <li>
                El <strong>PRESTADOR</strong> no es responsable de la
                disponibilidad, calidad o costo de las refacciones, ya que su
                única función es brindar información y ayuda para localizar las
                tiendas que las venden.
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  14.
                </span>
                Regreso anticipado por fallecimiento de un familiar en primer
                grado
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen14(!open14)}
            >
              <svg
                className={"transform " + (open14 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open14 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                En caso de que, durante el viaje del{" "}
                <strong>BENEFICIARIO</strong>, fallezca uno de sus familiares en
                primer grado (padre, madre, hermano, hijo o cónyuge) y sea
                necesario su regreso anticipado, el <strong>PRESTADOR</strong>
                brindará los siguientes servicios:{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> se encargará de gestionar el
                cambio de fecha y hora del pasaje de regreso del{" "}
                <strong>BENEFICIARIO</strong> a su lugar de origen, en la medida
                de lo posible y sujeto a las condiciones del billete original.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> proporcionará información sobre
                las opciones de transporte disponibles para el regreso
                anticipado, incluyendo tarifas, horarios y disponibilidad.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> cubrirá los gastos
                correspondientes, al cambio de fecha y hora del billete de avión
                o transporte terrestre hasta un límite de cobertura de $3,000
                (tres mil pesos 00/100 m.n.) y un evento por vigencia anual.{" "}
              </li>
              <li>
                Es importante destacar que, para la procedencia del servicio, el
                <strong> BENEFICIARIO</strong> deberá notificar de manera
                inmediata a el <strong>PRESTADOR</strong>
                sobre la situación y presentar la documentación necesaria para
                acreditar el fallecimiento del familiar en primer grado.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong>, queda facultado para declinar la
                solicitud de servicio o quedará eximido del cumplimiento de sus
                obligaciones derivadas de los servicios que integran esta
                asistencia, cuando:{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> solicite el reembolso por el
                monto de la cobertura, debido a que no reportó con oportunidad
                el evento.{" "}
              </li>
              <li>
                El deceso del familiar reportado no pertenezca a un familiar de
                primer grado.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  15.
                </span>
                Médico general a hotel
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen15(!open15)}
            >
              <svg
                className={"transform " + (open15 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open15 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> proporcionara atención médica
                básica en el lugar de hospedaje del{" "}
                <strong>BENEFICIARIO</strong>, cuando éste presente un
                padecimiento o enfermedad no grave que le impida trasladarse a
                un centro médico durante su viaje en territorio nacional las 24
                horas todos los días del año.{" "}
              </li>
              <li>
                Este servicio cubre la atención en casos que no sean emergencia
                médica, es decir, aquellos casos que requieren atención médica
                pero no pueden ser pospuestos y cuando no es posible el traslado
                de EL <strong>BENEFICIARIO</strong>, ya que representaría un
                riesgo a su salud o estabilidad física o debido al horario.{" "}
              </li>
              <li>
                La atención médica incluirá la evaluación médica, diagnóstico y
                en su caso la prescripción de una receta médica.{" "}
              </li>
              <li>
                El tiempo de respuesta para la atención médica dependerá de la
                disponibilidad del médico y las condiciones del tráfico en la
                zona.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El servicio no cubre la atención médica en hospitales o clínicas
                especializadas, ni el traslado del <strong>BENEFICIARIO</strong>{" "}
                a un centro médico.{" "}
              </li>
              <li>
                El servicio no cubre los costos de los medicamentos prescritos,
                los cuales deberán ser cubiertos directamente por el
                <strong> BENEFICIARIO</strong>.
              </li>
              <li>
                El servicio no cubre la atención médica en caso de enfermedades
                preexistentes o crónicas que requieran un seguimiento médico
                regular.
              </li>
              <li>
                Este servicio no tiene costo para el{" "}
                <strong> BENEFICIARIO</strong> y está limitado a 1 evento en el
                año de vigencia de su programa de beneficios.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> se hará cargo del servicio hasta
                un costo de $1,500 pesos (mil quinientos pesos 00/100 m.n.).{" "}
              </li>
              <li>
                En caso de que se trate de un paciente sospechoso o confirmado
                de COVID, se le informará a el <strong> BENEFICIARIO</strong> el
                costo excedente del cual deberá hacerse cargo.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  16.
                </span>
                Dental por urgencia
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen16(!open16)}
            >
              <svg
                className={"transform " + (open16 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open16 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                Cuando el <strong>BENEFICIARIO</strong> se encuentre de viaje y
                presente una emergencia médica dental el{" "}
                <strong>PRESTADOR</strong> se encargará de poner a su
                disposición su red de consultorios médicos para agendar una cita
                en el horario más inmediato disponible.{" "}
              </li>
              <li>
                El servicio cubrirá los siguientes beneficios:
                <td></td>Consulta de valoración en horario normal.
                <td></td>Las radiografías periapicales diagnósticas necesarias,
                sin costo.
                <td></td>Revisión y eliminación de dolor agudo.
              </li>
              <li>
                Este servicio cubre los gastos hasta una cantidad máximo de
                $1,500 (mil quinientos pesos 00/100 m.n.) y un evento durante la
                vigencia de su programa de beneficios.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  17.
                </span>
                Hotel por convalecencia
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen17(!open17)}
            >
              <svg
                className={"transform " + (open17 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open17 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                Si el <strong>BENEFICIARIO</strong> sufriera un accidente o una
                enfermedad que una vez atendido y tratado médicamente precisara
                prolongar su estadía, y siempre que la misma haya sido aceptada
                y autorizada por el médico tratante y el{" "}
                <strong>PRESTADOR</strong>, este tomará a su cargo solo los
                gastos de alojamiento del <strong>BENEFICIARIO</strong> en un
                hotel.{" "}
              </li>
              <li>
                Este servicio cuenta con una cobertura de hasta 3 noches o
                $4,500 pesos (cuatro mil quinientos pesos 00/100 m.n.) durante
                la vigencia anual.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  18.
                </span>
                Traslado de un familiar por convalecencia
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen18(!open18)}
            >
              <svg
                className={"transform " + (open18 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open18 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                En caso de enfermedad grave o accidente del{" "}
                <strong>BENEFICIARIO</strong> y a causa de esto haya requerido
                su hospitalización de más de 5 días, el{" "}
                <strong>PRESTADOR</strong> se hará cargo del traslado de un
                familiar directo o al cónyuge desde el lugar de residencia
                habitual del
                <strong> BENEFICIARIO</strong> al lugar de hospitalización y
                posteriormente lo trasladará de regreso a su lugar de residencia
                habitual (boleto redondo) por avión de línea regular (clase
                económica).{" "}
              </li>
              <li>
                Este servicio tiene un límite de cobertura de hasta $3,000 pesos
                (tres mil pesos 00/100 m.n.) y un evento durante su vigencia
                anual.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  19.
                </span>
                Concierge de viaje
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen19(!open19)}
            >
              <svg
                className={"transform " + (open19 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open19 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> proporcionará a el{" "}
                <strong>BENEFICIARIO</strong> previa solicitud por medio del{" "}
                <strong>CA</strong> asesoría para facilitarle durante su viaje
                en el territorio Nacional, asistencia de Concierge en:
                <td></td>Referencia de museos, centros de espectáculos.
                <td></td>Información de vuelos o autobuses.
                <td></td>Lugares turísticos en la zona y sitios de interés.
                <td></td>Asistencia para reservación en hoteles, restaurantes.
                <td></td>Información de renta de autos y tours.
              </li>
              <li>
                Este servicio no tiene límite de eventos ni costo durante su
                viaje.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  20.
                </span>
                Envío de datos celulares por urgencia
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen20(!open20)}
            >
              <svg
                className={"transform " + (open20 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open20 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                Cuando el <strong>BENEFICIARIO</strong> haya solicitado algún
                servicio de urgencia de su programa de beneficios y haya agotado
                sus datos celulares de su teléfono móvil personal, el{" "}
                <strong>PRESTADOR</strong> previa solicitud, proporcionará
                gratuitamente una carga de datos celulares suficientes para que
                este comunicado durante su contingencia o un máximo de 2
                Gigabytes.{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> durante o posteriormente a la
                solicitud de su servicio de asistencia de urgencia, deberá
                proporcionar al ejecutivo del <strong>CA</strong> su número
                celular a 10 dígitos.{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> se da por enterado y acepta que
                el <strong>PRESTADOR</strong> no tiene responsabilidad alguna
                respecto a las políticas de uso y condiciones del servicio de su{" "}
                <strong>PRESTADOR</strong> de telefonía celular, por lo que el{" "}
                <strong>BENEFICIARIO</strong> deberá ceñirse a lo estipulado en
                el contrato de su compañía respecto a la adquisición y uso de
                datos celulares adicionales.{" "}
              </li>
              <li>
                Este servicio es limitado a un evento durante el viaje del
                <strong> BENEFICIARIO</strong>, siempre que previamente haya
                solicitado un servicio de urgencia de su programa de beneficios.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  21.
                </span>
                Viajes nacionales aéreos: localización y envío de equipaje
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen21(!open21)}
            >
              <svg
                className={"transform " + (open21 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open21 ? "block" : "hidden")}
          >
            <h1 className="mt-3 font-semibold">
              LOCALIZACIÓN Y ENVÍO DE EQUIPAJE{" "}
            </h1>
            <ol class="list-decimal">
              <li>
                El <strong>BENEFICIARIO</strong> podrá hacer uso de este
                beneficio y someter a revisión su reclamación si su equipaje
                registrado se pierde o es extraviado por la aerolínea.{" "}
              </li>
              <li>
                Para hacer efectivo este beneficio es necesario se presente lo
                siguiente:{" "}
              </li>
              <li>
                Después de haber transcurrido 24 horas de no encontrar el
                equipaje, este será considerado como perdido o extraviado. Para
                obtener este beneficio el <strong>BENEFICIARIO</strong> deberá
                contactar a el
                <strong> PRESTADOR</strong> para su reporte dentro de las 24
                hrs. contadas a partir del arribo.{" "}
              </li>
              <li>
                Que el equipaje este registrado por la aerolínea comercial.{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> ha realizado las acciones
                necesarias para mantener su equipaje documentado e intacto y ha
                realizado el procedimiento regular para recuperarlo.{" "}
              </li>
              <li>
                Presentar el documento o carta que indique o ampare la pérdida o
                extravío del equipaje expedida por parte de la aerolínea
                comercial, al encontrarse bajo la custodia de la misma y
                especifique el número de maletas registradas a nombre del
                <strong> BENEFICIARIO</strong> para hacer efectivo este
                beneficio.{" "}
              </li>
              <li>
                Es necesario proporcionar el reporte de extravío emitido por la
                aerolínea comercial, así como los recibos originales, facturas,
                comprobantes o copias de los artículos que integran su equipaje,
                detallando la descripción de los bienes y el valor de los
                artículos que integran sus maletas. Así como sus pases de
                abordaje del vuelo.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> utilizará todos los medios
                razonables a su alcance para rastrear el equipaje durante u
                plazo máximo de 21 días naturales.{" "}
              </li>
              <li>
                Este servicio es ilimitado durante el viaje del{" "}
                <strong>BENEFICIARIO</strong>.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  22.
                </span>
                Alimentos y accesorios de viaje por vuelo cancelado
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen22(!open22)}
            >
              <svg
                className={"transform " + (open22 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open22 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                En caso de que el viaje incurra en una cancelación y mientras el
                <strong> BENEFICIARIO</strong> es pasajero de la línea aérea, se
                cubrirá a éste los gastos por concepto de alimentos y accesorios
                de viaje de vuelo hasta por $500 pesos (quinientos pesos 00/100
                m.n.).{" "}
              </li>
              <li>La cobertura se hará efectiva siempre y cuando: </li>
              <li>
                El <strong>BENEFICIARIO</strong> se encuentre fuera de su lugar
                de residencia.{" "}
              </li>
              <li>
                La cancelación sea mayor de 12 horas y sea originada por falla
                mecánica de la aeronave, caso fortuito o fuerza mayor que afecte
                al vehículo aéreo, o bien, a la línea aérea en la cual el
                <strong> BENEFICIARIO</strong> se encuentre registrado para
                viajar.{" "}
              </li>
              <li>
                La línea aérea certifique por escrito las causas que originaron
                la demora y la duración de ésta.{" "}
              </li>
              <li>
                La aerolínea no haya hecho pública la cancelación o avisado con
                24 horas previas por medio electrónica a el{" "}
                <strong>BENEFICIARIO</strong>.{" "}
              </li>
              <li>
                Esta asistencia tiene una cobertura de 1 evento hasta por $500
                pesos (quinientos pesos 00/100 m.n.) por año de vigencia.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
      </div>
    </div>
  );
};

export default Premium;
