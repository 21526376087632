import React from "react";

export default function Planesindividuales() {
  return (
    <div>
      <div className='xl:mx-auto xl:container py-20 2xl:px-0 px-6'>
        <div className='lg:flex items-center justify-between'>
          <div className=' lg:w-1/2 w-full px-5'>
            <h1
              role='heading'
              className='md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800'
            >
              Planes Individuales
            </h1>
            <p className='text-bold font-bold leading-4 text-gray-600 mt-5'>
              Condiciones Generales
            </p>

            <ol class='list-decimal pt-10 '>
              <li>Suma Asegurada de uno y dos millones de pesos.</li>
              <li>
                Edad de contratación de 18 a 63 años, renovación hasta los 69
                años.
              </li>
              <li>Suma Asegurada reinstalada a la renovación.</li>
              <li>
                No hay rehabilitaciones en caso de cancelación de su programa.
              </li>
              <li>
                Tarifa nivelada, el mismo precio a cualquier edad, hombres o
                mujeres.
              </li>
              <li>Renovación anual de tu cobertura.</li>
            </ol>
            <p className=' text-left  pt-2 font-semibold '>
              Suma asegurada INTELIGENTE, se renueva anualmente.
            </p>
          </div>

          <div
            className='xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8'
            role='list'
          >
            <img
              src='https://i.ibb.co/0n6DSS3/bgimg.png'
              className='absolute w-full -ml-12 mt-24'
              alt='background circle images'
            />
            <div
              role='listitem'
              className='bg-white cursor-pointer shadow rounded-lg p-8 relative z-30'
            >
              <div className='md:flex items-center justify-between'>
                <h2 className='text-2xl font-semibold leading-6 text-gray-800'>
                  PLAN 1{" "}
                </h2>
                <p className=' text-xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800'>
                  Suma Asegurada $ 1,000,000
                  <span className=' text-sm'> MXN</span>
                </p>
              </div>
              <p className='md:w-80 text-base leading-6 mt-4 text-gray-600'>
                12 pagos mensuales -{" "}
                <strong>
                  $ 1,500.00<span className=' text-sm'> MXN</span>
                </strong>{" "}
                <td></td>Total -{" "}
                <strong>
                  $ 18,000.00<span className=' text-sm'> MXN</span>
                </strong>{" "}
                <td></td>
                <strong>menor (mensual) $450 </strong>
                <strong>(anual) $ 5,400</strong>
                <td></td>Pago *ANUAL -{" "}
                <strong>
                  $ 15,700.00<span className=' text-sm'> MXN</span>
                </strong>
                <td></td>
                <strong>*Incluye un menor sin costo</strong>
                <td></td>
                <strong>
                  *Para que se pueda asegurar a un menor de edad, debe de ser
                  forzosamente hijo directo del titular, ya sea en pago mensual
                  o anual.
                </strong>
              </p>
            </div>
            <div
              role='listitem'
              className='bg-white cursor-pointer shadow rounded-lg p-8 relative z-30 mt-7'
            >
              <div className='md:flex items-center justify-between'>
                <h2 className='text-2xl font-semibold leading-6 text-gray-800'>
                  PLAN 2
                </h2>
                <p className=' text-xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800'>
                  Suma Asegurada $ 2,000,000
                  <span className=' text-sm'> MXN</span>
                </p>
              </div>
              <p className='md:w-80 text-base leading-6 mt-4 text-gray-600'>
                12 pagos mensuales -{" "}
                <strong>
                  $ 1,800.00<span className=' text-sm'> MXN</span>
                </strong>{" "}
                <td></td>Total -{" "}
                <strong>
                  $ 21,600.00<span className=' text-sm'> MXN</span>
                </strong>{" "}
                <td></td>
                <strong>menor (mensual) $500</strong>
                <strong> (anual) $6,000</strong>
                <td></td>Pago *ANUAL -{" "}
                <strong>
                  $ 19,200.00<span className=' text-sm'> MXN</span>
                </strong>
                <td></td>
                <strong>*Incluye un menor sin costo</strong>
                <td></td>
                <strong>
                  *Para que se pueda asegurar a un menor de edad, debe de ser
                  forzosamente hijo directo del titular, ya sea en pago mensual
                  o anual.
                </strong>
                <td></td>
                <strong className=' text-sm'>
                  *Cobertura en el extranjero por $50,000 USD
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* start  */}
      <div className='xl:mx-auto xl:container py-20 2xl:px-0 px-6'>
        <div className='lg:flex items-center justify-between'>
          <div className=' lg:w-1/2 w-full px-5'>
            <h1
              role='heading'
              className='md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800 '
            >
              PLAN INDIVIDUAL PLUS
            </h1>
            <p className='text-bold font-bold leading-4 text-gray-600 mt-5'>
              Condiciones Generales
            </p>
            <p className='text-bold font-normal leading-6 text-gray-600 mt-5'>
              Nuestro producto PLUS tiene las mismas condiciones y beneficios
              del PLAN DOS, pero con las siguientes coberturas adicionales:
            </p>

            <ol class='list-decimal pt-5 '>
              <li>
                <strong>Renta diaria por enfermedad /accidente : </strong>
                Además de eliminar el pago de deducible y coaseguro, agregamos
                una cobertura de indemnización por renta diaria por
                hospitalización en caso de enfermedad o accidente: <td></td>
                <strong>
                  Accidente $ 2,450 (Período de espera 12 horas), Enfermedad $
                  980.00 (Período de espera 24 horas).
                </strong>
              </li>
              <li>
                <strong>Asistencia para mascotas:</strong>Reconocemos la
                importancia de las mascotas en tu vida. Por eso, te brindamos
                asistencia especial para ellos, asegurando que tanto tus seres
                queridos humanos como tus amigos peludos estén protegidos.
                Orientación veterinaria online, Consulta veterinaria presencial,
                Servicios funerarios y vacunas.
              </li>
              <li>
                <strong>Pago por diagnóstico de cáncer: </strong>
                Te proporcionamos un respaldo financiero adicional con una
                indemnización especial para ayudarte a sobrellevar el
                tratamiento y otros gastos, relacionados con el diagnóstico del
                cáncer.{" "}
                <strong>
                  Indemnización $ 122,500 Período de espera 90 días.
                </strong>
              </li>
              <li>
                <strong>Plan visual: </strong>
                Ahora incluimos un plan para el cuidado de tu salud visual.
                Ahorra en servicios oftalmológicos y mantén una buena salud
                visual con facilidad. Examen de la vista <strong>GRATIS</strong>
                . Anteojos o lentes de contacto, descuentos en reposiciones,
                deducible <strong>$87 mxn</strong> y una suma asegurada de
                <strong>$1,100 mxn</strong>
              </li>
              <li>
                <strong>Muerte accidental: </strong>
                Incrementamos la suma por muerte accidental, para garantizar un
                mayor respaldo económico.{" "}
                <strong>Suma Asegurada $ 490,000 </strong>
              </li>
              <li>
                <strong>Urgencias 24/7: </strong>atención rápida y experta.
                Obtén acceso inmediato a un equipo de médicos urgenciólogos.
              </li>
              <li>
                <strong>Protección integral: </strong>check up anual{" "}
                <strong>GRATIS</strong>, limpieza dental anual, ambulancia,
                asistencia funeraria, descuentos en medicinas, consultas y
                pruebas de laboratorio.{" "}
              </li>
              <li>
                <strong>Cuenta Hospitalaria: </strong>
                <strong>SIN</strong> deducible ni coaseguro.
              </li>
            </ol>
          </div>

          <div
            className='xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8'
            role='list'
          >
            <div
              role='listitem'
              className='bg-white cursor-pointer shadow rounded-lg mt-3 flex relative z-30'
            >
              <div className='w-2.5  h-auto bg-[#1e49a6] rounded-tl-md rounded-bl-md' />
              <div className='w-full p-8'>
                <div className='md:flex items-center justify-between'>
                  <h2 className='text-2xl font-semibold leading-6 text-gray-800'>
                    PLAN 2 PLUS
                  </h2>
                  <p className=' text-xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800'>
                    Suma Asegurada $ 2,000,000
                    <span className=' text-sm'> MXN</span>
                  </p>
                </div>
                <p className='md:w-80 text-base leading-6 mt-4 text-gray-600'>
                  12 pagos mensuales -{" "}
                  <strong>
                    $ 2,150 .00<span className=' text-sm'> MXN</span>
                  </strong>{" "}
                  <td></td>
                  Total -{" "}
                  <strong>
                    $ 25,800.00 <span className=' text-sm'> MXN</span>
                  </strong>{" "}
                  <td></td>
                  <strong>
                    Menor mensual - $ 550.00{" "}
                    <span className=' text-sm'> MXN</span>
                  </strong>{" "}
                  <td></td>Pago *ANUAL -{" "}
                  <strong>
                    $ 22,900.00 <span className=' text-sm'> MXN</span>
                  </strong>
                  <td></td>
                  <strong>*Incluye un menor sin costo</strong>
                  <td></td>
                  <strong>
                    *Para que se pueda asegurar a un menor de edad, debe de ser
                    forzosamente hijo directo del titular, ya sea en pago
                    mensual o anual.
                  </strong>
                  <td></td>
                  <strong>
                    *Ofrecemos el pago mensual a 12 meses sin intereses Incluye
                    un menor sin costo{" "}
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* end  */}
    </div>
  );
}
