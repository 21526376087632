import React from "react";
import Benef from "../components/Benef";

function Beneficios() {
  return (
    <div>
      <Benef />
    </div>
  );
}

export default Beneficios;
