import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";

const FormModalPrincipal = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g1mv17g",
        "template_z2389ni",
        form.current,
        "0jsvzs7nYDShvp7lC"
      )
      .then(
        (success) => {
          toast.success("Tus datos se mandaron correctamente!");
          form.current.reset();
        },
        (error) => {
          toast.error(error);
        }
      );
  };

  return (
    <div className=" from-purple-600 to-indigo-700 pt-9 w-full">
      <form
        ref={form}
        onSubmit={sendEmail}
        className="bg-white py-4 px-8 rounded-tr rounded-br"
      >
        <div className=" flex  items-center justify-center my-96">
          <div className="absolute  top-40  bg-white shadow rounded py-12 lg:px-28 px-8 ">
            <p className="md:text-3xl text-xl font-bold leading-7 text-center text-gray-700">
              FORMULARIO
            </p>
            <p className="text-xs leading-3 text-gray-600 mt-4">
              Te agradeceremos nos proporciones los siguientes datos, para que
              uno de nuestros ejecutivos te contacte y brindarte la atención que
              requieras{" "}
            </p>
            <div className="md:flex items-center mt-12">
              <div className="md:w-72 flex flex-col ">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Nombre completo:{" "}
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input email address"
                  id="modal1_name"
                  name="modal1_name"
                  type="text"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input email address"
                />
              </div>
            </div>
            <div className="md:flex items-center mt-8">
              <div className="md:w-72 flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Fecha de nacimiento:
                </label>
                <input
                  tabIndex={0}
                  role="input"
                  arial-label="Please input company name"
                  id="modal1_fecha"
                  name="modal1_fecha"
                  type="text"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 "
                  placeholder="Please input email address"
                />
              </div>
              <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Telefono de contacto:
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input country name"
                  id="modal1_tel"
                  name="modal1_tel"
                  type="text"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input country name"
                />
              </div>
            </div>
            <div className="md:flex items-center mt-8">
              <div className="md:w-72 flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Estado civil:
                </label>
                <input
                  tabIndex={0}
                  role="input"
                  arial-label="Please input company name"
                  id="modal1_civil"
                  name="modal1_civil"
                  type="text"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 "
                  placeholder="Please input company name"
                />
              </div>
              <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Edad:{" "}
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input country name"
                  id="modal1_edad"
                  name="modal1_edad"
                  type="text"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input country name"
                />
              </div>
            </div>
            <div className="md:flex items-center mt-8">
              <div className="md:w-72 flex flex-col">
                <label className="text-base font-semibold leading-none text-gray-800">
                  Domicilio completo:
                </label>
                <input
                  tabIndex={0}
                  role="input"
                  arial-label="Please input company name"
                  id="modal1_dom"
                  name="modal1_dom"
                  type="text"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100 "
                  placeholder="Please input company name"
                />
              </div>
              <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                <label className="text-base font-semibold leading-none text-gray-800">
                  RFC o CURP:{" "}
                </label>
                <input
                  tabIndex={0}
                  arial-label="Please input country name"
                  id="modal1_rfc"
                  name="modal1_rfc"
                  type="text"
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-gray-100 border rounded border-gray-200 placeholder-gray-100"
                  placeholder="Please input country name"
                />
              </div>
            </div>

            <div className="flex items-center justify-center w-full">
              <button
                type="submit"
                value="Submit"
                className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-[#1e49a6] rounded hover:bg-[#1e49a6] focus:ring-2 focus:ring-offset-2 focus:ring-[#1e49a6] focus:outline-none"
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormModalPrincipal;
