import React from "react";
import { useNavigate } from "react-router-dom";
import Art1 from "../../assets/img-blog/blog-1.jpg";
import Art2 from "../../assets/img-blog/blog.jpg";

const BlogMenu = () => {
  const navigate = useNavigate();
  return (
    <>
      <div id="blog" className="bg-gray-100 px-4 xl:px-0 py-12">
        <div className="mx-auto container">
          <h1 className="text-center text-3xl lg:text-5xl tracking-wider text-gray-900">
            Lo último de nuestro Blog
          </h1>
          <div className="mt-12 lg:mt-24">
            <div className="grid sm:grid-cols-1 md:grid-cols-1 lg:grid-cols-2 xl:grid-cols-2 gap-8">
              <div>
                <img className="w-full" src={Art1} alt="computer" />
                <div className="py-4 px-8 w-full flex justify-between bg-[#1e49a6]">
                  <p className="text-sm text-white font-semibold tracking-wide">
                    Equipo smart finances
                  </p>
                  <p className="text-sm text-white font-semibold tracking-wide">
                    Viernes, 24 Noviembre, 2023
                  </p>
                </div>
                <div className="bg-white px-10 py-6 rounded-bl-3xl rounded-br-3xl">
                  <h1 className="text-4xl text-gray-900 font-semibold tracking-wider">
                    ¿POR QUÉ SALUD INTEGRAL?
                  </h1>
                  <p className="text-gray-700 text-base lg:text-lg  lg:leading-8 tracking-wide mt-6 w-11/12">
                    “La salud integral cuida de todos los aspectos de tu vida,
                    para que logres un completo bienestar físico, mental,
                    emocional y social. Y hay una correlación directa con tu
                    salud financiera, ya que se trata de tu patrimonio y
                    bienestar futuro, así como el de tu familia”
                  </p>
                  <div className="w-full mt-4 justify-end flex items-center cursor-pointer">
                    <p
                      className="text-base tracking-wide text-[#1e49a6] cursor-pointer"
                      onClick={() => navigate("/articulo-1")}
                    >
                      Mas Informacion
                    </p>
                    <svg
                      className="ml-3 lg:ml-6"
                      xmlns="http://www.w3.org/2000/svg"
                      width={20}
                      height={18}
                      viewBox="0 0 20 18"
                      fill="none"
                    >
                      <path
                        d="M11.7998 1L18.9998 8.53662L11.7998 16.0732"
                        stroke="#4338ca"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M1 8.53662H19"
                        stroke="#4338ca"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="h-5 w-2" />
                </div>
              </div>
              <div>
                <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-8">
                  {/* <!-- Board Card --> */}
                  <div
                    className=" cursor-pointer"
                    onClick={() => navigate("/blog-menu")}
                  >
                    <img className="w-full" src={Art2} alt="games" />
                    <div className="py-2 px-4 w-full flex justify-between bg-indigo-700">
                      <p className="text-sm text-white font-semibold tracking-wide">
                        Equipo smart finances
                      </p>
                      <p className="text-sm text-white font-semibold tracking-wide">
                        PROXIMAMENTE
                      </p>
                    </div>
                    <div className="bg-white px-3  lg:px-6 py-4 rounded-bl-3xl rounded-br-3xl">
                      <h1 className="text-lg text-gray-900 font-semibold tracking-wider">
                        ¿En que te conviene invertir tu dinero?
                      </h1>
                      <p className="text-gray-700 text-sm  lg:text-base  lg:leading-8 pr-4 tracking-wide mt-2">
                        Es importante que consideres factores como: rendimiento,
                        riesgo, horizonte de inversión . . .
                      </p>
                    </div>
                  </div>
                  {/* <!-- Board Card --> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BlogMenu;
