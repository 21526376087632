import React from "react";
import Shh from "../components/Proyectoespecialc";
import { Toaster } from "react-hot-toast";

function Proyectoespecial() {
  return (
    <div>
      <Toaster position="top-center " duration="6000" />;
      <Shh />
    </div>
  );
}

export default Proyectoespecial;
