import React from 'react'
import  Comptable  from '../components/Comptable';
import  Featuresfisc  from '../components/Featuresfisc';



function Individualp() {
  return (
    <div >
      <Comptable />
      <Featuresfisc />
    </div>
  );
}

export default Individualp;
