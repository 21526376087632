import React from "react";

const Pagination = ({ totalPosts, postsPerPage, setCurrentPage }) => {
  let pages = [];

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pages.push(i);
  }
  return (
    <div>
      {pages.map((page, index) => {
        return (
          <ul class="inline-flex -space-x-px text-base  pt-2 items-center">
            <li>
              <button
                className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-slate-100 border border-gray-300 hover:bg-blue-100 hover:text-gray-700"
                key={index}
                onClick={() => setCurrentPage(page)}
              >
                {page}
              </button>
            </li>
          </ul>
        );
      })}
    </div>
  );
};

export default Pagination;
