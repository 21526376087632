import React from "react";
import Rendi from "../components/Rendimientos2023";

function Rendimientos2023() {
  return (
    <div>
      <Rendi />
    </div>
  );
}

export default Rendimientos2023;
