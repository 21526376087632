import react, { useState, useEffect } from "react";
import logo from "../assets/logo/logosmart.jpg";
import hero from "../assets/hero.jpg";
import logogrande from "../assets/logogrande.png";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { Toaster, toast } from "react-hot-toast";

const Index = () => {
  const navigate = useNavigate();
  const [menu, setMenu] = useState(false);
  const [show, setshow] = useState(false);
  useEffect(() => {
    toast(
      "Procura no tener extensiones de traducción en tu navegador, ya que puede alterar el contenido de la página.",
      {
        duration: 6000,
        id: "clipboard",
        icon: "⚠️",
        style: {
          borderRadius: "10px",
          background: "#333",
          color: "#fff",
        },
      }
    );
  });

  return (
    <div>
      <section>
        <div className="w-full relative pb-10 px-6 xl:px-0">
          <nav className="lg:hidden relative z-40">
            <div className="flex py-3 justify-between items-center px-4"></div>
          </nav>
          <nav
            role="navigation"
            aria-label="Main"
            tabIndex="0"
            className=" relative z-10 w-full lg:flex flex justify-between items-center p-3 lg:p-10"
          >
            <div className="w-1/6">
              <a
                tabIndex="0"
                aria-label="we care company logo"
                href="javascript:void(0)"
              ></a>
            </div>
            <div className="w-5/6">
              <div className="flex items-center justify-end">
                <a
                  role="button"
                  aria-label="live chat"
                  className="focus:bg-[#ace788] focus:ring-2 focus:ring-offset-2 focus:[#92d468] bg-[#92d468]  hover:bg-[#ace788] text-white px-5 py-4 font-bold rounded focus:outline-none text-2xl"
                  href="https://api.whatsapp.com/send/?phone=6181386286"
                >
                  <AiOutlineWhatsApp />
                </a>
                <div className="pl-25 px-3">
                  <a
                    role="button"
                    aria-label="live chat"
                    className="focus:bg-[#ace788] focus:ring-2 focus:ring-offset-2 focus:[#92d468] bg-[#92d468]  hover:bg-[#ace788] text-white px-12 py-4 font-semibold rounded focus:outline-none"
                    href="https://multibankfx.com/es/account/live-account?ibNum=333587514"
                  >
                    REGISTRARSE
                  </a>
                </div>
                <a href="https://smartsfinances.info/blog/">
                  <div>
                    <button
                      role="button"
                      aria-label="live chat"
                      className="focus:bg-[#92d468] focus:ring-2 focus:ring-offset-2 focus:[#92d468] bg-[#1e49a6]  hover:bg-[#9399e7] text-white px-6 py-4 font-semibold rounded focus:outline-none"
                    >
                      Blog
                    </button>
                  </div>
                </a>
              </div>
            </div>
          </nav>
          <div className="pt-0 lg:flex items-center  z-10 container mx-auto">
            <div className="w-full lg:w-2/3 h-full lg:pr-10   ">
              <img
                tabIndex="0"
                role="img"
                aria-label="people smiling"
                className="mx-auto   "
                src={hero}
                alt="people smiling"
              />
            </div>
            <div role="contentinfo" className="w-full lg:w-1/2 h-full pt-1">
              <img
                className=" h-auto max-w-full rounded-lg"
                width={800}
                height={10}
                src={logogrande}
              />
              <p
                tabIndex="0"
                className=" text-xl text-gray-800 font-semibold mb-7 pt-1 sm:text-center lg:text-center text-center "
              >
                Somos una firma de consultoría financiera, conformada por
                expertos en finanzas personales, operadores bursátiles y
                productos para tu salud integral.
              </p>
            </div>
          </div>
        </div>
      </section>

      <style>
        {`
            /* Top menu */
            .top-100 {
                animation: slideDown 0.5s ease-in-out;
            }
            @keyframes slideDown {
                0% {
                    top: -50%;
                }
                100% {
                    top: 0;
                }
            }
            * {
                outline: none !important;
                -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
                -webkit-tap-highlight-color: transparent;
            }`}
      </style>
      <Toaster position="bottom-right" reverseOrder={false} />
    </div>
  );
};
export default Index;
