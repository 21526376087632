import React from "react";
import { useNavigate } from "react-router-dom";
import mutt from "../assets/logo/mutuus.png";
import generalsalud from "../assets/logo/generalsalud.png";
import log from "../assets/logosmall.png";
import video from "../assets/video/Mutuusvideo.mp4";
import img1 from "../assets/saludfisica1.jpg";
import img2 from "../assets/saludfisica1-cel.jpg";

const CaractGen = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="flex flex-col lg:items-center justify-center w-full">
        <h1 className="font-semibold text-gray-800 text-3xl md:text-4xl text-center">
          SALUD INTEGRAL By MUTUUS
        </h1>
      </div>
      <div className="flex items-center justify-center mt-10">
        <div className="grid    xl:grid-cols-2 gap-2 ">
          <div className="md:w-48 w-full h-32  flex items-center justify-center">
            <a href="">
              <img
                width={200}
                height={24}
                viewBox="0 0 73 24"
                fill="none"
                src={mutt}
                alt="logo"
              />
            </a>
          </div>

          <div className="md:w-48 w-full h-32  flex items-center justify-center">
            <a href="">
              <img
                width={140}
                height={24}
                viewBox="0 0 73 24"
                fill="none"
                src={generalsalud}
                alt="logo"
              />
            </a>
          </div>
        </div>{" "}
      </div>
      <div className="2xl:mx-auto 2xl:container 2xl:px-20 px-6 ">
        <div className="flex md:flex-row flex-col-reverse md:items-stretch items-center justify-center">
          <div className="md:py-20 xl:w-1/2 sm:w-1/2 lg:mr-20 md:mr-6 flex flex-col md:items-end items-center justify-center xl:mr-28">
            <div className="flex flex-col items-center justify-center px-5">
              <h1 className="md:text-5xl text-3xl font-bold text-center text-gray-800">
                Características Generales:
              </h1>
              <ol class="list-decimal pt-10 ">
                <li>Te ofrecemos la primera membresía de salud en México.</li>
                <li>
                  Accede a la mayoría de los hospitales privados en el país,
                  cuando tu diagnóstico lo requiera, sin hacer ningún pago.
                </li>
                <li>
                  A través de una App fácil de usar, instantánea y accesible en
                  todo momento y/o por teléfono al 558525-8800, servicio 24/7.
                </li>
                <li>
                  Atención en Urgencias, ágil y eficiente, gracias a la
                  geolocalización, atendidas por Médicos Urgenciólogos, con el
                  mejor servicio y una oportuna atención.
                </li>
                <li>No se cubren preexistencias declaradas o no declaradas.</li>
                <li>Aplican períodos de espera para algunas enfermedades.</li>
                <li>
                  TODOS los eventos pasan por dictamen, una vez autorizado se
                  procede con la autorización.
                </li>
                <li>Cada plan es individual.</li>
                <li>
                  Las membresías para menores deben de estar ligadas
                  forzosamente a un titular mayor de edad.
                </li>
                <li>
                  Se puede aplicar una “extra prima” al costo de tu membresía,
                  debido al sobrepeso, de acuerdo a tú índice de masa corporal.
                  Puede ser temporal, ya que gracias a la atención de nuestros
                  nutriólogos podrás mejorar tu salud y condición física.
                </li>
                <li>Prueba COVID PCR en nuestra red de laboratorios.</li>
              </ol>

              <div className=" mt-6 w-20 h-20 relative mr-5">
                <div className=" ">
                  <img width={300} src={log} alt="logo" />
                </div>
              </div>
            </div>
          </div>
          <div className="py-12 xl:w-1/2 lg:w-1/3 sm:w-1/2">
            <img
              src={img1}
              alt="image ow a woman studying"
              className="h-full rounded-md object-cover object-center md:block hidden"
            />
            <img
              src={img2}
              alt="image ow a woman studying"
              className="h-auto w-auto md:hidden block"
            />
          </div>
        </div>
      </div>
      <section className="mx-auto container bg-white  py-10">
        <div className="px-4 lg:px-0">
          <div role="contentinfo" className="flex items-center flex-col px-4">
            <p
              tabIndex={0}
              className="focus:outline-none uppercase text-sm text-center text-gray-500 leading-none"
            >
              En 1 minuto
            </p>
            <h1
              tabIndex={0}
              className="focus:outline-none text-4xl lg:text-4xl pt-4 font-extrabold text-center leading-tight text-gray-800 lg:w-7/12 md:w-9/12 xl:w-5/12"
            >
              "Conoce los principales beneficios de nuestra membresía"
            </h1>
          </div>
        </div>
      </section>
      <div className="  relative items-center  justify-items-center grid  ">
        <video width="750" height="500" controls>
          <source src={video} type="video/mp4" />
        </video>
      </div>
    </div>
  );
};

export default CaractGen;
