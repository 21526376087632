import React from "react";
import Hero from "./components/Hero";
import Cta from "./components/Cta";
import Abt from "./components/Abt";
import Modal from "./components/ModalPrincipal";

function App() {
  return (
    <div>
      <Modal />
      <Hero />
      <Cta />
      <Abt />
    </div>
  );
}

export default App;
