import React, { useState } from "react";

const Intermedio = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);

  return (
    <div className=" lg:container lg:mx-auto lg:py-16 md:py-12 md:px-6 py-12 px-4 ">
      <h1 className="text-center lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 font-semibold">
        PROGRAMA INTERMEDIO
      </h1>

      <div className=" lg:mt-12 bg-gray-100 md:mt-10 mt-8 lg:py-7 lg:px-6 md:p-6 py-6 px-4 lg:w-8/12 w-full mx-auto ">
        <div className=" flex justify-between md:flex-row flex-col ">
          <div className=" md:mb-0 mb-8 md:text-left text-center">
            <h2 className=" font-medium text-xl leading-5 text-gray-800 lg:mb-2 mb-4">
              11 Beneficios
            </h2>
            <p className=" font-normal text-lg leading-5 text-gray-600   ">
              Los siguientes apartados son todos los beneficios que contiene el
              plan <strong>BASICO</strong>, más:
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-8/12 w-full mx-auto">
        {/* <!-- Question 1 --> */}
        <hr className=" w-full lg:mt-10 md:mt-12 md:mb-8 my-8" />

        <div className="w-full md:px-6  ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className=" ">
              <p className="flex justify-center items-center font-medium text-lg leading-6 md:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  1.
                </span>{" "}
                Ambulancia
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen(!open)}
            >
              <svg
                className={"transform " + (open ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: las 24 horas del día y los 365 días del año </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                De traslado médico terrestre (ambulancia), cuando el{" "}
                <strong>BENEFICIARIO</strong> sufra una emergencia médica ya sea
                por una alteración súbita de su salud que ponga en peligro su
                vida o porque sufra una lesión por accidente o asalto.{" "}
              </li>
              <li>
                Traslado: se realizará al hospital más cercano o idóneo para la
                atención del paciente dentro de la misma ciudad. En todo momento
                se velará por la integridad del <strong>BENEFICIARIO</strong> y
                no se condicionará el envío de la ambulancia a ninguna
                consideración o valoración médica.{" "}
              </li>
              <li>
                Hospital Público: cuando el <strong>BENEFICIARIO</strong> sea
                trasladado a un hospital público, la familia de éste se obliga a
                realizar todos los trámites administrativos que correspondan
                para que el paciente sea aceptado en el nosocomio.
              </li>
              <li>
                Si no es recibido: si el <strong>BENEFICIARIO</strong> no fue
                recibido en el nosocomio inicialmente solicitado, se generará un
                segundo servicio a otro hospital con cargo a su membresía de
                beneficios.{" "}
              </li>
              <li>
                Demora: si el hospital en donde deben recibir a el{" "}
                <strong>BENEFICIARIO</strong> requiere que espere la ambulancia,
                después de los 15 minutos, a partir del minuto 16 se generará un
                costo por tiempo de espera a cargo del usuario, la tarifa
                aplicable se le informará en el momento y deberá ser liquidada
                directamente al paramédico de la ambulancia.
              </li>
              <li>
                Límite de cobertura: tiene un límite de traslado de $ 1,500 (un
                mil quinientos pesos 00/100 m.n.) o al hospital más cercano por
                servicio y está limitado a 2 eventos por año de vigencia.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> del servicio, queda facultado para
                declinar la solicitud de servicio o quedará eximido de cualquier
                responsabilidad o del cumplimiento de sus obligaciones derivadas
                de los servicios que integran esta asistencia, cuando:
              </li>
              <li>
                Los familiares se encuentren agresivos: y esta circunstancia
                haga imposible brindar servicio, ya sea por su estado violento o
                porque se nieguen a recibir la atención.
              </li>
              <li>
                A juicio del personal de urgencias médicas: consideren que por
                la gravedad del paciente y conforme a los principios
                establecidos por la especialidad de medicina crítica, el
                traslado resulte improcedente, inútil o imposible de realizar
                por cualquier circunstancia.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 2 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  2.
                </span>{" "}
                Teleconsulta legal (ORIENTACIÓN LEGAL)
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen2(!open2)}
            >
              <svg
                className={"transform " + (open2 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open2 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año.</li>
              <li>
                Consultas: sobre cualquier problema jurídico en materia penal,
                civil, familiar y mercantil.
              </li>
              <li>Medios: video consulta o de manera telefónica</li>
              <li>
                Se genera un expediente digital por cada{" "}
                <strong>BENEFICIARIO</strong>, para brindarle la mejor asesoría
                y llevar un seguimiento de su caso.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                Procedimiento: el <strong>BENEFICIARIO</strong> deberá relatar
                al abogado los hechos que original la duda legal. En caso de ser
                necesario el abogado podrá recibir vía correo electrónico
                escáner o fotografías de documentos oficiales, para que pueda
                estudiarlos y tener mayores elementos para emitir una mejor
                opinión legal.{" "}
              </li>
              <li>
                Disponibilidad: los abogados estarán disponibles las veces que
                sea necesario para consultas telefónicas subsecuentes derivadas
                del mismo asunto.{" "}
              </li>
              <li>
                En caso de urgencia legal: y previa autorización del{" "}
                <strong>BENEFICIARIO</strong>, el abogado podrá remitirlo con un
                profesionista de la red en su ciudad, para tener una asesoría de
                forma presencial sin costo.{" "}
              </li>
              <li>
                Contratación del abogado referido: en caso de que el{" "}
                <strong>BENEFICIARIO</strong> esté interesado en contratar sus
                servicios, se gestionará una tarifa presencial respecto de los
                honorarios del abogado, vigilará la prestación del servicio y
                supervisará que el mismo cumpla con los estándares de calidad en
                cuanto a tiempo y eficiencia.{" "}
              </li>
              <li>
                Este servicio no tiene costo ni límite de eventos para el{" "}
                <strong>BENEFICIARIO</strong> durante la vigencia de su programa
                de beneficios.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 3 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  3.
                </span>
                Localización de mascotas
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen3(!open3)}
            >
              <svg
                className={"transform " + (open ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open3 ? "block" : "hidden")}
          >
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                Cuando la MASCOTA del <strong>BENEFICIARIO</strong>, se
                encuentre extraviada, se le proporcionará a el{" "}
                <strong>BENEFICIARIO</strong> la asesoría de las gestiones y la
                logística a realizar tendientes a localizar la MASCOTA.{" "}
              </li>
              <li>
                Se le elaborará un boletín impreso con la foto de la MASCOTA,
                para que el <strong>BENEFICIARIO</strong> de a conocer el hecho.
              </li>
              <li>
                Este servicio no tiene costo ni límite de eventos para el{" "}
                <strong>BENEFICIARIO</strong>, durante la vigencia de su
                programa de beneficios .{" "}
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 4 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6  ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  4.
                </span>
                Descuentos en artículos para mascotas
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen4(!open4)}
            >
              <svg
                className={"transform " + (open4 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open4 ? "block" : "hidden")}
          >
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                Se pondrá a disposición del <strong>BENEFICIARIO</strong> la
                "Red Online" de tiendas de artículos y accesorios para mascotas
                con las cuales se haya celebrado un convenio previo de
                descuentos. Este número de control o convenio lo proporciona el{" "}
                <strong>CA</strong>.{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> deberá acceder a la página web
                del club de descuentos y cupones, identificarse con su número de
                membresía y acceder a la tienda de su preferencia. El número de
                membresía aparece en su certificado del programa
                correspondiente.
              </li>
              <li>
                Este servicio no tiene un límite de eventos, el beneficio
                consiste en obtener un costo preferencial.
              </li>
            </ol>
          </div>
          <hr className=" w-full lg:mt-10 my-8" />
        </div>
      </div>
    </div>
  );
};

export default Intermedio;
