

const Index = () => (

    <div className='overflow-y-hidden'>
      <div className="xl:mx-auto xl:container  xl:px-20 md:px-6 px-4 py-12">
        <div className="lg:flex items-center justify-center lg:space-x-12 2xl:space-x-6">
          <div className>
            <p className="lg:text-4xl text-3xl font-extrabold leading-9 text-gray-800">Caracteristicas y Condiciones de uso</p>
            
            <div className="lg:hidden lg:w-3/5 xl:w-3/5 w-full lg:mt-0 mt-6">
              <img src="https://img.freepik.com/vector-gratis/ilustracion-plana-soporte-al-cliente_23-2148899114.jpg?w=826&t=st=1687142331~exp=1687142931~hmac=478fdd0e928f21d3e7bab1abf7b2f0f237c88b172c5105c2bfc3a91be5dec11a" alt="ongoing meeting" className="w-full obejct-fit object-center object-fill h-full" />
            </div>
            <div className="mt-6 md:mt-10 grid sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-6 lg:mt-6 2xl:mt-12">
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg1.svg" alt="clock" />
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">Centro de Atención CA  24/7</h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">Atención personalizada, grabamos el 100% de las llamadas para la calidad del servicio.</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg2.svg" alt="Friendly" />
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">Por medio de WhatsApp</h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600"> Recibiras acompañamiento y atención durante tu servicio, podrás enviarnos tu geolocalización, fotos, videos, notas de voz, para precisar de mejor manera la ayuda que necesitas.</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg3.svg" alt="Creative" />
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">Contamos con una amplia red nacional</h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600"> Más de 7,000 proveedores médicos y de asistencia, más de 4,000 comercios afiliados y  cobertura en los 32 estados de la República en las 170 principales ciudades.</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg4.svg" alt="Achievments" />
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">Todo a través de nuestro Centro de Atención CA</h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">- Tus consultas médicas, te indicaremos el médico más cercano a tu domicilio. <td></td>- Te recordaremos el día y la hora de la cita. <td></td>- Al igual que todos los servicios que necesites los podrás solicitar directamente a nuestro CA 24/7.</p>
                </div>
              </div>
              <div className="flex items-center">
                <div className="w-16 h-16 relative">
                  <div className=" flex-shrink-0 z-20  w-16 h-16 flex items-center justify-center mt-2 mr-3">
                    <img src="https://tuk-cdn.s3.amazonaws.com/can-uploader/feature_6_Svg4.svg" alt="Achievments" />
                  </div>
                </div>
                <div className="flex items-start flex-col ml-6 pt-8">
                  <h2 className="text-lg font-semibold leading-4 text-gray-800">Campañas E-mail Marketing</h2>
                  <p className="lg:w-40 2xl:w-52 text-base leading-6 mt-2 text-gray-600">Realizamos dos campañas al mes para recordarte el contenido y alcance de tu Programa de Beneficios.</p>
                </div>
              </div>
              
            </div>
          </div>
          <div className="hidden lg:block lg:w-3/5 xl:w-3/5 w-full lg:mt-0 mt-6">
            <img src="https://img.freepik.com/vector-gratis/ilustracion-plana-soporte-al-cliente_23-2148899114.jpg?w=826&t=st=1687142331~exp=1687142931~hmac=478fdd0e928f21d3e7bab1abf7b2f0f237c88b172c5105c2bfc3a91be5dec11a" alt="ongoing meeting" className="w-full obejct-fit object-center object-fill h-full" />
          </div>
        </div>
      </div>







































    </div>
  
  
  
  );
  export default Index;
  
  
  
  
  