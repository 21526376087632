import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import modalimg from "../assets/promos/info.png";

function ModalPrincipal() {
  const navigate = useNavigate();
  const [show, setshow_modal_X] = useState(false);

  return (
    <>
      <div
        className="absolute  top-0 right-0 bottom-0 left-0 z-10 ease-in-out py-16 "
        id="wrapper"
        onClick={() => setshow_modal_X(false)}
      >
        <div className="px-4 py-14">
          <div
            className={`${
              show ? "flex" : "hidden"
            } lg:max-w-[642px] md:max-w-[744px] max-w-[375px] mx-auto bg-gray-100  md:px-2 px-2  lg:py-8 md:py-12 py-9`}
          >
            <div className="mx-auto relative">
              <svg
                onClick={() => setshow_modal_X(false)}
                className="cursor-pointer absolute right-4 -top-7 z-10"
                width={24}
                height={24}
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  clipRule="evenodd"
                  d="M7.28033 6.21967C6.98744 5.92678 6.51256 5.92678 6.21967 6.21967C5.92678 6.51256 5.92678 6.98744 6.21967 7.28033L10.9393 12L6.21967 16.7197C5.92678 17.0126 5.92678 17.4874 6.21967 17.7803C6.51256 18.0732 6.98744 18.0732 7.28033 17.7803L12 13.0607L16.7197 17.7803C17.0126 18.0732 17.4874 18.0732 17.7803 17.7803C18.0732 17.4874 18.0732 17.0126 17.7803 16.7197L13.0607 12L17.7803 7.28033C18.0732 6.98744 18.0732 6.51256 17.7803 6.21967C17.4874 5.92678 17.0126 5.92678 16.7197 6.21967L12 10.9393L7.28033 6.21967Z"
                  fill="#373737"
                />
              </svg>
              <div>
                <img
                  src={modalimg}
                  alt=""
                  className="mt-0 w-full lg:block md:hidden hidden"
                />
                <img
                  src={modalimg}
                  alt
                  className="lg:hidden md:block block mt-0 w-full"
                />
              </div>
              <button
                onClick={() => navigate("/form1")}
                className="bg-[#1e49a6] text-white w-full py-3 mt-6 hover:bg-[#92d468] duration-300"
              >
                Subscribe
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalPrincipal;
