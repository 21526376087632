import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { toast } from "react-hot-toast";

const FormPlanesEmpresariales = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g1mv17g",
        "template_8hu9zvp",
        form.current,
        "0jsvzs7nYDShvp7lC"
      )
      .then(
        (success) => {
          toast.success("Tus datos se mandaron correctamente!");
          form.current.reset();
        },
        (error) => {
          toast.error(error);
        }
      );
  };
  return (
    <div className="container mx-auto pt-16">
      <div className="lg:flex">
        <div className="xl:w-2/5 lg:w-2/5 bg-[#1e49a6] py-16 xl:rounded-bl rounded-tl rounded-tr xl:rounded-tr-none">
          <div className="xl:w-5/6 xl:px-0 px-8 mx-auto">
            <h1 className="xl:text-4xl text-3xl pb-4 text-white font-bold">
              VENTA EMPRESARIAL
            </h1>
            <p className="text-sm text-white pb-7 leading-relaxed font-semibold lg:pr-10">
              Te ofrecemos una excelente alternativa para mejorar la
              productividad, permanencia y retención de tus colaboradores,
              fortalecer su calidad de vida, social y familiar, a través de
              nuestros PROGRAMAS DE BENEFICIO por medio de la previsión social,
              creando un sentimiento de permanencia y lealtad.
            </p>
            <p className="text-sm text-white pb-7 leading-relaxed font-semibold lg:pr-10">
              Como previsión social, aprovecha el poder hacer estos gastos
              deducibles en base a los arts. 7, 27 fracc. XI y 93 de la LISR.
            </p>
            <h1 className=" text-lg pb-4 text-white font-bold">
              PROCESO DE COMPRA:
            </h1>

            <ul class="list-decimal px-4 pt-0 text-white text-sm font-semibold">
              <li>Envía el formulario adjunto.</li>
              <li>
                Recibe una atención personalizada para explicarte claramente el
                alcance y características de nuestros productos.
              </li>
              <li>
                En base a lo solicitado, te ofreceremos el mejor precio y
                condiciones.
              </li>
              <li>
                Envías el LAYOUT con los datos de todos tus colaboradores, que
                recibirán nuestro producto y vía correo enviaras los datos de
                facturación.
              </li>
              <li>
                Recibes la factura correspondiente en base a la frecuencia de
                pago que hayas solicitado.
              </li>
              <li>Realizas el pago vía transferencia electrónica.</li>
              <li>
                Cada uno de tus colaboradores recibe en su correo electrónico su
                CERTIFICADO explicando claramente las características de su
                PROGRAMA DE BENEFICIOS.
              </li>
              <li>
                Cada mes te solicitaremos actualizar el LAY OUT, en base a altas
                y bajas de tus colaboradores, en frecuencia de pago mensual, se
                ajusta la factura a esta información.
              </li>
            </ul>
          </div>
        </div>
        <div className="xl:w-3/5 lg:w-3/5 bg-gray-200 h-full pt-5 pb-5 xl:pr-5 xl:pl-0 rounded-tr rounded-br">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="bg-white py-4 px-8 rounded-tr rounded-br"
          >
            <h1 className="text-4xl text-gray-800 font-extrabold mb-6">
              Formulario{" "}
            </h1>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs mb-6 xl:mb-0">
                <div className="flex flex-col">
                  <label
                    htmlFor="compra_name"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Nombre del contacto:
                  </label>
                  <input
                    required
                    id="compra_name"
                    name="compra_name"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end">
                <div className="flex flex-col">
                  <label
                    htmlFor="compra_mail"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Correo electrónico:
                  </label>
                  <input
                    required
                    id="compra_mail"
                    name="compra_mail"
                    type="email"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="compra_celular"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Celular (con 10 dígitos)
                  </label>
                  <input
                    required
                    id="compra_celular"
                    name="compra_celular"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="compra_telefono"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Teléfono de oficina y ext.
                  </label>
                  <input
                    required
                    id="compra_telefono"
                    name="compra_telefono"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="compra_empresa"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Nombre de la Empresa:
                  </label>
                  <input
                    required
                    id="compra_empresa"
                    name="compra_empresa"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="compra_colab"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    No. De Colaboradores:
                  </label>
                  <input
                    required
                    id="compra_colab"
                    name="compra_colab"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="compra_progams"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    PROGRAMA DE BENEFICIOS de tu interés:
                  </label>

                  <select
                    id="compra_progams"
                    name="compra_progams"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border "
                  >
                    <option selected>Programas ...</option>
                    <option value="BASICO">BASICO</option>
                    <option value="INTERMEDIO">INTERMEDIO</option>
                    <option value="ELITE">ELITE</option>
                    <option value="PREMIUM">PREMIUM</option>
                  </select>
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="compra_horario"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Horario de atención:
                  </label>
                  <input
                    required
                    id="compra_horario"
                    name="compra_horario"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>

            <div className="w-full mt-6">
              <div className=" text-center">
                <button
                  type="submit"
                  value="Submit"
                  className="focus:outline-none bg-[#92d468] transition duration-150 ease-in-out hover:bg-[#1e49a6] rounded text-white px-8 py-3  text-lg font-bold leading-6"
                >
                  Enviar
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormPlanesEmpresariales;
