import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineArrowLeft } from "react-icons/ai";
const conservadora = [
  { fecha: "dic-23", mensual: 1.5, anualizada: 18, cetes: 11.21 },
  { fecha: "nov-23", mensual: 3.4, anualizada: 40.8, cetes: 10.86 },
  { fecha: "oct-23", mensual: 3.9, anualizada: 46.8, cetes: 11.08 },
  { fecha: "sep-23", mensual: 2.95, anualizada: 35.4, cetes: 11.08 },
  { fecha: "ago-23", mensual: 2.27, anualizada: 27.24, cetes: 11.16 },
  { fecha: "jul-23", mensual: 3.8, anualizada: 45.6, cetes: 11.22 },
  { fecha: "jun-23", mensual: 1.925, anualizada: 23.1, cetes: 11.16 },
  { fecha: "may-23", mensual: 1.6, anualizada: 19.2, cetes: 11.32 },
  { fecha: "abr-23", mensual: 1.15, anualizada: 13.8, cetes: 11.29 },
  { fecha: "mzo-2023", mensual: 0.59, anualizada: 7.08, cetes: 11.23 },
  { fecha: "feb-23", mensual: 0.63, anualizada: 7.56, cetes: 10.92 },
  { fecha: "ene-23", mensual: 0.41, anualizada: 4.92, cetes: 10.61 },
];

const Grafica = () => {
  const navigate = useNavigate();
  const [show, setShow] = useState(null);

  return (
    <>
      <div className="w-full sm:px-6">
        <div className="px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
          <div className="sm:flex items-center justify-between">
            <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
              ESTRATEGIAS SMART FINANCES 2023
            </p>
            <div>
              <button
                className="inline-flex sm:ml-3 mt-4 sm:mt-0 items-start justify-start px-6 py-3 bg-[#92d468]  focus:outline-none rounded"
                onClick={() => navigate("/")}
              >
                <p className="text-sm font-medium leading-none text-white">
                  <AiOutlineArrowLeft />
                </p>
              </button>
            </div>
          </div>
        </div>
        <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
          <table className="w-full whitespace-nowrap">
            <thead>
              <tr className="h-16 w-full text-sm leading-none text-gray-800">
                <th className="font-bold text-center pl-20">Fecha</th>
                <th className="font-bold text-center pl-20 ">
                  Smart Finances<td></td>Mensual
                </th>
                <th className="font-bold text-center pl-20">
                  Smart Finances<td></td>Anualizada
                </th>
                <th className="font-bold text-center pl-20">
                  CETES 28 días <td></td>Promedio de la tasa mensual,
                  anualizada.<td></td> Fuente : Banco de Mexico
                </th>
              </tr>
              {conservadora.map((val, key) => {
                return (
                  <tr
                    key={key}
                    className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
                  >
                    <td className="pl-20 font-medium text-center">
                      {val.fecha}
                    </td>
                    <td className="pl-20 font-medium text-center">
                      {val.mensual}%
                    </td>
                    <td className="pl-20 font-medium text-center">
                      {val.anualizada}%
                    </td>
                    <td className="pl-20 font-medium text-center">
                      {val.cetes}%
                    </td>
                  </tr>
                );
              })}
            </thead>
          </table>
        </div>
      </div>
    </>
  );
};

export default Grafica;
