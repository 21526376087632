import React from 'react'
import Preciosem from '../components/Preciosempre';

function Priceindi() {
  return (
    <div >
     <Preciosem />
    </div>
  )
}

export default Priceindi