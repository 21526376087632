import { createContext, useEffect, useState } from "react";

import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  onAuthStateChanged,
  signOut,
} from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from "../firebase";

const initialState = { loading: false, error: null, currentUser: null };

export const AuthContext = createContext({});

export const AuthContextProvider = ({ children }) => {
  const [state, setState] = useState(initialState);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        await getCurrentUser(currentUser.uid);
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  const setUser = (currentUser) => {
    setState((prev) => ({ ...prev, currentUser }));
  };

  const setLoading = (loading) => {
    setState((prev) => ({ ...prev, loading }));
  };

  const setError = (error) => {
    setState((prev) => ({ ...prev, error }));
  };

  const onHandleLogin = async (email, password) => {
    try {
      setLoading(true);
      const { user } = await signInWithEmailAndPassword(auth, email, password);
      await getCurrentUser(user.uid);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setError(error);
    }
  };

  const onHandleLogOut = () => {
    signOut(auth);
    setUser(null);
  };

  const getCurrentUser = async (id) => {
    try {
      setLoading(true);
      const ref = doc(db, "users", id);
      const snapshot = await getDoc(ref);
      const user = snapshot.data();
      setUser(user);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  return (
    <AuthContext.Provider
      value={{ currentUser: state.currentUser, onHandleLogin, onHandleLogOut }}
    >
      {children}
    </AuthContext.Provider>
  );
};
