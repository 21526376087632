import React, { Fragment, useCallback } from "react";

import { Outlet, ScrollRestoration, useLocation } from "react-router-dom";

const MainLayout = () => {
  const { pathname } = useLocation();

  const getKey = useCallback((location, matches) => {
    const match = matches.find((m) => m.handle?.scrollMode);
    if (match?.handle?.scrollMode === "pathname") {
      return location.pathname;
    }

    return location.key;
  }, []);

  return (
    <Fragment>
      <Outlet />

      <ScrollRestoration getKey={getKey} />
    </Fragment>
  );
};

export default MainLayout;
