import React from 'react'
import  About  from '../components/Aboutus.jsx';

function Aboutus() {
  return (
    <div>
    <About />
    </div>
  )
}

export default Aboutus