import React from "react";
import Form from "../components/FormPempresarial";
import { Toaster } from "react-hot-toast";

function FormEmpresarial() {
  return (
    <div>
      <Toaster position="top-center " duration="6000" />;
      <Form />
    </div>
  );
}

export default FormEmpresarial;
