import React from 'react'
import Preciosin from '../components/Preciosindi';

function Priceindi() {
  return (
    <div >
     <Preciosin />
    </div>
  )
}

export default Priceindi