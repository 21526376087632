import React from "react";
import { Link, useNavigate } from "react-router-dom";
import EmpreBasicM from "../assets/logospaquetes/349C1637-8532-4678-8B62-B57F4E6959C2.png";
import EmpreBasicCon from "../assets/logospaquetes/61D407FA-B203-42E6-A361-C39611A423E8.png";

import EmpreInterM from "../assets/logospaquetes/327409AC-7BE3-4368-9F17-D614C9DC4B8C.png";
import EmpreInterCon from "../assets/logospaquetes/9B4E66E6-7696-43AC-A74E-57ACB17500D4.png";

import EmpreEliteM from "../assets/logospaquetes/EA8CB12E-6A02-40A7-9597-30FA24609077.png";
import EmpreEliteCon from "../assets/logospaquetes/98951FC5-C360-41B7-B301-9189BA75B018.png";

import EmprePremiumM from "../assets/logospaquetes/68F07459-4BA3-4E01-B0DC-7908F3D006A5.png";
import EmprePremiumCon from "../assets/logospaquetes/77CC7C77-0E95-41FF-BF1C-E6561C38A96A.png";

function Preciosempre() {
  const navigate = useNavigate();
  return (
    <div>
      <section className="px-6 xl:px-0">
        <div className="mt-20 mx-auto container">
          <div className="flex flex-col lg:items-center justify-center w-full">
            <h1 className="font-semibold text-[#1e49a6] text-3xl md:text-4xl">
              PLANES EMPRESARIALES
            </h1>
            <p className="mt-2.5 lg:w-1/2 lg:text-center text-2xl">
              Tenemos 4 planes que se acomodan a las necesidades de tu empresa.
            </p>
            <p className="mt-2.5 lg:w-1/2 lg:text-center text-1xl font-semibold">
              Al solicitar tu producto, es necesario que llenes un formulario
              simple para recibir el certificado Con tus datos.
            </p>
          </div>
          <div className="flex items-center justify-center w-full">
            <div className="pt-14">
              <div className="container mx-auto">
                <div className="flex flex-wrap mb-12 justify-between sm:justify-center -mx-6">
                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                      <h4 className="text-2xl text-[#1e49a6] font-semibold pb-0">
                        Básico{" "}
                      </h4>
                      <h1 className=" font-semibold">
                        A quienes incluimos: Titular{" "}
                      </h1>
                      <div className=" flex justify-center pt-4 space-x-4   ">
                        <img width={90} src={EmpreBasicM} />
                        <img width={90} src={EmpreBasicCon} />
                      </div>
                      <ul className="flex flex-col mb-6 pt-7">
                        <h1 className=" font-bold">6 Beneficios: </h1>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Bienestar Financiero
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Telemedicina activa
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Nutricional
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Ayuda psicológica
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Fitness
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Club de descuentos
                          </p>
                        </li>

                        {/* NADAA SOLO ESTETICO */}
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>

                        {/* NADAA SOLO ESTETICO */}
                      </ul>
                      <h1 className=" font-bold">Precio Mensual* </h1>
                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          47.53<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          /10 a 50 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          46.06<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          /50 a 100 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          44.59<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          /Mas de 100 Personas
                        </span>
                      </p>
                      <h1 className=" font-bold">Precio De contado* </h1>
                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          513.32<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          /10 a 50 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          497.45<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          /50 a 100 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          481.57<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          /Mas de 100 Personas
                        </span>
                      </p>
                      <h2 className="font-bold mt-2">* costos por persona</h2>
                      <Link to="/planesem">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Mas Informacion
                        </button>
                      </Link>
                      <Link to="/contrata">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Elegir
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                      <h4 className="text-2xl text-[#1e49a6] font-semibold pb-0">
                        Intermedio
                      </h4>
                      <h1 className="font-semibold">
                        A quienes incluimos: Titular{" "}
                      </h1>
                      <div className=" flex justify-center pt-4 space-x-4   ">
                        <img width={90} src={EmpreInterM} />
                        <img width={90} src={EmpreInterCon} />
                      </div>
                      <ul className="flex flex-col mb-6 pt-7">
                        <h1 className="font-bold">10 Beneficios: </h1>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-bold">
                            Los Básicos mas :{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Ambulancia
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Teleconsulta legal
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Localización de mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        {/* NADAA SOLO ESTETICO */}
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img src="" className="mr-4" alt="" />
                          <p className="text-white text-base font-normal">
                            Descuentos art. mascotas
                          </p>
                        </li>
                        {/* NADAA SOLO ESTETICO */}
                      </ul>
                      <h1 className=" font-bold">Precio Mensual* </h1>
                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          110.58<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 10 a 50 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          107.16<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 50 a 100 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          103.74<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / Mas de 100 Personas
                        </span>
                      </p>
                      <h1 className=" font-bold">Precio De contado* </h1>
                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          1,194.26<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 10 a 50 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          1,157.33<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 50 a 100 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          1,120.39<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / Mas de 100 Personas
                        </span>
                      </p>
                      <h2 className="font-bold mt-2">* costos por persona</h2>
                      <Link to="/planesem">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Mas Informacion
                        </button>
                      </Link>
                      <Link to="/contrata">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Elegir
                        </button>
                      </Link>
                    </div>
                  </div>
                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-white border border-gray-200shadow rounded-lg text-left">
                      <h4 className="text-2xl text-[#1e49a6] font-semibold pb-0">
                        Elite
                      </h4>
                      <h1 className="font-semibold">
                        A quienes incluimos: <td></td>a).-Titular soltero y sus
                        padres
                        <td></td>b).-Titular casado, cónyuge e hijos menores de
                        25 años.
                      </h1>
                      <div className=" flex justify-center pt-4 space-x-4   ">
                        <img width={90} src={EmpreEliteM} />
                        <img width={90} src={EmpreEliteCon} />
                      </div>
                      <ul className="flex flex-col mb-6 pt-7">
                        <h1 className="font-bold">31 Beneficios: </h1>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-bold">
                            Los Básicos, intermedios, más:{" "}
                          </p>
                        </li>

                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Dental, visión, descuentos en laboratorios,
                            medicamentos, clinicas y hospitales.
                          </p>
                        </li>

                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Costo preferente en medico general, especialista y
                            alta especialidad{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Servicio integral funerario{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Plataforma E-Learning{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Asistencia emergencia en el hogar y legal
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Importantes Beneficios para tu mascota
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMark.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-gray-800 text-base font-normal">
                            Dispositivos digitales y servicio de grúa{" "}
                          </p>
                        </li>
                      </ul>
                      <h1 className=" font-bold">Precio Mensual* </h1>
                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          265.78<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 10 a 50 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          257.56<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 50 a 100 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          249.34<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / Mas de 100 Personas
                        </span>
                      </p>
                      <h1 className=" font-bold">Precio De contado* </h1>
                      <p className="text-base text-[#1e49a6] relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          2,870.42<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 10 a 50 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          2,781.65<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 50 a 100 Personas
                        </span>
                      </p>
                      <p className="text-base text-[#1e49a6] relative pl-3 ">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          2,692.87<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / Mas de 100 Personas
                        </span>
                      </p>
                      <h2 className="font-bold mt-2">* costos por persona</h2>
                      <Link to="/planesem">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Mas Informacion
                        </button>
                      </Link>
                      <Link to="/contrata">
                        <button className="mt-5 w-full bg-gray-200 hover:bg-gray-300 focus:outline-none transition duration-150 ease-in-out rounded text-[#1e49a6] px-8 py-3 text-base font-semibold py-3">
                          Elegir
                        </button>
                      </Link>
                    </div>
                  </div>

                  <div className="w-full xl:w-1/3 lg:w-1/3 md:w-1/2 sm:w-1/2 mb-4 px-6">
                    <div className="py-5 px-4 bg-gray-400 border border-gray-200 shadow rounded-lg text-left">
                      <h4 className="text-2xl text-black font-semibold pb-0">
                        Premium
                      </h4>
                      <h1 className="font-semibold text-white">
                        A quienes incluimos:<td></td> a).-Titular soltero y sus
                        padres <td></td>b).-Titular casado, cónyuge e hijos
                        menores de 25 años.
                      </h1>
                      <div className=" flex justify-center pt-4 space-x-4   ">
                        <img width={90} src={EmprePremiumM} />
                        <img width={90} src={EmprePremiumCon} />
                      </div>
                      <ul className="flex flex-col mb-6 pt-7">
                        <h1 className="font-bold text-black">
                          54 Beneficios:{" "}
                        </h1>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-bold">
                            Los Básicos, intermedios, Elite, más:{" "}
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Medico a domicilio
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Servicios complementarios Funerarios, Seguros de
                            vida, muerte accidental y GM por accidente.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4"
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Mayores beneficios para tu mascota.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4 "
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Asistencia vial integral.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4 "
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Asistencia integral en viajes nacionales.
                          </p>
                        </li>
                        <li className="flex items-center mb-2.5">
                          <img
                            src="https://cdn.tuk.dev/assets/templates/weCare/checkMarkWhite.png"
                            className="mr-4 "
                            alt="check-mark"
                          />
                          <p className="text-white text-base font-normal">
                            Asistencia en viajes nacionales aéreos.{" "}
                          </p>
                        </li>
                      </ul>
                      <h1 className=" font-bold text-black">
                        Precio Mensual*{" "}
                      </h1>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          949.63<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 10 a 50 Personas
                        </span>
                      </p>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          920.26<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 50 a 100 Personas
                        </span>
                      </p>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          890.89<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / Mas de 100 Personas
                        </span>
                      </p>

                      <h1 className=" font-bold text-black">
                        Precio De contado*{" "}
                      </h1>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          10,256.00<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 10 a 50 Personas
                        </span>
                      </p>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          9,938.81<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / 50 a 100 Personas
                        </span>
                      </p>
                      <p className="text-base text-black relative pl-3">
                        <span className="font-light text-lg">$</span>
                        <span className="text-2xl font-semibold">
                          9,621.61<span className=" text-sm"> MXN</span>
                        </span>
                        <span className="font-light text-lg">
                          / Mas de 100 Personas
                        </span>
                      </p>
                      <h2 className="font-bold mt-2">* costos por persona</h2>
                      <Link to="/planesem">
                        <button className="mt-5 w-full text-[#1e49a6] focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-gray-100 px-8 py-3 text-base font-semibold py-3">
                          Mas Informacion
                        </button>
                      </Link>
                      <Link to="/contrata">
                        <button className="mt-5 w-full text-[#1e49a6] focus:outline-none transition duration-150 ease-in-out rounded bg-white hover:bg-gray-100 px-8 py-3 text-base font-semibold py-3">
                          Elegir
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <style
                dangerouslySetInnerHTML={{
                  __html: "",
                }}
              />
            </div>
          </div>
        </div>
      </section>

      <style>
        {` 
            .checkbox:checked {
                right: 0;
                background-color: #4338ca;
            }
            `}
      </style>
    </div>
  );
}

export default Preciosempre;
