import React from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { AiOutlineWhatsApp } from "react-icons/ai";
import mutt from "../assets/logo/mutuus.png";
import generalsalud from "../assets/logo/generalsalud.png";
import log from "../assets/logosmall.png";
import extedadmax from "../assets/planes/extensiondeedadmax.jpg";
import extedadmid from "../assets/planes/extensiondeedadmid.jpg";
import extedadsm from "../assets/planes/extensiondeedadsm.jpg";

import indivmax from "../assets/planes/planindivmax.jpg";
import indivmid from "../assets/planes/planindivmid.jpg";
import indivsm from "../assets/planes/planindivsm.jpg";

import d from "../assets/d.jpg";

import img1 from "../assets/saludfisica1.jpg";
import img2 from "../assets/saludfisica1-cel.jpg";

const Preciosinte = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='flex flex-col lg:items-center justify-center w-full'>
        <h1 className='font-bold text-gray-800 text-3xl md:text-4xl text-center'>
          SALUD INTEGRAL By MUTUUS
        </h1>
      </div>
      <div className='flex items-center justify-center mt-10'>
        <div className='grid    xl:grid-cols-2 gap-2 '>
          <div className='md:w-48 w-full h-32  flex items-center justify-center'>
            <a href=''>
              <img
                width={200}
                height={24}
                viewBox='0 0 73 24'
                fill='none'
                src={mutt}
                alt='logo'
              />
            </a>
          </div>

          <div className='md:w-48 w-full h-32  flex items-center justify-center'>
            <a href=''>
              <img
                width={140}
                height={24}
                viewBox='0 0 73 24'
                fill='none'
                src={generalsalud}
                alt='logo'
              />
            </a>
          </div>
        </div>{" "}
      </div>
      <div className='2xl:mx-auto 2xl:container 2xl:px-20 px-6 '>
        <div className='flex md:flex-row flex-col-reverse md:items-stretch items-center justify-center'>
          <div className='md:py-20 xl:w-1/2 sm:w-1/2 lg:mr-20 md:mr-6 flex flex-col md:items-end items-center justify-center xl:mr-28'>
            <div className='flex flex-col items-center justify-center px-2'>
              <h1 className='md:text-5xl text-3xl font-semibold text-center text-gray-800'>
                Características Generales
              </h1>
              <p class=' pt-5 text-center text-xl'>
                <p>
                  Te ofrecemos la primera membresía de salud en México. Contamos
                  con la mayoría de los hospitales privados, sin pagar deducible
                  ni coaseguro. A través de nuestra App solicite atención en
                  urgencias de forma ágil y eficiente. Todos nuestros planes son
                  individuales, aplican períodos de espera para
                  algunas enfermedades.
                </p>
              </p>
              <div className='flex relative items-center cursor-pointer pb-4 md:pb-4'>
                <button
                  onClick={() => navigate("/caractgen")}
                  className='bg-[#1e49a6] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-white'
                >
                  {" "}
                  Más información
                </button>
              </div>

              <div className=' mt-6 w-20 h-20 relative mr-5'>
                <div className=' '>
                  <img width={300} src={log} alt='logo' />
                </div>
              </div>
            </div>
          </div>
          <div className='py-12 xl:w-1/2 lg:w-1/3 sm:w-1/2'>
            <img
              src={img1}
              alt='image ow a woman studying'
              className='h-full rounded-md object-cover object-center md:block hidden'
            />
            <img
              src={img1}
              alt='image ow a woman studying'
              className='h-auto w-auto md:hidden block'
            />
          </div>
        </div>
      </div>

      <h1 className='lg:text-4xl text-3xl font-bold text-center text-[#1e49a6] pt-10'>
        "Disfruta de nuestros planes"
      </h1>
      {/* Inicio nuevo apartado */}
      <div className='mx-auto container py-12 px-4 md:px-6 2xl:px-0 flex justify-center items-center'>
        <div className='flex flex-col lg:flex-row justify-center items-center lg:space-x-8 space-y-6 lg:space-y-0'>
          <div className='relative'>
            <img className='hidden lg:block' src={indivmax} alt='table-chair' />
            <img
              className='hidden sm:block lg:hidden'
              src={indivmid}
              alt='table-chair'
            />
            <img className='sm:hidden' src={indivsm} alt='table-chair' />
            <div className='absolute bottom-4 sm:bottom-10 inset-x-4 sm:inset-x-10 p-6 bg-white flex flex-col justify-start items-start'>
              <div>
                <p className='text-2xl font-semibold leading-6 text-gray-800'>
                  Planes Individuales / INDIVIDUAL PLUS
                </p>
              </div>
              <div className='mt-2'>
                <p className='text-base leading-6 sm:leading-4 text-black'>
                  Ofrecemos dos opciones de suma asegurada que se reinstalan
                  anualmente.
                </p>
              </div>
              <div className='mt-6'>
                <div className='flex justify-between items-center space-x-2'>
                  <a
                    className='text-base font-medium leading-none hover:underline text-[#1e49a6]'
                    href='/planesindividuales'
                  >
                    Mas informacion
                  </a>
                  <svg
                    className='fill-current'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3.33337 8H12.6667'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M10 10.6667L12.6667 8'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M10 5.33333L12.6667 8'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <div className='relative'>
            <img
              className='hidden lg:block'
              src={extedadmax}
              alt='chair-wood-fire'
            />
            <img
              className='hidden sm:block lg:hidden'
              src={extedadmid}
              alt='chair-wood-fire'
            />
            <img className='sm:hidden' src={extedadsm} alt='chair-wood-fire' />
            <div className='absolute bottom-4 sm:bottom-10 inset-x-4 sm:inset-x-10 p-6 bg-white flex flex-col justify-start items-start'>
              <div>
                <p className='text-2xl font-semibold leading-6 text-gray-800'>
                  Extensión de edad
                </p>
              </div>
              <div className='mt-2'>
                <p className='text-base leading-6 sm:leading-4 text-black'>
                  Extiende tu protección a partir de los 70 hasta los 99 años.
                </p>
              </div>
              <div className='mt-6'>
                <div className='flex justify-between items-center space-x-2'>
                  <a
                    className='text-base font-medium leading-none hover:underline text-[#1e49a6]'
                    href='/planextension'
                  >
                    Mas informacion
                  </a>
                  <svg
                    className='fill-current'
                    width='16'
                    height='16'
                    viewBox='0 0 16 16'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M3.33337 8H12.6667'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M10 10.6667L12.6667 8'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                    <path
                      d='M10 5.33333L12.6667 8'
                      stroke='currentColor'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Final nuevo apartado */}
      <div className=' text-center px-4'>
        <p className=' mt-4 text-center font-semibold'>
          Horario de atención : lunes a viernes de 9 am a 6 pm sábado de 9 am a
          4 pm
        </p>
        <a href='https://wa.me/525621624964?text=Vi%20en%20Smarts%20Finances%20su%20producto%20y%20me%20interesa'>
          <button className=' bg-[#25D366] w-[280px] rounded-md font-medium my-5 mx-auto py-3 text-white'>
            Dudas del producto
            <span className=' absolute  justify-center -mt-1 mr-3 px-2 text-white text-3xl '>
              <AiOutlineWhatsApp />
            </span>
          </button>
        </a>
      </div>
      <div className='flex relative items-center cursor-pointer  md:pb-4 '>
        <button
          onClick={() => navigate("/mutuus")}
          className='bg-[#1e49a6] w-[300px] rounded-md font-medium my-6 mx-auto py-3 text-white'
        >
          {" "}
          Me interesa comprar
        </button>
      </div>
      <div className='pb-32 pt-16 md:mx-auto md:container px-4'>
        <div className='mx-auto'>
          <div className='flex flex-wrap flex-row-reverse items-center'>
            <div className='md:w-1/2 lg:w-2/3 w-full lg:pl-20 md:pl-10 sm:pl-0 pl-0'>
              <div className='py-2 text-color'>
                <h1 className='text-2xl lg:text-5xl md:leading-snug f-f-l font-semibold '>
                  Planes Empresariales
                </h1>
                <h2 className='text-lg lg:text-xl leading-7 md:leading-10 f-f-r py-8'>
                  Nos especializamos en colectivos de 10 titulares en adelante,
                  descuento por numero mayor de personas. Suma asegurada de 1 y
                  2 MDP, edad máxima 69 años. Si el empleado deja de trabajar
                  puede conservar su membresía con un plan individual.
                </h2>
                <div className='flex items-center cursor-pointer pb-4 md:pb-0'>
                  <a
                    className='f-f-r text-lg lg:text-2xl font-semibold underline text-[#1e49a6]'
                    href='/planesempresariales'
                  >
                    Mas informacion
                  </a>
                  <div className='pl-2'>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width={24}
                      height={24}
                      viewBox='0 0 24 24'
                      fill='none'
                    >
                      <path
                        d='M13.1719 12L8.22192 7.04999L9.63592 5.63599L15.9999 12L9.63592 18.364L8.22192 16.95L13.1719 12Z'
                        fill='#1e49a6'
                      />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <div className='lg:w-1/3 md:w-1/2 w-full relative h-96 flex items-end justify-center'>
              <img
                className='absolute w-full h-full inset-0 object-cover object-center rounded-md'
                src='https://cdn.tuk.dev/assets/templates/prodify/invoicing-system.png'
                alt
              />
            </div>
          </div>
        </div>
      </div>

      <div className='2xl:mx-auto 2xl:container md:px-30 px-4 md:py-12 py-9'>
        <div className='relative rounded-md'>
          <img
            src={d}
            alt='city view'
            className=' w-full h-full rounded-md  object-center object-fill absolute sm:block hidden'
          />
          <img
            src='https://img.freepik.com/foto-gratis/familia-feliz-sentado-sofa-sala-estar_23-2148456279.jpg?t=st=1725460869~exp=1725464469~hmac=957c5737a9426de08b5d82b6bf5158c00d069188546179d4aec885e1d8e38540&w=1380'
            alt='city view'
            className='w-full h-full rounded-md absolute object-center object-fill sm:hidden'
          />
          <div className='text-xl relative z-20 bg-gradient-to-r from-[#1e49a6] to-transparent w-full h-full z-40 top-0 md:p-16 p-6 flex flex-col justify-between rounded-md '>
            <div>
              <h1 className='md:text-5xl text-3xl font-bold md:leading-10 leading-9 text-white sm:w-auto w-64'>
                MÁS BENEFICIOS
              </h1>
              <p className='text-xl leading-6 text-white xl:w-5/12 lg:w-8/12 md:w-10/12  2xl:pr-12 mt-5'>
                <ul class='list-disc '>
                  <li>
                    Servicio integral funerario Incluye cremación o inhumación,
                    así como gestión de trámites.{" "}
                  </li>
                  <li>Asistencia en viajes internacionales </li>
                  <li>Seguro de vida por muerte accidental.</li>
                  <li>Cobertura de emergencia en el extranjero.</li>
                </ul>
              </p>
            </div>
            <div className='md:mt-12 mt-20'>
              <button
                className='text-base font-medium leading-4 text-[#1e49a6] bg-white sm:w-auto w-full rounded p-4 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-white'
                onClick={() => navigate("/benefi")}
              >
                Más información
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preciosinte;
