import React from "react";
import Layoutus from "../components/Adminusers";
import Sisbar from "../components/SisNavbar";

function SisAdmUsers() {
  return (
    <div>
      <Sisbar />
      <Layoutus />
    </div>
  );
}

export default SisAdmUsers;
