import React from 'react'
import Sign from '../components/Signin';

function SignIn() {
  return (
    <div>
      <Sign />
    </div>
  )
}

export default SignIn