import React, { useEffect, Fragment, useState, useContext } from "react";
import Modal from "../components/ModalArchivo";
import { AiFillFolderOpen, AiOutlineCloudUpload } from "react-icons/ai";
import {
  collection,
  Firestore,
  getDocs,
  query,
  orderBy,
  Timestamp,
  where,
} from "firebase/firestore";
import { db } from "../firebase";
import { AuthContext } from "../context/AuthContext";

const UserInterface = () => {
  const { currentUser } = useContext(AuthContext);

  const [showModal, setShowModal] = useState(false);

  const [comprobantes, setComprobantes] = useState([]);
  const comprobantesCollection = collection(db, "comprobantes");

  const email = currentUser.email;
  console.log(email);
  const q = query(
    comprobantesCollection,

    where("nombre", "==", email || "")
  );

  const getComprobantes = async () => {
    const data = await getDocs(q);

    setComprobantes(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log(comprobantes);
  };

  // funcion para eliminar

  useEffect(() => {
    getComprobantes();
  }, []);

  const [search, setSearch] = useState("");
  console.log(search);
  return (
    <Fragment>
      <div className="absolute bg-[#2b3e50] w-full h-full">
        {/* Page title starts */}
        <div className="my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300">
          <div>
            <h4 className="text-2xl font-bold leading-tight text-white">
              Comprobantes
            </h4>
            <ul className="flex flex-col md:flex-row items-start md:items-center text-white text-sm mt-3">
              <li className="flex items-center mr-3 mt-3 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-paperclip"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9 l6.5 -6.5" />
                  </svg>
                </span>
                <span>Archivos</span>
              </li>
              <li className="flex items-center mr-3 mt-3 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-trending-up"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="3 17 9 11 13 15 21 7" />
                    <polyline points="14 7 21 7 21 14" />
                  </svg>
                </span>
                <span> Base de datos</span>
              </li>
            </ul>
          </div>
          <div className="mt-6 lg:mt-0 " id="button">
            <button
              onClick={() => setShowModal(true)}
              className=" mx-auto transition duration-150 ease-in-out hover:bg-blue-300 bg-blue-500 rounded text-white px-4 sm:px-8 py-2 text-xs sm:text-sm cursor-pointer"
            >
              <div className=" text-lg px-12">
                <AiOutlineCloudUpload />
              </div>
              Subir comprobante
            </button>
            <Modal isVisible={showModal} onClose={() => setShowModal(false)} />
          </div>
        </div>
        {/* Page title ends */}

        <div className="container mx-auto px-6">
          {/* Remove class [ h-64 ] when adding a card block */}
          {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
          <div className="w-full sm:px-6 rounded">
            <div className=" px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
              <div className="sm:flex items-center justify-between">
                <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                  Data Base
                </p>
              </div>
            </div>

            <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
              {/* search start */}
              <form>
                <label
                  for="default-search"
                  class="mb-2 text-sm font-medium text-gray-900 sr-only "
                >
                  Search
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500 \"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    id="default-search"
                    class="block  p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                    placeholder="Search date ..."
                    required
                  />
                </div>
              </form>
              {/* search end */}
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="h-16 w-full text-sm leading-none text-gray-800">
                    <th className="font-normal text-left pl-4">
                      Fecha de Subida
                    </th>
                    <th className="font-normal text-center pl-0">
                      Comprobante
                    </th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {comprobantes
                    .filter((comprobante) => {
                      return search.toLocaleLowerCase() === ""
                        ? comprobante
                        : comprobante.timeStamp
                            .toLocaleLowerCase()
                            .includes(search);
                    })
                    .map((comprobante) => (
                      <tr
                        key={comprobante.id}
                        className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
                      >
                        <td className="font-medium  items-center  ">
                          {comprobante.timeStamp.split("T")[0]}-
                          {comprobante.timeStamp.split("T")[1].split(".")[0]}
                        </td>

                        <div className=" text-center pt-5">
                          <a
                            href={comprobante.imagen}
                            class="bg-blue-300 hover:bg-blue-400 text-white font-bold py-2 px-4 rounded inline-flex"
                          >
                            <AiFillFolderOpen />
                          </a>
                        </div>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default UserInterface;
