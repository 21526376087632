import React from "react";
import Form from "../components/FormMutuus";
import { Toaster } from "react-hot-toast";

function FormMutuus() {
  return (
    <div>
      <Toaster position="top-center " duration="6000" />;
      <Form />
    </div>
  );
}

export default FormMutuus;
