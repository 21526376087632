import React from "react";

export default function Planextensiondeedad() {
  return (
    <div>
      <div className="xl:mx-auto xl:container py-20 2xl:px-0 px-6">
        <div className="lg:flex items-center justify-between">
          <div className=" lg:w-1/2 w-full px-5">
            <h1
              role="heading"
              className="md:text-5xl text-3xl font-bold leading-10 mt-3 text-gray-800"
            >
              Extensión de edad
            </h1>
            <p className="text-bold font-bold leading-4 text-gray-600 mt-5">
              Condiciones Generales
            </p>
            <p className="text-bold font-normal leading-6 text-gray-600 mt-5">
              Toma una decisión inteligente, extiende tu programa de SALUD
              INTEGRAL y mantente protegido después de que concluye tu programa
              a los 69 años.<td className="pt-2"></td> A partir de edad 70 y
              hasta los 99 años ! Paga una tarifa nivelada con el mismo costo
              sin importar tu edad.
            </p>

            <ol class="list-decimal pt-5 ">
              <li>Suma Asegurada de un millón de pesos.</li>
              <li>Renovación garantizada y vitalicia hasta 99 años.</li>
              <li>
                Se contrata al vencimiento del Plan de SALUD INTEGRAL a partir
                de los 70 años.
              </li>
            </ol>
          </div>

          <div
            className="xl:w-1/2 lg:w-7/12 relative w-full lg:mt-0 mt-12 md:px-8"
            role="list"
          >
            <div
              role="listitem"
              className="bg-white cursor-pointer shadow rounded-lg mt-3 flex relative z-30"
            >
              <div className="w-2.5  h-auto bg-[#1e49a6] rounded-tl-md rounded-bl-md" />
              <div className="w-full p-8">
                <div className="md:flex items-center justify-between">
                  <h2 className="text-2xl font-semibold leading-6 text-gray-800">
                    PLAN 1
                  </h2>
                  <p className=" text-xl font-semibold md:mt-0 mt-4 leading-6 text-gray-800">
                    Suma Asegurada $ 1,000,000
                    <span className=" text-sm"> MXN</span>
                  </p>
                </div>
                <p className="md:w-80 text-base leading-6 mt-4 text-gray-600">
                  12 pagos mensuales -{" "}
                  <strong>
                    $ 2,350.00<span className=" text-sm"> MXN</span>
                  </strong>{" "}
                  <td></td>
                  Total -{" "}
                  <strong>
                    $ 28,200.00<span className=" text-sm"> MXN</span>
                  </strong>{" "}
                  <td></td>Pago ANUAL -{" "}
                  <strong>
                    $ 25,000.00<span className=" text-sm"> MXN</span>
                  </strong>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
