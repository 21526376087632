import React from "react";
import Grafica from "../components/Charts";
import Tablas from "../components/Grafica";
function Rendimientos2023() {
  return (
    <div>
      <div className="flex flex-col lg:items-center justify-center w-full">
        <h1 className="font-semibold text-gray-800 text-2xl md:text-4xl text-center py-5">
          ESTRATEGIAS SMART FINANCES
        </h1>
      </div>
      <Grafica />
      <Tablas />
    </div>
  );
}

export default Rendimientos2023;
