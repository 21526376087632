import React from "react";
import Formserv from "../components/Formservicio";
import { Toaster } from "react-hot-toast";

function ProcedimientoServicio() {
  return (
    <div>
      <Toaster position="top-center " duration="6000" />;
      <Formserv />
    </div>
  );
}

export default ProcedimientoServicio;
