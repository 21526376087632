import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "react-hot-toast";

const FormPempresarial = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g1mv17g",
        "template_gz3be5t",
        form.current,
        "0jsvzs7nYDShvp7lC"
      )
      .then(
        (success) => {
          toast.success("Tus datos se mandaron correctamente!");
          form.current.reset();
        },
        (error) => {
          toast.error(error);
        }
      );
  };

  // Function will execute on click of button
  const onButtonClick = () => {
    // using Java Script method to get PDF file
    fetch("LAY-OUT-Salud-Integral-Empresarial.xlsx").then((response) => {
      response.blob().then((blob) => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement("a");
        alink.href = fileURL;
        alink.download = "LAY-OUT-Salud-Integral-Empresarial.xlsx";
        alink.click();
      });
    });
  };

  return (
    <div className="container mx-auto pt-16">
      <div className="lg:flex">
        <div className="xl:w-2/5 lg:w-2/5 bg-[#1e49a6] py-16 xl:rounded-bl rounded-tl rounded-tr xl:rounded-tr-none">
          <div className="xl:w-5/6 xl:px-0 px-8 mx-auto">
            <h1 className="xl:text-4xl text-3xl pb-4 text-white font-bold">
              COMO SOLICITAR SU PLAN EMPRESARIAL{" "}
            </h1>

            <ul class="list-decimal px-4 pt-0 text-white text-lg">
              <li>Llenar y enviar el formulario.</li>
              <li>
                Un ejecutivo se comunicará contigo para explicarte a detalle
                nuestro programa de SALUD INTEGRAL{" "}
              </li>
              <li>
                Una vez aceptada nuestra propuesta, te solicitaremos los
                siguientes datos de tus empleados: <td></td> • Nombre completo
                <td></td> • RFC<td></td> • Edad<td></td> • Sexo<td></td> •
                Direccion<td></td> • Condición (Titular o menor de edad
                asociado, en caso de incluirlo)<td></td> • De ser menor de edad,
                es indispensable contar con el RFC del titular.
              </li>
              <li>
                Con los datos adjuntos capturados en un LAY OUT (Descargalo{" "}
                <a
                  onClick={onButtonClick}
                  class="font-medium text-white underline  hover:no-underline cursor-pointer"
                >
                  Aquí
                </a>{" "}
                ), se solicitará información complementaria como identificación
                oficial (INE), declaración de salud en forma electrónica y
                firmada por cada empleado.
              </li>
              <li>
                Si cuentan actualmente con una póliza de GMM vigente, se
                solicitará la carátula de la misma para solicitar se respete su
                antigüedad.
              </li>
              <li>
                Se envía la información y documentos a la Cía. Aseguradora al
                área de selección de riesgos.
              </li>
              <li>
                Una vez teniendo la resolución nos comunicaremos contigo para
                informarte y te damos los datos para que puedas realizar el pago
                en línea.
              </li>
              <li>
                Una vez realizado el pago correspondiente, recibirán los
                documentos necesarios de tu programa de SALUD INTEGRAL a sus
                correos electrónicos.
              </li>
            </ul>
          </div>
        </div>
        <div className="xl:w-3/5 lg:w-3/5 bg-gray-200 h-full pt-5 pb-5 xl:pr-5 xl:pl-0 rounded-tr rounded-br">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="bg-white py-4 px-8 rounded-tr rounded-br"
          >
            <h1 className="text-4xl text-gray-800 font-extrabold mb-6">
              GMM EMPRESARIAL{" "}
            </h1>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs mb-6 xl:mb-0">
                <div className="flex flex-col">
                  <label
                    htmlFor="fempresarial_name"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Nombre del Contacto
                  </label>
                  <input
                    required
                    id="fempresarial_name"
                    name="fempresarial_name"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end">
                <div className="flex flex-col">
                  <label
                    htmlFor="fempresarial_mail"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Correo electronico
                  </label>
                  <input
                    required
                    id="fempresarial_mail"
                    name="fempresarial_mail"
                    type="email"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="fempresarial_celular"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Celular
                  </label>
                  <input
                    required
                    id="fempresarial_celular"
                    name="fempresarial_celular"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="fempresarial_empresa"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Nombre de la Empresa
                  </label>
                  <input
                    required
                    id="fempresarial_empresa"
                    name="fempresarial_empresa"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>

            <div className="flex w-full flex-wrap pt-4">
              <div className="w-2/4 max-w-xs">
                <div className="flex flex-col">
                  <label
                    htmlFor="fempresarial_ntrabajadores"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    No. De Trabajadores
                  </label>
                  <input
                    id="fempresarial_ntrabajadores"
                    name="fempresarial_ntrabajadores"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>
            <div className="flex w-full flex-wrap pt-4">
              <div className="w-2/4 max-w-xs">
                <label
                  htmlFor="fempresarial_plan"
                  className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                >
                  Plan de interés plan uno / plan dos
                </label>
                <input
                  id="fempresarial_plan"
                  name="fempresarial_plan"
                  type="text"
                  className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                  placeholder
                />
              </div>
            </div>

            <div className="w-full mt-6">
              <div className=" text-center">
                <button
                  type="submit"
                  value="Submit"
                  className="focus:outline-none bg-[#92d468] transition duration-150 ease-in-out hover:bg-[#1e49a6] rounded text-white px-8 py-3  text-lg font-bold leading-6"
                >
                  Enviar
                </button>
              </div>
            </div>
            <h1 className=" pt-20 pb-28 text-center md:text-6xl text-4xl font-black  text-[#1e49a6]  leading-snug ">
              ¡Nos especializamos en atender PYMES!
            </h1>
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormPempresarial;
