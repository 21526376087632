import React, { useState } from "react";

const Elite = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);
  const [open7, setOpen7] = useState(false);
  const [open8, setOpen8] = useState(false);
  const [open9, setOpen9] = useState(false);
  const [open10, setOpen10] = useState(false);
  const [open11, setOpen11] = useState(false);
  const [open12, setOpen12] = useState(false);
  const [open13, setOpen13] = useState(false);
  const [open14, setOpen14] = useState(false);
  const [open15, setOpen15] = useState(false);
  const [open16, setOpen16] = useState(false);
  const [open17, setOpen17] = useState(false);
  const [open18, setOpen18] = useState(false);
  const [open19, setOpen19] = useState(false);
  const [open20, setOpen20] = useState(false);
  const [open21, setOpen21] = useState(false);

  return (
    <div className=" lg:container lg:mx-auto lg:py-16 md:py-12 md:px-6 py-12 px-4 ">
      <h1 className="text-center lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 font-semibold">
        PROGRAMA ELITE
      </h1>

      <div className=" lg:mt-12 bg-gray-100 md:mt-10 mt-8 lg:py-7 lg:px-6 md:p-6 py-6 px-4 lg:w-8/12 w-full mx-auto ">
        <div className=" flex justify-between md:flex-row flex-col ">
          <div className=" md:mb-0 mb-8 md:text-left text-center">
            <h2 className=" font-medium text-xl leading-5 text-gray-800 lg:mb-2 mb-4">
              31 Beneficios
            </h2>
            <p className=" font-normal text-lg leading-5 text-gray-600   ">
              Los siguientes apartados son todos los beneficios de los planes{" "}
              <strong>BASICO, INTERMEDIO</strong>, más:
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-8/12 w-full mx-auto">
        {/* <!-- Question 1 --> */}
        <hr className=" w-full lg:mt-10 md:mt-12 md:mb-8 my-8" />

        <div className="w-full md:px-6  ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className=" ">
              <p className="flex justify-center items-center font-medium text-lg leading-6 md:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  1.
                </span>{" "}
                Dental
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen(!open)}
            >
              <svg
                className={"transform " + (open ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Horario: de 9 am a 5 pm. De lunes a viernes y 9 am a 1 pm.
                sábados, CDMX según disponibilidad de citas de cada odontólogo
                de la red.
              </li>
              <li>
                Asistencia: se le brindará a el <strong>BENEFICIARIO</strong> la
                asistencia odontológica por medio de su red de profesionales y
                técnicos.<td></td>El establecimiento perteneciente a la red
                dental, le aplicara los costos preferenciales y beneficios. Una
                vez que se haya comunicado al <strong>CA</strong> para
                solicitarlo.
              </li>
              <li>
                Como solicitarlo: el <stron>BENEFICIARIO</stron> deberá
                mencionar el número de control que le proporcione el{" "}
                <strong>CA</strong> y presentar su identificación en el
                establecimiento perteneciente a la red dental de el
                <strong> PRESTADOR</strong>, para que le apliquen los costos
                preferenciales y beneficios.{" "}
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                Consulta, (previa cita) ya sea preventiva o diagnóstica,
                comprende la realización del historial clínico, valoración y
                técnica de cepillado.
              </li>
              <li>Integración del historial clínico.</li>
              <li>
                Las radiografías periapicales diagnósticas necesarias, sin
                costo.
              </li>
              <li>
                Aplicación de flúor sin costo, para beneficiarios de hasta 12
                años.
              </li>
              <li>
                Una vez terminado el tratamiento, limpieza cosmética simple.{" "}
              </li>
              <li>
                Emergencias: consulta de emergencia en horario normal, revisión
                y eliminación de dolor agudo. (No incluye procedimiento).
              </li>
              <li>
                El <stron>BENEFICIARIO</stron> obtendrá precios preferentes en
                más de 150 tratamientos tabulados y publicados en la historia
                clínica, garantizando que es el precio máximo que cobrará el
                odontólogo de forma directa.{" "}
              </li>
              <li>
                Descuentos en tratamientos no tabulados de hasta el 30% sobre
                los precios ordinarios.{" "}
              </li>
              <li>
                Si el <stron>BENEFICIARIO</stron> no puede acudir a la cita por
                cualquier causa, se obliga a cancelarla al CA cuando menos con
                24 horas de anticipación.{" "}
              </li>
              <li>
                Si el <stron>BENEFICIARIO</stron> falta su cita programada 2
                veces sin previa cancelación o causa justificada, se extingue su
                derecho respecto a esta cobertura.{" "}
              </li>
              <li>
                Este servicio no cuenta con límites de eventos ni de cobertura
                durante la vigencia de su programa de beneficios.
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                Se queda facultado a declinar la solicitud de servicio o eximido
                de cualquier responsabilidad o del cumplimiento de las
                obligaciones derivadas de este servicio, cuando el servicio se
                solicite en lugares inhóspitos o sin infraestructura.
              </li>
              <li>
                Este servicio no es procedente para solicitar únicamente la
                limpieza dental, el BENEFICIARIO debe ser evaluado por el
                odontólogo para conocer el estado de su dentadura.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 2 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  2.
                </span>{" "}
                Visión
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen2(!open2)}
            >
              <svg
                className={"transform " + (open2 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open2 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Horario: de 9 am a 5 pm. De lunes a viernes y 9 am a 1 pm.
                sábados, CDMX según disponibilidad de citas de cada óptica de la
                red.
              </li>
              <li>
                Se brindará la asistencia al <strong>BENEFICIARIO</strong>{" "}
                dentro de las principales ciudades de la República Mexicana, por
                medio de su red de profesionales y técnicos especializados en
                visión, previa solicitud al <strong>CA</strong>
              </li>
              <li>
                Como solicitarlo: el <strong>BENEFICIARIO</strong> deberá
                mencionar el número de control que le proporcione el CA y
                presentar su identificación, así como su tarjeta de beneficios
                en el establecimiento perteneciente a la red de ópticas
                afiliadas, para que le apliquen los costos preferenciales y
                beneficios, como un examen de la vista gratuito y acceso al Plan
                Delta y Gama plus con precios preferenciales en la compra de
                armazones con micas graduadas.{" "}
              </li>
              <li>
                Plan Delta: consiste en armazón, micas graduadas terminadas y
                estuche por $399
              </li>
              <li>
                Plan Gama Plus: consiste en armazón, micas graduadas terminadas,
                estuche por $799
              </li>
              <li>
                Se entiende como Micas Blancas CR-30 Visión Sencilla
                (Terminadas) las micas prefabricadas terminadas al 100%, con
                graduación completa, las cuales abarcan las siguientes dioptrías
                ESPH +/- 4 CON CYL -2.
              </li>
              <li>
                Se entiende como Micas Bifocales Flap Top (Terminadas) las micas
                prefabricadas terminadas al 100% con graduación completa, las
                cuales abarcan las siguientes dioptrías ESPH +/- 3 CON ADD + 3.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>Examen de la vista de gabinete, sin costo.</li>
              <li>Presupuesto de lentes, sin costo.</li>
              <li>Armazones oftálmicos graduados.</li>
              <li>Precios preferenciales en productos no cubiertos.</li>
              <li>
                20% de descuento en micas con tratamiento y otras líneas de
                armazón.{" "}
              </li>
              <li>
                Reposiciones (las que sean necesarias) pagando el precio del
                paquete.
              </li>
              <li>
                Este servicio no tiene un límite de eventos para el{" "}
                <strong>BENEFICIARIO</strong> durante la vigencia de su programa
                de beneficios.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                Se queda facultado a declinar la solicitud de servicio o eximido
                de cualquier responsabilidad o del cumplimiento de las
                obligacionesderivados de esta asistencia cuando, el{" "}
                <strong>BENEFICIARIO</strong> requiera de lentes o graduaciones
                especiales.
              </li>
              <li>
                En caso de que el <strong>BENEFICIARIO</strong> solicite
                armazones de marca o de otro tipo de las no incluidas en este
                beneficio.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 3 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  3.
                </span>
                Red de descuentos médicos: descuentos en laboratorios,
                medicamentos, clínicas y hospitales.
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen3(!open3)}
            >
              <svg
                className={"transform " + (open ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open3 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                <strong>Importante</strong>: no se cuenta con directorios
                impresos, que a lo largo del tiempo pueden ser obsoletos, por lo
                que invariablemente deberá llamar al número telefónico asignado
                para conocer a los profesionistas y laboratorios que integran la
                red médica, así como sus horarios de atención. EL CA está
                disponible las 24 horas los 365 días del año, para brindar esta
                información.{" "}
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                <strong>HOSPITALES Y CLINICAS:</strong> cuando el{" "}
                <strong>BENEFICIARIO</strong> requiera los servicios de una
                clínica u hospital deberá llamar al centro de atención para
                obtener detalles de los nosocomios disponibles, la ubicación y
                descuentos aplicables, ya que pueden variar entre un 5% al 25%
                según el tipo de servicio y la clínica u hospital que se trate.{" "}
              </li>
              <li>
                <strong>LABORATORIOS</strong>: Si el{" "}
                <strong>BENEFICIARIO</strong> necesita conocer los laboratorios
                donde puede tener acceso a descuentos entre el 10 al 50%, así
                como la ubicación, sus horarios de atención y tipos de
                servicios, deberá comunicarse al CA para que un ejecutivo
                especializado lo asesore al respecto y conozca la forma de hacer
                efectivos los beneficios descritos.
              </li>
              <li>
                <strong>FARMACIAS ON LINE</strong>: cuando el{" "}
                <strong>BENEFICIARIO</strong> compre medicamentos en alguna de
                las farmacias online afiliadas a la red de descuentos, el{" "}
                <strong>BENEFICIARIO</strong> obtendrá cuando menos un 5% de
                descuento de manera permanente durante toda la vigencia de su
                membresía.{" "}
              </li>
              <li>
                <strong>GARANTIA DE SATISFACCIÓN</strong>: Si el médico,
                hospital, clínica, laboratorio, farmacia online participante en
                la red de descuentos de el <strong>PRESTADOR</strong>, no hace
                válido el descuento o precio descrito en estas condiciones
                generales del programa de beneficios, el{" "}
                <strong>PRESTADOR</strong> se obliga a compensar al{" "}
                <strong>BENEFICIARIO</strong>
                por el equivalente del monto del descuento no aplicado.{" "}
              </li>
              <li>
                Para hacer efectiva esta garantía el{" "}
                <strong>BENEFICIARIO</strong> deberá llamar al{" "}
                <strong>CA</strong> del <strong>PRESTADOR</strong> para reportar
                lo sucedido y enviar por el medio más idóneo el comprobante de
                pago en donde no se aplicó el beneficio. El{" "}
                <strong>PRESTADOR</strong> se compromete a dar una respuesta en
                un plazo no mayor de 5 días hábiles.{" "}
              </li>
              <li>
                El servicio de red de descuentos médicos es ilimitado para el{" "}
                <strong>BENEFICIARIO</strong> durante la vigencia de su programa
                de beneficios.
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR </strong> queda facultado a declinar la
                solicitud de servicio o quedará eximido de cualquier
                responsabilidad o del cumplimiento de sus obligaciones derivadas
                de los servicios que integran la asistencia de descuentos
                médicos, cuando:
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> solicite los descuentos con
                médicos, clínicas, hospitales, laboratorios o farmacias que no
                se encuentren afiliados a la red o cuando el{" "}
                <strong>BENEFICIARIO</strong> no siga las indicaciones para
                hacer efectivo el descuento.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 4 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6  ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  4.
                </span>
                Costo preferente en médico general, especialista y alta
                especialidad
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen4(!open4)}
            >
              <svg
                className={"transform " + (open4 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open4 ? "block" : "hidden")}
          >
            <ol class="list-decimal">
              <li>
                <strong>CONSULTAS MÉDICAS</strong>: El{" "}
                <strong>BENEFICIARIO</strong> podrá solicitar vía telefónica, la
                coordinación de una cita médica con algún médico certificado
                perteneciente a la red médica vigente.{" "}
              </li>
              <li>
                Se le garantiza al <strong>BENEFICIARIO</strong> el acceso a los
                siguientes costos preferenciales, dependiendo de su localidad:{" "}
                <td></td>Médico general costo de $200 a $300 pesos mexicanos.{" "}
                <td></td>Médicos especialistas, costos de $450 a $650 pesos
                mexicanos.
                <td></td>Médicos de alta especialidad, costos de $650 a $850
                pesos mexicanos.
              </li>
              <li>
                El día de la cita médica, una hora antes, el coordinador de
                servicio en turno de el PRESTADOR llamará al{" "}
                <strong>BENEFICIARIO</strong> para recordarle los datos de su
                cita y la hora de la misma. Si por cualquier razón el{" "}
                <strong>BENEFICIARIO</strong> no puede acudir a la cita médica
                éste se obliga a cancelarla cuando menos un día antes de la
                fecha prevista.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 5 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  5.
                </span>
                Servicio funerario
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen5(!open5)}
            >
              <svg
                className={"transform " + (open5 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open5 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                El <strong>PRESTADOR</strong> pondrá a disposición del{" "}
                <strong>DEUDO RESPONSABLE</strong> su red de casas funerarias
                afiliadas, las 24 horas todos los días del año, dentro del
                territorio de la República Mexicana el servicio funerario
                estándar, haciéndose cargo del costo del mismo. Asistencia
                telefónica ilimitada 24/7 al <strong>DEUDO RESPONSABLE</strong>.{" "}
              </li>
              <li>
                Quien solicite el servicio deberá ser quien funja como{" "}
                <strong>DEUDO RESPONSABLE</strong>, la cual es la persona
                designada como responsable y deberá comunicar al personal del CA
                todas las decisiones de los deudos relacionadas con el servicio
                funerario, así mismo, se le hará saber las opciones funerarias
                disponibles en el lugar donde solicite el servicio o el
                prestador del servicio más cercano.{" "}
              </li>
              <li>
                Para poder verificar la procedencia del servicio el{" "}
                <strong>DEUDO RESPONSABLE</strong> se obliga a enviar al email o
                WhatsApp que le sea indicado, en formato de fotografía o PDF la
                siguiente documentación del fallecido:{" "}
              </li>
              <li>
                Acta de nacimiento, pasaporte o credencial del INE, comprobante
                de domicilio, certificado de defunción, en su caso acta de
                matrimonio y/o cualquier otro documento idóneo. Cualquier
                documento que avale que la persona es la titular o es
                beneficiario.{" "}
              </li>
              <li>
                <strong>PERIODO DE ESPERA</strong>: en el primer año de
                contratación de esta asistencia, aplica un período de espera de
                90 días a partir del inicio de vigencia de este servicio, para
                los decesos causados por una enfermedad terminal, sospechosos o
                confirmados COVID.{" "}
              </li>
              <li>
                NO APLICA este período de espera en caso de fallecimiento por
                accidente o enfermedad no terminal. ENFERMEDAD TERMINAL: aquella
                que está lo suficientemente avanzada o en fase de evolución
                irreversible y que ya no responde a ningún tratamiento para
                frenarla o curarla. Considerando las siguientes enfermedades:
                cualquier tipo de cáncer, lupus eritematoso sistémico, distrofia
                muscular, SIDA (VIH), esclerosis múltiple, hepatitis B o alguna
                otra hepatopatía grave, EPOC grave, enfermedades crónicas
                degenerativas en un estado avanzado o cualquier otra que así se
                considere médicamente y que se verifique en el certificado de
                defunción.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS</h1>
            <ol class="list-decimal">
              <li>
                Recolección del cuerpo en: hospital, domicilio o servicio
                forense.
              </li>
              <li>
                Gestión legal ante la autoridad competente en caso de deceso
                violento.
              </li>
              <li>Traslado del cuerpo a la sala de velación que se designe.</li>
              <li>
                Arreglo estético del cuerpo, limpieza, vestido y maquillaje
                facial no reconstructivo.
              </li>
              <li>Ataúd estándar.</li>
              <li>
                Uso de sala de velación por 24 horas o préstamo del equipo para
                velación en domicilio. (Mesa pedestal, (4) candeleros o
                torcheros y Cristo) hasta por 24 horas.{" "}
              </li>
              <li>
                Traslado del cuerpo al cementerio en la zona urbana donde
                ocurrió el deceso.{" "}
              </li>
              <li>
                En caso de cremación, el servicio incluye la renta del ataúd
                para la velación, traslado del cuerpo al crematorio, cremación y
                urna de madera estándar.
              </li>
              <li>
                Traslado del ataúd hasta 50 kilómetros a la redonde desde el
                lugar en donde se presentó el deceso para su velación y/o
                inhumación.
              </li>
              <li>
                En caso de que la velación y/o inhumación se realice en una
                localidad más alejada de los 50 kilómetros el{" "}
                <strong>DEUDO RESPONSABLE</strong>, se obliga a pagar el
                kilometraje excedente a una tarifa preferencial.
              </li>
              <li>
                Si por la distancia del lugar de inhumación, el transporte
                terrestre no fuera el idóneo, el <strong>PRESTADOR</strong> se
                obliga a coordinar el transporte aéreo a "cargo del{" "}
                <strong>DEUDO RESPONSABLE</strong>", siempre y cuando exista
                disponibilidad y/o vuelos por parte de las terminales y líneas
                aéreas. El costo del traslado aéreo deberá ser cubierto por el{" "}
                <strong>DEUDO RESPONSABLE</strong>.
              </li>
              <li>
                Este servicio de asistencia funeraria tiene un límite de 2
                eventos anuales, hasta un límite de $30,000 (treinta mil pesos
                00/100 M.N.) cada uno.
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> queda facultado para declinar la
                solicitud de servicio o quedará eximido de cualquier
                responsabilidad o del cumplimiento de sus obligaciones derivadas
                de los servicios que integran la asistencia funeraria cuando:{" "}
              </li>
              <li>
                Los <strong>BENEFICIARIOS</strong> o{" "}
                <strong>DEUDO RESPONSABLE</strong> no reporten con oportunidad
                el deceso y el <strong>PRESTADOR</strong> se encuentre en
                imposibilidad material de cumplir con el servicio, porque el
                sepelio ya se hubiera realizado sin conocimiento previo y
                oportuno por parte del <strong>PRESTADOR</strong>.{" "}
              </li>
              <li>
                Alguno de los <strong>BENEFICIARIOS</strong> o{" "}
                <strong>DEUDO RESPONSABLE</strong>
                solicite el reembolso o pago del servicio funerario al incurrir
                en la omisión, del punto anterior. Lo anterior en virtud de que
                esta prestación es un servicio y no un beneficio indemnizatorio.{" "}
              </li>
              <li>
                Los <strong>BENEFICIARIOS</strong> o{" "}
                <strong>DEUDO RESPONSABLE</strong> no envíen en formato
                electrónico, la documentación solicitada para acreditar la
                personalidad o entroncamiento de la persona fallecida.{" "}
              </li>
              <li>
                El servicio funerario se solicite en otra casa funeraria que no
                pertenezcan a la red en convenio con el{" "}
                <strong>PRESTADOR</strong>.{" "}
              </li>
              <li>
                Cuando el deceso ocurra porque el <strong>BENEFICIARIO</strong>{" "}
                atentó contra su propia vida.
              </li>
            </ol>
          </div>
        </div>

        <hr className=" w-full lg:mt-10 my-8" />
        {/* <!-- Question 5 --> */}

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  6.
                </span>
                Legal funerario
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen6(!open6)}
            >
              <svg
                className={"transform " + (open6 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open6 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año.</li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> pondrá a disposición del{" "}
                <strong>DEUDO RESPONSABLE</strong> su red de abogados para que
                coadyuven en los trámites legales que se requieran ante el
                Ministerio Público cuando el fallecimiento se haya originado por
                una situación violenta.
              </li>
              <li>
                En todo momento el abogado velará por aligerar la pena a él
                <strong> DEUDO RESPONSABLE</strong> y sus familiares gestionando
                de una manera pronta y expedita los trámites legales que se
                deriven de la carpeta de investigación y, por lo tanto, la
                entrega del cuerpo del fallecido a sus familiares para su
                velación y servicio funerario.
              </li>
              <li>
                El servicio de orientación legal funeraria no tiene costo, esta
                asistencia termina cuando el abogado obtiene la entrega del
                cuerpo del falleció a su familia, para su velación.
              </li>
            </ol>
          </div>
        </div>

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  7.
                </span>
                Legal sucesoria y de pensiones
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen7(!open7)}
            >
              <svg
                className={"transform " + (open7 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open7 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario de 9 am a 5 pm. CDMX de lunes a sábado. </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> pondrá a disposición de la familia
                de los
                <strong> BENEFICIARIOS</strong> su plantilla de abogados, para
                que sean consultados vía telefónica respecto a dudas o problemas
                jurídicos relacionados con el deceso del{" "}
                <strong>BENEFICIARIO</strong>, en las siguientes materias:
                Civil, Familiar, Mercantil y Laboral.
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> o{" "}
                <strong>DEUDO RESPONSABLE</strong>, deberá relatar al abogado
                los hechos que originan la incertidumbre legal. En caso de ser
                necesario el abogado podrá recibir en formato PDF o de
                fotografía vía correo electrónico, documentos diversos u
                oficiales para que el profesionista pueda estudiarlos y tener
                mayores elementos para emitir opinión profesional o asesoría
                legal. El <strong>CA</strong> indicará el correo para enviar
                dicha información.
              </li>
              <li>
                Los abogados del <strong>PRESTADOR</strong>, estarán disponibles
                las veces que sea necesario para consultas telefónicas
                subsecuentes derivadas del mismo asunto.
              </li>
              <li>
                En caso de urgencia legal y previa autorización del{" "}
                <strong> DEUDO RESPONSABLE</strong> o{" "}
                <strong>BENEFICIARIO</strong>, el abogado podrá remitirlo con un
                profesionista de la red en su ciudad, para tener una asesoría de
                forma presencial sin costo.{" "}
              </li>
              <li>
                En caso de que el usuario esté interesado en contratar al
                abogado referido el <strong>PRESTADOR</strong> gestionará una
                tarifa preferencial respecto de los honorarios del abogado,
                vigilará la prestación del servicio y supervisará que el mismo
                cumpla con los estándares de calidad en cuanto a tiempo, técnica
                jurídica y eficiencia.{" "}
              </li>
              <li>
                El servicio de orientación legal telefónica no tiene costo, ni
                límite de eventos durante la vigencia de su programa de
                beneficios.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  8.
                </span>
                Tanatológica
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen8(!open8)}
            >
              <svg
                className={"transform " + (open8 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open8 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: de 9 am a 5 pm. CDMX de lunes a sábado. </li>
              <li>Medios: video consulta o de manera telefónica.</li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                {" "}
                Ayuda en duelo y pérdidas emocionales por el deceso de un ser
                querido.
              </li>
              <li>
                {" "}
                Prevención y ayuda de trastornos psicológicos, como depresión,
                ansiedad, estrés, adicciones, etc.{" "}
              </li>
              <li>
                {" "}
                Referencia de centros de ayuda psicológica estatales o
                particulares.{" "}
              </li>
              <li>
                {" "}
                Apoyo a nivel familiar, en situaciones de tipo emocional con el
                fin de obtener la ayuda apropiada de acuerdo a su caso.{" "}
              </li>
              <li>
                {" "}
                Este servicio no tiene costo, ni límite de eventos durante la
                vigencia de su programa de beneficios.
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  9.
                </span>
                Plataforma E-Learning
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen9(!open9)}
            >
              <svg
                className={"transform " + (open9 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open9 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año.</li>
              <li>
                El <strong>PRESTADOR</strong> por medio de su plataforma
                E-learning de metodología y aprendizaje, pondrá a disposición
                del <strong>BENEFICIARIO</strong>, cursos especializados en
                diferentes campos para su autocapacitación.{" "}
              </li>
              <li>
                Entre los cursos que el <strong>BENEFICIARIO</strong> podrá
                tomar se encuentran cursos de: diseño, desarrollo personal,
                negocios, tecnología, administración, entre otros.{" "}
              </li>
              <li>
                Como acceder: el <strong>BENEFICIARIO</strong> deberá seguir los
                siguientes pasos: <td></td> Ingresar a la página web{" "}
                <a className=" text-sky-500">http://quality.uvelop.mx</a>{" "}
                <td></td>Ingresar el número de tarjeta y contraseña que aparece
                en su certificado digital.{" "}
              </li>
              <li>
                Este servicio es ilimitado durante la vigencia de su programa de
                beneficios para el <strong>BENEFICIARIO</strong>.
              </li>
            </ul>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  10.
                </span>
                Asistencia emergencia en el hogar:
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen10(!open10)}
            >
              <svg
                className={"transform " + (open10 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open10 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: de 9 am a 9 pm. CDMX de lunes a sábado.</li>
              <li>
                El <strong>PRESTADOR</strong> brindará los servicios de técnicos
                especializados para el hogar, dentro del territorio nacional y
                previa solicitud del <strong>BENEFICIARIO</strong> para su
                asistencia, en caso de emergencia técnica en el interior de su
                domicilio, para lo cual deberá enviar por email o WhatsApp foto
                de la avería que se pretenda reparar.
              </li>
              <li>
                El personal del <strong>CA</strong> coordinará el servicio
                enviando al técnico de la especialidad que se encuentre más
                cercano y disponible, el tiempo estimado de arribo es de 90
                minutos. Sin embargo, este tiempo puede variar. Si durante el
                horario nocturno no es posible enviar a un técnico, se
                concertará una cita a la mayor brevedad con el primer proveedor
                disponible.{" "}
              </li>
              <li>
                El Técnico realizará un diagnóstico de la avería, tomará
                fotografías o video y comunicará su reporte al{" "}
                <strong>CA</strong> para verificar la procedencia del servicio y
                el costo cotizado por el especialista.{" "}
              </li>
              <li>
                Si el costo de mano de obra y materiales no rebasa la cobertura
                de $800 procederá a realizar el mantenimiento correctivo. Si el
                costo es mayor a la cantidad señalada, el supervisor técnico del
                CA, procederá a revisar la cotización. Si esta continúa siendo
                mayor a los $800 procederá a notificárselo al el{" "}
                <strong>BENEFICIARIO</strong>, para confirmar si está de acuerdo
                en que se realice la reparación y pagar el excedente que resulte
                directamente al técnico.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">
              EXCLUSIONES DE LA ASISTENCIA HOGAR{" "}
            </h1>
            <ol class="list-decimal">
              <li>
                El <strong>CA</strong> queda facultado para declinar la
                solicitud de servicio o quedará eximido del cumplimiento de sus
                obligaciones derivadas de los servicios que integran esta
                asistencia, cuando:{" "}
              </li>
              <li>
                No se trate del inmueble en donde vive el{" "}
                <strong>BENEFICIARIO</strong> o cuando se solicite el servicio
                para mantenimiento preventivo como cambio de piezas de la caja
                del inodoro, gotera en las llaves o destapar caños.{" "}
              </li>
              <li>
                No procederá cuando se solicite al técnico en cerrajería, para
                abrir chapas en el interior del domicilio ya sea puertas, cajas
                o closets.{" "}
              </li>
              <li>
                No procederá cuando se solicite la asistencia por la rotura de
                vidrios por fenómenos naturales o cuando se trate de cualquier
                clase de espejos o cualquier tipo de vidrios finos, biselados o
                vitrales.{" "}
              </li>
              <li>
                No procederá cuando se solicite al técnico para realizar
                trabajos en las áreas comunes del inmueble, como son puerta de
                acceso al inmueble, pasillos, azotea o patios.{" "}
              </li>
              <li>
                Si el <strong>BENEFICIARIO</strong> se niega a cubrir el
                excedente, en este caso el <strong>BENEFICIARIO</strong> gastará
                uno de sus servicios disponibles.
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  11.
                </span>
                Plomería
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen11(!open11)}
            >
              <svg
                className={"transform " + (open11 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open11 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Solo aplica para la reparación de fallas por rotura o fuga en
                las instalaciones hidráulicas, sanitarias y gas que se
                encuentren en el interior del domicilio y requieran reparación
                de emergencia debido a que pongan en riesgo la seguridad del
                hogar o causen un daño irreparable.{" "}
              </li>
              <li>
                La reparación de fugas de gas, únicamente se hará cuando
                corresponda de la salida del tanque de gas a los muebles, como
                estufa, calentadores, aire acondicionado o secadora.{" "}
              </li>
            </ul>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  12.
                </span>
                Cerrajería
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen12(!open12)}
            >
              <svg
                className={"transform " + (open12 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open12 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                El CA enviará un técnico de esta especialidad para la reparación
                y/o apertura de chapas y cerraduras dañadas por descompostura,
                accidente, robo o intento del mismo, que pongan en riesgo la
                seguridad del domicilio del usuario o no permita el acceso al
                mismo.
              </li>
              <li>
                El servicio es procedente sólo en las puertas exteriores de
                acceso al inmueble (casa o departamento, para uso habitacional).{" "}
              </li>
            </ul>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  13.
                </span>
                Electricidad
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen13(!open13)}
            >
              <svg
                className={"transform " + (open13 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open13 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                El <strong>PRESTADOR</strong> enviará a un técnico de esta
                especialidad para el restablecimiento del servicio de energía
                eléctrica por causas de un corto circuito cuando la falla se
                origine en el interior del inmueble (casa o departamento, para
                uso habitacional) y que corresponda al domicilio permanente del{" "}
                <strong>BENEFICIARIO</strong>.
              </li>
              <li>
                La reparación o cambio de tableros eléctricos, switches de
                cuchillas, interruptores (breakes) o fusibles dañados por corto
                circuito o sobrecarga, sólo se hará en aquellas partes que
                pertenezcan a la instalación eléctrica del propio inmueble, y
                que corresponda al domicilio permanente del{" "}
                <strong>BENEFICIARIO</strong>.{" "}
              </li>
            </ul>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  14.
                </span>
                Vidriería
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen14(!open14)}
            >
              <svg
                className={"transform " + (open14 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open14 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                El <strong>PRESTADOR</strong> enviará a un técnico de esta
                especialidad para el cambio de vidrios rotos en puertas o
                ventanas que den a la calle y atenten contra la seguridad del
                hogar del <strong>BENEFICIARIO</strong>, cuando a consecuencia
                de un hecho súbito se produzca la rotura del mismo.
              </li>
            </ul>

            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> quedará eximido del cumplimiento
                de sus obligaciones derivadas en la asistencia Hogar modalidad
                Vidriería en:{" "}
              </li>
              <li>
                Cualquier clase de espejos y cualquier tipo de vidrios a pesar
                de ser parte de la edificación en caso de una rotura y no forme
                parte de cualquier fachada exterior de la vivienda que dé hacia
                la calle poniendo en peligro la seguridad del inmueble, sus
                ocupantes o terceros.{" "}
              </li>
              <li>Rotura de vidrios por fenómenos naturales. </li>
            </ol>
            <h1 className="mt-3 font-semibold">IMPORTANTE: </h1>
            <ul class="list-disc">
              <li>
                El <strong>BENEFICIARIO </strong> tendrá derecho a 2 servicios
                por cada especialidad durante la vigencia de su programa de
                beneficios (anualmente) y una cobertura de hasta $800 pesos
                (ochocientos 00/100 M.N.) por cada uno de ellos.
              </li>
            </ul>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  15.
                </span>
                Orientación legal
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen15(!open15)}
            >
              <svg
                className={"transform " + (open15 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open15 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año.</li>
              <li>
                Consultas: sobre cualquier problema jurídico en materia penal,
                civil, familiar y mercantil.
              </li>
              <li>Medios: video consulta o de manera telefónica.</li>
              <li>
                Se genera un expediente digital por cada{" "}
                <strong>BENEFICIARIO</strong>, para brindarle la mejor asesoría
                y llevar un seguimiento de su caso.{" "}
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                Procedimiento: el <strong>BENEFICIARIO</strong> deberá relatar
                al abogado los hechos que original la duda legal. En caso de ser
                necesario el abogado podrá recibir vía correo electrónico
                escáner o fotografías de documentos oficiales, para que pueda
                estudiarlos y tener mayores elementos para emitir una mejor
                opinión legal.{" "}
              </li>
              <li>
                Disponibilidad: los abogados estarán disponibles las veces que
                sea necesario para consultas telefónicas subsecuentes derivadas
                del mismo asunto.{" "}
              </li>
              <li>
                {" "}
                En caso de urgencia legal: y previa autorización del{" "}
                <strong> BENEFICIARIO</strong>, el abogado podrá remitirlo con
                un profesionista de la red en su ciudad, para tener una asesoría
                de forma presencial sin costo.{" "}
              </li>
              <li>
                {" "}
                Contratación del abogado referido: en caso de que el{" "}
                <strong>BENEFICIARIO</strong> esté interesado en contratar sus
                servicios, se gestionará una tarifa presencial respecto de los
                honorarios del abogado, vigilará la prestación del servicio y
                supervisará que el mismo cumpla con los estándares de calidad en
                cuanto a tiempo y eficiencia.{" "}
              </li>
              <li>
                {" "}
                Este servicio no tiene costo ni límite de eventos para el{" "}
                <strong>BENEFICIARIO</strong> durante la vigencia de su programa
                de beneficios{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  16.
                </span>
                Legal por tenencia de la mascota
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen16(!open16)}
            >
              <svg
                className={"transform " + (open16 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open16 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año</li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> pondrá a disposición del{" "}
                <strong>BENEFICIARIO</strong>, su plantilla de abogados para que
                sean consultados vía telefónica respecto a cualquier problema
                jurídico en las materias abajo mencionadas.{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> deberá relatar al abogado los
                hechos que original la duda legal. En caso de ser necesario el
                abogado podrá recibir vía correo electrónico o fotografías de
                documentos oficiales, para que el profesionista pueda
                estudiarlos y tener mayores elementos para emitir una mejor
                opinión legal.{" "}
              </li>
              <li>
                Los abogados del <strong>PRESTADOR</strong> estarán disponibles
                las veces que sea necesario para consultas telefónicas
                subsecuentes derivadas del mismo asunto u otros.{" "}
              </li>
              <li>
                {" "}
                En caso de urgencia legal y previa autorización del
                <strong> BENEFICIARIO</strong>, el abogado podrá remitirlo con
                un profesionista de la red en su ciudad, para tener una asesoría
                de forma presencial sin costo.
              </li>
              <li>
                {" "}
                En caso de que el usuario esté interesado en contratar al
                abogado referido, el <strong>PRESTADOR</strong> gestionará una
                tarifa preferencial respecto de los honorarios del abogado,
                vigilará la prestación del servicio y supervisará que el mismo
                cumpla con los estándares de calidad en cuanto a tiempo y
                eficiencia.{" "}
              </li>
              <li>
                Este servicio no tiene costo ni límite de eventos para el
                <strong> BENEFICIARIO</strong>, durante la vigencia de su
                programa de beneficios.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  17.
                </span>
                Consulta Veterinaria
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen17(!open17)}
            >
              <svg
                className={"transform " + (open17 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open17 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Horario: de 9 am a 5 pm. De lunes a viernes y 9 am a 1 pm.
                sábados, CDMX según disponibilidad de citas de cada veterinaria
                de la red.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                Cuando la mascota (perro) sufra alguna enfermedad, el{" "}
                <strong>PRESTADOR</strong> coordinará la consulta en su red, y
                se hará cargo de 2 consultas.{" "}
              </li>
              <li>
                {" "}
                Esta cobertura tiene un límite de 2 eventos hasta por $350 pesos
                (Trescientos cincuenta pesos 00/100 M.N.).{" "}
              </li>
              <li>
                {" "}
                El costo de las consultas veterinarias subsecuentes estarán a
                cargo del <strong>BENEFICIARIO</strong> a un costo preferente.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  18.
                </span>
                Desparasitación o vacuna antirrábica
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen18(!open18)}
            >
              <svg
                className={"transform " + (open18 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open18 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Horario: de 9 am a 5 pm. De lunes a viernes y 9 am a 1 pm.
                sábados, CDMX según disponibilidad de citas de cada veterinaria
                de la red.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                El <strong>BENEFICIARIO</strong> propietario de la mascota
                (perro) podrá solicitar anualmente dos desparasitaciones
                internas o vacunas antirrábicas para para su perro, con la red
                veterinaria de el <strong>PRESTADOR</strong>.{" "}
              </li>
              <li>Esta prestación no es reembolsable. </li>
              <li>
                {" "}
                Esta cobertura tiene un límite de 2 eventos combinados hasta por
                un costo de $300 pesos (Trescientos pesos 00/100 M.N.)
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  19.
                </span>
                Hotel para mascotas (perro)
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen19(!open19)}
            >
              <svg
                className={"transform " + (open19 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open19 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Horario: el CA le atiende los 365 días del año, las 24 horas del
                día, para solicitar el servicio.
              </li>
              <li>
                El <strong>PRESTADOR</strong> por medio de su{" "}
                <strong>"CA"</strong> se encargará de brindar la atención y
                orientación requerida por EL <strong>BENEFICIARIO</strong> al
                solicitar su servicio de hotel para mascota. El operador en
                turno se encargará de tomar los datos necesarios para que en
                horario de atención de nuestra red de proveedores pueda realizar
                la reservación necesaria para su mascota.{" "}
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                En caso de convalecencia por intervención quirúrgica o
                vacaciones del <strong>BENEFICIARIO</strong>, el{" "}
                <strong>PRESTADOR</strong> organizará y se hará cargo del
                hospedaje para la mascota (perro) hasta por 10 días en la
                pensión más cercana o adecuada perteneciente a su red.{" "}
              </li>
              <li>
                Para que la mascota (perro) pueda estar asegurada mientras el
                <strong> BENEFICIARIO</strong> está en posibilidades de hacerse
                cargo nuevamente de la misma.{" "}
              </li>
              <li>
                En caso de que el <strong>BENEFICIARIO</strong> requiera más
                días, éste únicamente se hará cargo de liquidar el costo de los
                días o monto excedente.{" "}
              </li>
              <li>
                La mascota (perro) deberá ser presentada en la pensión con
                alimento suficiente para los días que estará hospedada, cartilla
                de vacunación vigente y al corriente.{" "}
              </li>
              <li>
                Este servicio tiene una cobertura de hasta 10 días o $1,500
                pesos (Un mil quinientos pesos 00/100 M.N.) lo que ocurra
                primero y está limitado a 2 eventos por vigencia anual, de su
                programa de beneficios.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  20.
                </span>
                Servicio a dispositivos digitales
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen20(!open20)}
            >
              <svg
                className={"transform " + (open20 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open20 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: de 9 am a 6 pm. CDMX de lunes a sábado </li>
              <li>
                El <strong>PRESTADOR</strong> por medio de su personal técnico
                especializado, proporcionará a el <strong>BENEFICIARIO</strong>{" "}
                asesoría telefónica o por medio de chat, para resolver problemas
                que se presenten en los dispositivos digitales de su propiedad.{" "}
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> podrá acceder a una solución
                rápida llamando o vía WhatsApp al CA, quien brindará por medio
                de sus técnicos especializados asesoramiento telefónico
                inmediato y/o asistencia remota a través de internet.{" "}
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                Los técnicos de el <strong>PRESTADOR</strong>, brindarán
                asistencia tecnológica en los siguientes casos o contratiempos
                con su equipo o dispositivo:
              </li>
              <li>Si el equipo esta lento. </li>
              <li>Si no se puede ingresar al internet. </li>
              <li>Limpieza de virus. </li>
              <li>Instalación y configuración de periféricos. </li>
              <li>Instalación y configuración de software. </li>
              <li>Configuración de red. </li>
              <li>
                Si tiene dudas acerca de cómo configurar una cuenta de Facebook,
                Twitter, LinkedIn u otras redes sociales.{" "}
              </li>
              <li>
                Información acerca del uso de su dispositivo, así como de las
                aplicaciones que tenga instaladas o que quiera instalar.{" "}
              </li>
              <li>
                Consultas relacionadas con el uso de internet, certificados
                digitales, compras, seguridad, control parental, almacenamiento
                en la nube, huella digital o herencia digital.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">
              DISPOSITIVOS Y PLATAFORMAS SOPORTADAS PARA ESTE SERVICIO{" "}
            </h1>
            <ol class="list-decimal">
              <li>
                Computadoras de escritorio: Windows, desde la versión XP y Mac
                OS.{" "}
              </li>
              <li>Laptops: Windows, Mac OS y Android. </li>
              <li>Tablets: Windows, Mac OS y Android. </li>
              <li>Smartphones: Windows, Mac OS y Android.</li>
              <li>
                En general: Videoconsolas, impresoras, escaners, cualquier
                dispositivo relacionado con el hogar digital, cualquier
                aplicación de uso doméstico y paquetería office.{" "}
              </li>
              <li>
                Este servicio no tiene costo ni límite de eventos para el
                BENEFICIARIO, durante la vigencia de su programa de beneficios.{" "}
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  21.
                </span>
                Vial: servicio de grúa
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen21(!open21)}
            >
              <svg
                className={"transform " + (open21 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open21 ? "block" : "hidden")}
          >
            <h1 className="mt-3 font-semibold">VIAL </h1>
            <ul class="list-disc">
              <li>
                Horario: el <strong>CA</strong> le atiende los 365 días del año,
                las 24 horas del día, para solicitar el servicio.
              </li>
              <li>
                El <strong>PRESTADOR</strong> pondrá a disposición del{" "}
                <strong>BENEFICIARIO</strong> su red de proveedores asociados de
                grúa y auxilio vial, para que asistan a el{" "}
                <strong>BENEFICIARIO</strong>, cuando su automóvil haya sufrido
                una avería.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIO DE GRÚA </h1>
            <ol class="list-decimal">
              <li>
                En caso de que el vehículo del <strong>BENEFICIARIO</strong>{" "}
                sufra una avería que no pueda ser reparada, el{" "}
                <strong>PRESTADOR</strong> organizará y tomará a su cargo los
                servicios de remolque hasta el taller más cercano, designado de
                común acuerdo con el <strong>BENEFICIARIO</strong>.{" "}
              </li>
              <li>
                El <strong>CA</strong> recibirá la llamada del{" "}
                <strong>BENEFICIARIO</strong> y organizará la prestación del
                servicio.{" "}
              </li>
              <li>
                El <strong>CA</strong> vigilará el desarrollo del servicio y
                supervisará que el mismo cumpla con los estándares de calidad en
                cuanto a tiempo y eficiencia.{" "}
              </li>
              <li>
                El <strong>PRESTADOR</strong> sólo se hará cargo de este
                servicio hasta un arrastre máximo de 80 kilómetros o el
                equivalente a $1,500 (Un mil quinientos pesos 00/100 M.N.) lo
                que ocurra primero. El
                <strong> BENEFICIARIO</strong> se hará cargo del costo excedente
                el cual le será informado al momento de solicitar el servicio.{" "}
              </li>
              <li>
                En todos los casos, el <strong>BENEFICIARIO</strong> deberá
                acompañar al proveedor de la grúa durante el traslado.{" "}
              </li>
              <li>
                El servicio de arrastre de grúa está limitado a 2 eventos por
                vigencia anual de su programa de beneficios.{" "}
              </li>
              <li>
                En el caso del arrastre de grúa, el costo de las casetas de
                peaje estará a cargo del <strong>BENEFICIARIO</strong>.{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>PRESTADOR</strong> queda facultado a declinar la
                solicitud de servicio o quedará eximido de cualquier
                responsabilidad o del cumplimiento de sus obligaciones derivadas
                de los servicios que integran esta asistencia, cuando:{" "}
              </li>
              <li>No exista persona que acompañe el traslado (Grúa).</li>
              <li>
                Tratándose de vehículos que no sean propiedad del{" "}
                <strong>BENEFICIARIO</strong>.
              </li>
              <li>
                Si se trata de vehículos cuyo peso excede de las 2.5 toneladas.
              </li>
              <li>
                Quedan excluidos los vehículos que no sean de servicio
                particular, como taxis, uber, carga, etc.{" "}
              </li>
              <li>
                Al presentarse el operador de la grúa el servicio es impedido
                por autoridad competente, que se encuentre en el lugar donde
                requiera el servicio.
              </li>
              <li>
                En el caso de vehículos atascados en baches o inundaciones, el
                servicio quedará sujeto a la disponibilidad del proveedor de
                grúa.
              </li>
            </ol>
          </div>
        </div>
        <hr className=" w-full lg:mt-10 my-8" />
      </div>
    </div>
  );
};

export default Elite;
