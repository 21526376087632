import React from "react";
import Layoutadm from "../components/AdminInterface";
import Sisbar from "../components/SisNavbar";

function SisAdmin() {
  return (
    <div>
      <Sisbar />
      <Layoutadm />
    </div>
  );
}

export default SisAdmin;
