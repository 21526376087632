import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyC8_X5UxS1PJbbJgods8CPYqgEDyowCUNE",
  authDomain: "smartfinance-fadcc.firebaseapp.com",
  projectId: "smartfinance-fadcc",
  storageBucket: "smartfinance-fadcc.appspot.com",
  messagingSenderId: "768951322054",
  appId: "1:768951322054:web:c269ce1d9e9aba44761ec7",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const storage = getStorage(app);
