import React from 'react'
import  Comptable2  from '../components/Comptableempre';
import  Featuresfisc  from '../components/Featuresfisc';




function Individualp() {
  return (
    <div >
      <Comptable2 />
      <Featuresfisc />
    </div>
  );
}

export default Individualp;
