import React, { useContext } from "react";
import { auth, db, storage } from "../firebase";

import {
  doc,
  setDoc,
  collection,
  addDoc,
  serverTimestamp,
  getFirestore,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  uploadBytes,
} from "firebase/storage";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AuthContext } from "../context/AuthContext";

const ModalArchivo = ({ isVisible, onClose }) => {
  const { currentUser } = useContext(AuthContext);

  console.log(currentUser.uid);

  if (!isVisible) return null;

  let urlImDesc;

  const guardarInfo = async (e) => {
    e.preventDefault();

    const nombre = e.target.nombre.value;

    const newComprobante = {
      nombre: nombre,
      imagen: urlImDesc,
    };
    //fucnion
    try {
      await addDoc(collection(db, "comprobantes"), {
        ...newComprobante,
        timeStamp: new Date().toISOString(),
      });
    } catch (error) {
      console.log(error);
    }

    e.target.nombre.value = "";
    e.target.file.value = "";
  };

  const fileHandler = async (e) => {
    //detectar archivo
    const archivoI = e.target.files[0];
    // cargar esto al storage
    const refArchivo = ref(storage, `Comprobantes/${archivoI.name}`);
    await uploadBytes(refArchivo, archivoI);
    // obtener la url de la imagen
    urlImDesc = await getDownloadURL(refArchivo);
  };

  //dddddddddddddddddddddddddd

  const auth = getAuth();
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // User is signed in, see docs for a list of available properties
      // https://firebase.google.com/docs/reference/js/auth.user
      const uid = user.uid;
    } else {
      // User is signed out
      // ...
    }
  });

  return (
    <div className="">
      {/* modal start */}
      <div
        className="py-12   transition duration-150 ease-in-out z-10 absolute top-0 right-0 bottom-0 left-0"
        id="modal"
      >
        <div
          role="alert"
          className="container mx-auto w-11/12 md:w-2/3 max-w-lg"
        >
          <div className="relative p-4 md:p-8 bg-white dark:bg-white shadow-md rounded border border-gray-400">
            <div className="w-full flex items-center justify-start text-black  mb-5">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="icon icon-tabler icon-tabler-upload"
                width={40}
                height={40}
                viewBox="0 0 24 24"
                strokeWidth={1}
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <path d="M4 17v2a2 2 0 0 0 2 2h12a2 2 0 0 0 2 -2v-2" />
                <polyline points="7 9 12 4 17 9" />
                <line x1={12} y1={4} x2={12} y2={16} />
              </svg>
              <h1 className="text-left text-black dark:text-blackfont-lg font-bold tracking-normal leading-tight ml-2">
                Upload Files
              </h1>
            </div>
            <p className="mb-5 text-sm text-black  text-left font-semibold">
              Sube tu comprobante de pago
            </p>
            <form onSubmit={guardarInfo}>
              <div>
                <input
                  className=" rounded-xl  mt-3 text-base border-2 w-11/12 mb-10"
                  type="text"
                  id="nombre"
                  value={currentUser.email}
                />
              </div>
              <div>
                <input
                  class="block w-full text-lg text-gray-900 border border-gray-300 rounded-lg cursor-pointer bg-gray-50  focus:outline-none mb-5 "
                  id="file"
                  type="file"
                  onChange={fileHandler}
                />
              </div>

              <div className="flex items-center justify-start w-full">
                <button
                  type="submit"
                  className="focus:outline-none transition duration-150 ease-in-out hover:bg-blue-300 bg-blue-500  rounded text-white px-8 py-2 text-sm"
                >
                  Subir
                </button>
                <button
                  onClick={() => onClose()}
                  className="focus:outline-none ml-3 bg-gray-100    transition duration-150 text-gray-600  ease-in-out hover:border-gray-400 hover:bg-gray-300 border rounded px-8 py-2 text-sm"
                >
                  Cancel
                </button>
              </div>
            </form>
            <div className=" text-black text-center pt-5 font-semibold">
              <h1>Recargar página " F5 " Para visualizar los cambios</h1>
            </div>
            <div className="cursor-pointer absolute top-0 right-0 mt-4 mr-5 text-gray-400 hover:text-gray-600 dark:text-gray-400 transition duration-150 ease-in-out">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                aria-label="Close"
                className="icon icon-tabler icon-tabler-x"
                width={20}
                height={20}
                viewBox="0 0 24 24"
                strokeWidth="2.5"
                stroke="currentColor"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
                onClick={() => onClose()}
              >
                <path stroke="none" d="M0 0h24v24H0z" />
                <line x1={18} y1={6} x2={6} y2={18} />
                <line x1={6} y1={6} x2={18} y2={18} />
              </svg>
            </div>
          </div>
        </div>
      </div>
      {/* modal ends */}
    </div>
  );
};

export default ModalArchivo;
