import React, { useRef } from "react";
import emailjs from "@emailjs/browser";
import { Toaster, toast } from "react-hot-toast";
import img1 from "../assets/saludfisica1.jpg";
import img2 from "../assets/saludfisica1-cel.jpg";
const FormMutuus = () => {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_g1mv17g",
        "template_3i9vaom",
        form.current,
        "0jsvzs7nYDShvp7lC"
      )
      .then(
        (success) => {
          toast.success("Tus datos se mandaron correctamente!");
          form.current.reset();
        },
        (error) => {
          toast.error(error);
        }
      );
  };
  return (
    <div className="container mx-auto pt-16">
      <div className="lg:flex">
        <div className="xl:w-2/5 lg:w-2/5 bg-[#1e49a6] py-16 xl:rounded-bl rounded-tl rounded-tr xl:rounded-tr-none">
          <div className="xl:w-5/6 xl:px-0 px-8 mx-auto">
            <h1 className="xl:text-4xl text-3xl pb-4 text-white font-bold">
              ANTES DE COMPRAR RECUERDA:{" "}
            </h1>
            <h1 className="xl:text-2xl text-2xl pb-4 text-white font-bold">
              Puntos más importantes{" "}
            </h1>

            <ul class="list-disc px-4 pt-0 text-white text-lg">
              <li>NO Deducible</li>
              <li>NO Coaseguro</li>
              <li>NO aceptamos enfermedades pre-existentes </li>
              <li>
                Tenemos un porcentaje mínimo de rechazo en las solicitudes, por
                omisión o falsa declaración en tu cuestionario de salud.
              </li>
              <li>
                Puede existir una extra prima, en base al índice elevado de masa
                corporal.{" "}
              </li>
              <li>
                SI podemos incluir un menor gratis con un titular, en forma de
                pago anual.
              </li>
              <li>
                SI debes de ser 100% honesto en el “cuestionario de salud” y
                evitar contratiempos al momento de un siniestro. Como diabetes e
                hipertensión, así como cualquier otra enfermedad. La Aseguradora
                podrá solicitar una carta aclaratoria por algún padecimiento.
              </li>
              <li>
                Al ingresar al hospital de la red médica, no tienes que realizar
                ningún pago.
              </li>
              <li>
                Es indispensable que hagas uso de tu Plan a través de la App.
              </li>
              <li>
                Atención de urgencias: cuentas con atención médica ágil y
                eficiente, gracias a la geolocalización de las urgencias y la
                atención de Médicos Urgenciólogos.{" "}
              </li>
              <li>
                Cobertura de Maternidad (Periodo de espera de 30 días) : control
                prenatal, parto natural (Suma asegurada de 20 UMAM $66,010.60 *)
                o cesárea (Suma asegurada de 25 UMAM $82,513.25*),
                complicaciones del embarazo, cuidados del recién nacido (Suma
                asegurada de $ 1 millón de pesos), congénitos del recién nacido.{" "}
              </li>
              <li>Gastos Funerarios: cremación o inhumación. </li>
              <li>
                Descuentos en laboratorios clínicos y consultas médicas a un
                costo preferencial.{" "}
              </li>
              <li>
                Inicia tu protección una vez que ha sido aprobada tu solicitud.
              </li>
            </ul>
            <h1 className="xl:text-2xl text-2xl pb-4 text-white font-bold pt-10">
              Períodos de espera
            </h1>
            <h1 className="xl:text-lg text-lg pb-4 text-white font-bold">
              30 DÍAS:
            </h1>
            <ul class="list-disc px-4 pt-0 text-white text-lg">
              <li>Embarazo, para la gestación.</li>
              <li>COVID-19.</li>
            </ul>
            <h1 className="xl:text-lg text-lg pb-4 text-white font-bold pt-5">
              2 AÑOS:
            </h1>
            <ul class="list-disc px-4 pt-0 text-white text-lg">
              <li>
                Amígdalas y/o adenoides, padecimientos anorrectal, cirugía
                funcional de nariz; circuncisiones, litiasis; hernias, columna
                vertebral, tratamientos quirúrgicos u oncológicos del piso
                perineal, genitales internos y externos, próstata, vesícula y
                vías biliares, tumores mamarios, insuficiencia venosa, rodilla.
              </li>
            </ul>
            <h1 className="xl:text-lg text-lg pb-4 text-white font-bold pt-5">
              5 AÑOS:
            </h1>
            <ul class="list-disc px-4 pt-0 text-white text-lg">
              <li>Sida.</li>
            </ul>
            <h1 className="xl:text-2xl text-2xl pb-4 text-white font-bold pt-10">
              Edades de contratación
            </h1>
            <ul class="list-disc px-4 pt-0 text-white text-lg">
              <li>De los 18 hasta antes de los 64 años.</li>
              <li>Renovación hasta los 69 años.</li>
            </ul>
          </div>
        </div>
        <div className="xl:w-3/5 lg:w-3/5 bg-gray-200 h-full pt-5 pb-5 xl:pr-5 xl:pl-0 rounded-tr rounded-br">
          <form
            ref={form}
            onSubmit={sendEmail}
            className="bg-white py-4 px-8 rounded-tr rounded-br"
          >
            <h1 className="text-4xl text-gray-800 font-extrabold mb-6">
              SMART FINANCES - MUTUUS
            </h1>
            <h1 className="text-lg text-black font-extrabold mb-6 pt-5">
              PROCESO DE COMPRA:
            </h1>
            <ol class="list-decimal px-5 leading-7">
              <li>Envías el formulario.</li>
              <li>
                Un asesor especialista de MUTUUS (nuestro socio estratégico) te
                atenderá en un plazo no mayor a 24 hrs.
              </li>
              <li>
                Te dará la información completa del producto y aclarará tus
                dudas.{" "}
              </li>
              <li>
                Juntos llenaran el cuestionario de salud, el cual incluye tus
                antecedentes médicos, peso, estatura, etc. Recuerda que se
                verificará tu información con el buró de salud, por medio de la
                Aseguradora. Si no lo puedes llenar en este momento, te lo
                enviara y lo regresaras con la información solicitada.
              </li>
              <li>
                Te solicitará le envíes la siguiente documentación:
                <td></td>- Carta aclaratoria de algún antecedente médico (en
                caso de ser solicitado por tu Asesor).
                <td></td>- Identificación vigente: INE o pasaporte.
                <td></td>- Si eres extranjero, tu FM2 o FM3.
                <td></td>- Para los menores de edad, necesitamos su CURP.
                <td></td>- Tu CSF constancia de situación fiscal.
              </li>
              <li>
                Realizas el pago de tu PLAN INTEGRAL By MUTUUS, de acuerdo al
                plan y frecuencia de pago, por cualquier de los siguientes
                medios:
                <td></td>- Página de MUTUUS.
                <td></td>- Por medio del enlace o liga de pago.
                <td></td>- Transferencia bancaria (Estos datos te los
                proporcionará tu asesor).
                <td></td>{" "}
                <strong>
                  Si tienes algún contratiempo tu asesor te ayudará, con tus
                  datos bancarios y tu CVV dinámico, de un solo uso, para tu
                  mayor seguridad.
                </strong>
              </li>
              <li>
                Se envía tu documentación a la Cía Aseguradora para su revisión
                y autorización.
              </li>
              <li>
                En caso de que la Aseguradora lo solicite, te pediremos alguna
                carta aclaratoria por algún padecimiento.
              </li>
              <li>
                En un plazo máximo de 7 días hábiles, se emite tu certificado
                individual.
              </li>
              <li>
                Recibes en tu correo electrónico:
                <td></td>- Tu certificado individual.
                <td></td>- Condiciones generales de la Cia. Aseguradora General
                de Salud.
                <td></td>- Manual reglamento de MUTUUS.
                <td></td>- En el caso de haber contratado el plan 2, recibes el
                manual de asistencia en el extranjero.
                <td></td>-Instrucciones de cómo descargar e instalar la
                aplicación de MUTUUS
              </li>
              <li>
                En el supuesto de que tu solicitud fuera rechazada, se te
                devolverá el pago realizado en un plazo de 24 a 72 hrs.
                <td></td>
                <strong>
                  ¡Ahora puedes empezar a utilizar tu PLAN INTEGRAL By MUTUUS!
                </strong>
              </li>
            </ol>
            <div className=" text-center">
              <h1 className="text-lg text-[#1e49a6]  font-extrabold mb-6 pt-5">
                Para realizar tu compra, por favor llena este formulario
                sencillo y serás atendido por un asesor especialista en un plazo
                no mayor a 24 hrs.{" "}
              </h1>
            </div>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs mb-6 xl:mb-0">
                <div className="flex flex-col">
                  <label
                    htmlFor="findividual_name"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Nombre del Contacto
                  </label>
                  <input
                    required
                    id="findividual_name"
                    name="findividual_name"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end">
                <div className="flex flex-col">
                  <label
                    htmlFor="findividual_mail"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Correo electronico
                  </label>
                  <input
                    required
                    id="findividual_mail"
                    name="findividual_mail"
                    type="email"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="findividual_celular"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Celular
                  </label>
                  <input
                    required
                    id="findividual_celular"
                    name="findividual_celular"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="findividual_hora"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Horario de atencion
                  </label>
                  <input
                    required
                    id="findividual_hora"
                    name="findividual_hora"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="findividual_nadultos"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    No. De Adultos
                  </label>
                  <input
                    required
                    id="findividual_nadultos"
                    name="findividual_nadultos"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="findividual_menores"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    No. De Menores de 18 años:
                  </label>
                  <input
                    required
                    id="findividual_menores"
                    name="findividual_menores"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>
            <div className="block xl:flex w-full flex-wrap justify-between mb-6">
              <div className="w-2/4 max-w-xs pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="findividual_plan"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Tipo de plan de interés: uno / dos / plus
                  </label>
                  <input
                    required
                    id="findividual_plan"
                    name="findividual_plan"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
              <div className="w-2/4 max-w-xs xl:flex xl:justify-end pt-4">
                <div className="flex flex-col">
                  <label
                    htmlFor="findividual_recomendado"
                    className="text-gray-800 text-sm font-semibold leading-tight tracking-normal mb-2"
                  >
                    Recomendado por:
                  </label>
                  <input
                    required
                    id="findividual_recomendado"
                    name="findividual_recomendado"
                    type="text"
                    className="focus:outline-none focus:border focus:border-indigo-700 font-normal w-64 h-10 flex items-center pl-3 text-sm border-gray-300 rounded border"
                    placeholder
                  />
                </div>
              </div>
            </div>

            <div className="w-full mt-6">
              <div className=" text-center">
                <button
                  type="submit"
                  value="Submit"
                  className="focus:outline-none bg-[#92d468] transition duration-150 ease-in-out hover:bg-[#1e49a6] rounded text-white px-8 py-3  text-lg font-bold leading-6"
                >
                  Estoy de acuerdo
                </button>
              </div>
            </div>
            <img className="hidden lg:block pb-10 pt-5" src={img1} alt="" />
            <img className="md:hidden pt-5" src={img2} alt="" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default FormMutuus;
