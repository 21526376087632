import React from "react";
import Formserv from "../components/FormPlanesEmpresariales";
import { Toaster } from "react-hot-toast";

function FormularioContEmp() {
  return (
    <div>
      <Toaster position="top-center " duration="6000" />;
      <Formserv />
    </div>
  );
}

export default FormularioContEmp;
