import React from "react";
import Art1 from "../../components/blog/Artic1";

function Artic1() {
  return (
    <div>
      <Art1 />
    </div>
  );
}

export default Artic1;
