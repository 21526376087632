import React from "react";

function luis2023() {
  return (
    <div class=" pt-10">
      <div class="pb-25">
        <div class="mx-auto bg-gradient-to-l from-[#1e49a6] to-[#92d468] h-96 ">
          <div class="mx-auto container w-full flex flex-col justify-center items-center">
            <div class="flex justify-center items-center flex-col">
              <div class="mt-20">
                <h2 class="lg:text-6xl md:text-5xl text-4xl font-black leading-10 text-white text-center">
                  ¡ Nuestro Rendimiento en 2023 !
                </h2>
              </div>
              <div class="mt-6 mx-2 md:mx-0 text-center">
                <p class="lg:text-xl md:text-base leading-6 text-sm text-white">
                  Gracias por confiar en nosotros.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mx-auto container md:-mt-28 -mt-32 flex justify-center items-center">
          <div class="grid lg:grid-cols-2 md:grid-cols-2 grid-cols-2 gap-x-2 gap-y-2 lg:gap-x-6 md:gap-x-6 md:gap-y-6 md:gap-y-6">
            <div class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl">
              <h2 class="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800">
                24.12%
              </h2>
              <p class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center ">
                Rendimiento
              </p>
              <p class=" text-sm md:text-base lg:text-lg leading-none text-center font-medium ">
                Estrategia Conservadora{" "}
              </p>
            </div>
            <div class="flex justify-center flex-col items-center w-36 h-36 md:w-44 md:h-48 lg:w-56 lg:h-56 bg-white shadow rounded-2xl">
              <h2 class="lg:text-5xl md:text-4xl text-2xl font-extrabold leading-10 text-center text-gray-800">
                11.09%
              </h2>
              <p class="mt-4 text-sm md:text-base lg:text-lg leading-none text-center ">
                Comparación con Cete
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default luis2023;
