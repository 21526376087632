import React from 'react'
import Preciosinte from '../components/Preciosinte';

function Priceinte() {
    return (
        <div >
         <Preciosinte />
        </div>
      )
    }
export default Priceinte