import React from "react";
import { useNavigate } from "react-router-dom";
import { AiOutlineWhatsApp } from "react-icons/ai";

export const Inversionista = () => {
  const navigate = useNavigate();
  return (
    <div>
      <div className="mx-auto container py-20 px-6">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {/* info 1 starts*/}
          <div className="rounded">
            <div className="w-full h-100 flex flex-col justify-between items-start bg-white rounded-lg border border-white mb-6 py-5 px-4">
              <div>
                <h4 className="text-gray-800 font-bold mb-3 text-center">
                  MEDIOS DE CONTACTO
                </h4>
                <p className="text-gray-800 text-sm mb-5">
                  <ul class="list-disc px-5 font-semibold text-center">
                    <li>
                      Puedes tener contacto con un ejecutivo vía WhatsApp de MBG
                      24/7 Consulta depósitos, retiros y movimientos en general.
                    </li>
                  </ul>
                  <div className=" text-center ">
                    <a
                      href="https://api.whatsapp.com/send?phone=19293792282"
                      target="_blank"
                    >
                      <button className=" bg-[#25D366] w-[200px] rounded-md font-medium my-5 mx-auto py-3 text-white">
                        Contacto 24/7
                        <span className=" absolute  justify-center -mt-1 mr-3 px-2 text-white text-3xl ">
                          <AiOutlineWhatsApp />
                        </span>
                      </button>
                    </a>
                  </div>
                  <ul class="list-disc px-5 font-semibold text-center">
                    <li>
                      Requieres una atención personalizada para algún trámite?
                      Contacta con nuestro ejecutivo Rodrigo Silva, quien te
                      atenderá con gusto en horario de oficina .
                    </li>
                  </ul>
                  <div className=" text-center pt-3">
                    <a
                      href="https://api.whatsapp.com/send?phone=528123706870"
                      target="_blank"
                    >
                      <button className=" bg-[#25D366] w-[200px] rounded-md font-medium my-5 mx-auto py-3 text-white">
                        Rodrigo Silva
                        <span className=" absolute  justify-center -mt-1 mr-3 px-2 text-white text-3xl ">
                          <AiOutlineWhatsApp />
                        </span>
                      </button>
                    </a>
                  </div>
                </p>
              </div>
              <div className="w-full flex flex-col items-start">
                <div className="flex items-center justify-between text-gray-800 w-full font-semibold">
                  <p className="text-sm ">Smart Finances Team</p>
                </div>
              </div>
            </div>
          </div>
          {/* info 1 end */}
        </div>
      </div>
    </div>
  );
};

export default Inversionista;
