import React from "react";
import { AiOutlineUserAdd } from "react-icons/ai";
import Saludintegral from "../assets/Integral.png";
import mobil from "../assets/mobil.jpg";
import ImgBlog from "../assets/img-blog/blog-1.jpg";
import ImgBlogprin from "../assets/img-blog/blog.jpg";
import Luis from "./Luis2023";
import {
  AiOutlineRise,
  AiOutlineDollar,
  AiOutlineHistory,
  AiOutlineUsergroupAdd,
  AiOutlineFileDone,
  AiOutlineRedo,
  AiFillCaretRight,
  AiOutlineCluster,
  AiOutlineMedicineBox,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";

const About3 = () => {
  const navigate = useNavigate();

  return (
    <div className='2xl:container 2xl:mx-auto lg:py-0 lg:px-20 md:py-12 md:px-6 py-9 px-4'>
      <div className='flex flex-col items-center justify-center'>
        <h1 className='lg:text-5xl text-3xl font-bold text-center text-[#92d468] '>
          Salud Financiera
        </h1>
        <p className=' text-xl  leading-6 mt-4 text-center text-gray-600 2xl:w-2/5 font-bold '>
          Logra tu bienestar, mediante el ahorro y la inversión inteligente
          hacia una excelente economía personal y familiar.
        </p>
        <p className=' text-sm  leading-6 mt-4 text-center text-gray-600 2xl:w-2/5 font-bold '>
          Pueden existir restricciones geográficas para algunos países en
          nuestras cuentas individuales. Por favor solicita información a través
          de nuestros medios de contacto antes de registrarte.
        </p>
      </div>
      <Luis />

      <div className='md:mx-auto md:container px-4'>
        <div className='pt-10 md:pt-35'>
          <div className='container mx-auto'>
            <div className='flex flex-wrap items-center pb-12'>
              <div className='md:w-1/2 lg:w-2/3 w-full xl:pr-20 md:pr-6'>
                <div className='py-2 text-color'>
                  <h1 className='lg:text-4xl text-3xl  md:leading-snug tracking-tighter f-f-l font-bold'>
                    Cuentas Individuales{" "}
                  </h1>

                  <td></td>

                  <div className='flex p-7 shadow-md'>
                    <div className='mr-6 w-11   '>
                      <AiOutlineRise size='3rem' />
                    </div>
                    <div className=''>
                      <p className='font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800'>
                        Resultados
                      </p>
                      <p className='mt-2 font-normal text-base leading-6 text-gray-600'>
                        Reciba los mejores rendimientos del mercado en nuestras
                        estrategias conservadora y dinámica.
                      </p>
                    </div>
                  </div>

                  {/* <!-- Board Card --> */}
                  <div className='flex p-7 shadow-md'>
                    <div className='mr-6 w-11   '>
                      <AiOutlineHistory size='3rem' />
                    </div>
                    <div className=''>
                      <p className='font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800'>
                        Disponible
                      </p>
                      <p className='mt-2 font-normal text-base leading-6 text-gray-600'>
                        Tú tienes siempre el control de tú dinero y liquidez de
                        tus recursos en 48 hrs.
                      </p>
                    </div>
                  </div>

                  {/* <!-- Board Card --> */}
                  <div className='flex p-7 shadow-md'>
                    <div className='mr-6 w-11   '>
                      <AiOutlineDollar size='3rem' />
                    </div>
                    <div className=''>
                      <p className='font-semibold lg:text-2xl text-xl lg:leading-6 leading-5 text-gray-800'>
                        Accesible
                      </p>
                      <p className='mt-2 font-normal text-base leading-6 text-gray-600'>
                        {" "}
                        Invierte desde $1,000 USD y obtén los mejores
                        beneficios.
                      </p>
                    </div>
                  </div>

                  <div className='flex items-center cursor-pointer pb-4 md:pb-0 text-center'>
                    <a
                      className='bg-[#1e49a6] w-[250px] rounded-md font-medium my-6 mx-auto py-3 text-white'
                      href='https://multibankfx.com/es/account/live-account?ibNum=333587514'
                    >
                      REGISTRATE AHORA
                    </a>
                  </div>
                </div>
              </div>
              <div className='lg:w-1/3 md:w-1/2 w-full relative h-96 flex items-end justify-center'>
                <img
                  className='absolute w-full h-full inset-0 object-cover object-center rounded-md'
                  src='https://images.unsplash.com/photo-1572021335469-31706a17aaef?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80'
                  alt
                />
                <div className='relative z-10 bg-white rounded shadow p-6 w-10/13 -mb-20 '>
                  <div className='flex items-center justify-between w-full sm:w-full mb-8 '>
                    <div>
                      <h1 className=' text-center text-black font-bold text-lg'>
                        ESTRATEGIAS{" "}
                      </h1>
                      <div className='flex items-center '>
                        <div className='p-4 bg-yellow-200 rounded-md'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            className='icon icon-tabler icon-tabler-trending-up'
                            width={35}
                            height={40}
                            viewBox='0 0 24 24'
                            strokeWidth='1.5'
                            stroke='currentColor'
                            fill='none'
                            strokeLinecap='round'
                            strokeLinejoin='round'
                          >
                            <path stroke='none' d='M0 0h24v24H0z' />
                            <polyline points='3 17 9 11 13 15 21 7' />
                            <polyline points='14 7 21 7 21 14' />
                          </svg>
                        </div>

                        <p className='text-[#92d468]  text-xl tracking-wide font-bold leading-normal pl-3'>
                          Conservadora{" "}
                          <p className='text-black  text-lg tracking-wide font-bold leading-normal '>
                            1.70%
                            <a className=' text-black font-normal'> mensual</a>
                            <td></td> 20.4%
                            <a className=' text-black font-normal'>
                              {" "}
                              anualizado
                            </a>
                          </p>
                        </p>
                        <p className='text-[#92d468]  text-xl tracking-wide font-bold leading-normal pl-3'>
                          Dinámica{" "}
                          <p className='text-black  text-lg tracking-wide font-bold leading-normal '>
                            3.5%
                            <a className=' text-black font-normal'> mensual*</a>
                            <td></td> 42%
                            <a className=' text-black font-normal'>
                              {" "}
                              anualizado*
                            </a>
                          </p>
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className='relative mb-2'>
                    <hr className='h-1 rounded-sm bg-gray-200' />
                    <hr className='absolute top-0 h-1 w-7/12 rounded-sm bg-[#1e49a6] ' />
                  </div>
                  <h4 className='text-base text-gray-600 font-bold tracking-normal leading-5'>
                    En Octubre 2024
                  </h4>
                  <p className=' text-xs font-bold'>*Promedio</p>
                  <div className='flex items-center cursor-pointer pb-0 md:pb-0'>
                    <button
                      className=' bg-amber-400 w-[300px] rounded-md font-medium my-2 mx-auto py-2 text-white'
                      onClick={() => navigate("/rendimientos2024")}
                    >
                      Rendimientos historicos
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Blog --> */}
      <div className='px-6 xl:px-0 mx-auto container py-16'>
        <div className>
          <div className>
            <a href='https://smartsfinances.info/blog/'>
              <div className='lg:flex flex-wrap items-center'>
                <div className='lg:w-1/3 w-full pb-6 md:pb-0 md:pr-6 cursor-pointer'>
                  <div className='w-full'>
                    <h1 className='text-2xl lg:text-3xl font-bold text-[#1e49a6] tracking-wider lg:w-11/12 leading-relaxed'>
                      “¿Buscas consejos prácticos para mejorar tu salud
                      financiera y física? ¡Tenemos justo lo que necesitas!
                    </h1>
                    <div className='py-4'>
                      <svg
                        xmlns='http://www.w3.org/2000/svg'
                        width={60}
                        height={2}
                        viewBox='0 0 60 2'
                        fill='none'
                      >
                        <rect width={60} height={2} fill='#92d468' />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className='lg:w-2/3 w-full md:mt-10 lg:mt-0'>
                  <div className='slider'>
                    <div className='slide-ana'>
                      <div
                        className='lg:flex'
                        style={{
                          transform: "translateX(0%)",
                        }}
                      >
                        {/* <!-- articulo 1  --> */}
                        <div className='cursor-pointer'>
                          <div className='relative h-96'>
                            <img
                              className='absolute inset-0 object-cover object-center w-full h-full'
                              src={ImgBlog}
                              alt
                            />
                          </div>
                          <div className='py-6 border-b border-gray-200'>
                            <h2 className='text-2xl text-[#1e49a6] font-bold'>
                              ¿Por qué un seguro de gastos médicos es una
                              inversión a largo plazo y no un gasto?
                            </h2>
                            <p className='py-3 text-sm text-gray-700 leading-7'>
                              El tener un seguro de gastos médicos puede marcar
                              la diferencia en la vida . . .
                            </p>
                            <p className='text-sm text-[#92d468] font-bold'>
                              Miercoles, 13 Noviembre, 2024
                            </p>
                            <div className='pt-6'>
                              <div className='flex justify-between w-full items-center text-gray-600 text-sm pl-2 pr-6'>
                                <div className='flex items-center'>
                                  <div>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width={18}
                                      height={18}
                                      viewBox='0 0 18 18'
                                      fill='none'
                                    >
                                      <circle
                                        cx={9}
                                        cy={9}
                                        r='7.5'
                                        stroke='#718096'
                                        strokeWidth='1.25'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                      <path
                                        d='M9 4.8335V9.00016L11.5 11.5002'
                                        stroke='#718096'
                                        strokeWidth='1.25'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                    </svg>
                                  </div>
                                  <p className='pl-1'>5:00am</p>
                                </div>
                                <div className='flex items-center pl-2'>
                                  <div>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width={16}
                                      height={18}
                                      viewBox='0 0 16 18'
                                      fill='none'
                                    >
                                      <circle
                                        cx={8}
                                        cy='8.1665'
                                        r='2.5'
                                        stroke='#718096'
                                        strokeWidth='1.25'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                      <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M12.7141 12.8806L9.17831 16.4164C8.86602 16.729 8.44227 16.9047 8.0004 16.9047C7.55852 16.9047 7.13478 16.729 6.82248 16.4164L3.28581 12.8806C0.682416 10.2771 0.682489 6.05602 3.28597 3.45258C5.88946 0.84914 10.1105 0.84914 12.714 3.45258C15.3175 6.05602 15.3175 10.2771 12.7141 12.8806Z'
                                        stroke='#718096'
                                        strokeWidth='1.25'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                    </svg>
                                  </div>
                                  <p className='pl-1'>Mexico</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- articulo 1 end  --> */}
                        {/* <!-- articulo 2  --> */}
                        <div className=' lg:block lg:ml-8 cursor-pointer'>
                          <div className='relative h-96'>
                            <img
                              className='absolute inset-0 object-cover object-center w-full h-full'
                              src={ImgBlogprin}
                              alt
                            />
                          </div>
                          <div className='py-6 border-b border-gray-200'>
                            <h2 className='text-2xl text-[#1e49a6] font-bold'>
                              Consejos que te ayudarán a fortalecer tu educación
                              financiera
                            </h2>
                            <p className='py-3 text-sm text-gray-700 leading-7'>
                              Son el pilar para que puedas mejorar tu salud
                              financiera, conoce que dicen los expertos al
                              respecto . . .
                            </p>
                            <p className='text-sm text-[#92d468] font-bold'>
                              PROXIMAMENTE
                            </p>
                            <div className='pt-6'>
                              <div className='flex justify-between w-full items-center text-gray-600 text-sm pl-2 pr-6'>
                                <div className='flex items-center'>
                                  <div>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width={18}
                                      height={18}
                                      viewBox='0 0 18 18'
                                      fill='none'
                                    >
                                      <circle
                                        cx={9}
                                        cy={9}
                                        r='7.5'
                                        stroke='#718096'
                                        strokeWidth='1.25'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                      <path
                                        d='M9 4.8335V9.00016L11.5 11.5002'
                                        stroke='#718096'
                                        strokeWidth='1.25'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                    </svg>
                                  </div>
                                  <p className='pl-1'>5:00am</p>
                                </div>
                                <div className='flex items-center pl-2'>
                                  <div>
                                    <svg
                                      xmlns='http://www.w3.org/2000/svg'
                                      width={16}
                                      height={18}
                                      viewBox='0 0 16 18'
                                      fill='none'
                                    >
                                      <circle
                                        cx={8}
                                        cy='8.1665'
                                        r='2.5'
                                        stroke='#718096'
                                        strokeWidth='1.25'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                      <path
                                        fillRule='evenodd'
                                        clipRule='evenodd'
                                        d='M12.7141 12.8806L9.17831 16.4164C8.86602 16.729 8.44227 16.9047 8.0004 16.9047C7.55852 16.9047 7.13478 16.729 6.82248 16.4164L3.28581 12.8806C0.682416 10.2771 0.682489 6.05602 3.28597 3.45258C5.88946 0.84914 10.1105 0.84914 12.714 3.45258C15.3175 6.05602 15.3175 10.2771 12.7141 12.8806Z'
                                        stroke='#718096'
                                        strokeWidth='1.25'
                                        strokeLinecap='round'
                                        strokeLinejoin='round'
                                      />
                                    </svg>
                                  </div>
                                  <p className='pl-1'>Mexico</p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <!-- articulo 2 end  --> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

      {/* <!-- Blog end --> */}

      <div className='flex flex-col items-center justify-center'>
        <h1 className='lg:text-5xl text-3xl font-bold text-center text-[#1e49a6] '>
          Salud Física
        </h1>
        <p className='text-xl leading-6 mt-4 text-center text-gray-600 2xl:w-2/5 font-bold mb-7'>
          Contrata el mejor producto de SALUD INTEGRAL, con las mejores
          condiciones, precio y beneficios adicionales del mercado.
        </p>
      </div>

      <div className='2xl:mx-auto 2xl:container md:px-20 px-4 md:py-0 py-9'>
        <div className='relative rounded-md'>
          <img
            src={Saludintegral}
            alt='city view'
            className='w-full h-full rounded-md  object-center object-fill absolute sm:block hidden'
          />
          <img
            src={mobil}
            alt='city view'
            className='w-full h-full rounded-md absolute object-center object-fill sm:hidden'
          />
          <div className='text-xl relative z-20 bg-gradient-to-r  to-transparent w-full h-full z-40 top-0 md:p-16 p-6  flex flex-col justify-between rounded-md '>
            <div>
              <h1 className='md:text-5xl text-3xl font-bold md:leading-10 leading-9 text-white sm:w-auto w-64 lg:mt-7 mt-14'>
                SALUD INTEGRAL CON GASTOS MÉDICOS
              </h1>
              <p className='text-lg leading-6 text-white   md:w-10/12  2xl:pr-12 mt-5'>
                Si de momento no tienes vigente tu póliza de GMM o no la has
                contratado tenemos la solución perfecta, nuestra membresía SALUD
                INTEGRAL, adquiere tu membresía que incluye importantes
                beneficios adicionales, al mejor precio del mercado.
              </p>
            </div>
            <div className='md:mt-12 mt-20'>
              <button
                className='text-base font-medium leading-4 text-[#1e49a6] bg-white sm:w-auto w-full rounded p-4 focus:outline-none hover:bg-gray-100 focus:ring-2 focus:ring-offset-2 focus:ring-white'
                onClick={() => navigate("/planesinte")}
              >
                CONOCE MÁS
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About3;
