import React from "react";
import { useNavigate } from "react-router-dom";

const Especial = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div className="mx-auto container py-20 px-6">
        <div className="grid sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
          {/* info 1 starts*/}
          <div className="rounded">
            <div className="w-full h-100 flex flex-col justify-between items-start bg-blue-300 rounded-lg border border-blue-300 mb-6 py-5 px-4">
              <div>
                <h4 className="text-gray-800 font-bold mb-3">
                  INFORMACIÓN GENERAL
                </h4>
                <p className="text-gray-800 text-sm mb-5">
                  <ul class="list-disc px-5">
                    <li>Tus movimientos se registran en dólares americanos.</li>
                    <li>
                      Realizas tu aportación bajo la logística establecida. La
                      cual te informaremos vía Telegram.
                    </li>
                    <li>
                      Tu aportación se ingresa en Mex Atlantic Corporation,
                      empresa de MULTIBANK GROUP, una de las más importantes
                      empresas financieras a nivel mundial.
                    </li>
                    <li>
                      Subes en la sección de COMPROBANTES la ficha de depósito.
                      Recuerda repetir este proceso en cada aportación.
                    </li>
                    <li>
                      A partir de este momento tu aportación generará
                      rendimientos adicionales.
                    </li>
                    <li>
                      En esta página en la sección de REPORTE DE MOVIMIENTOS
                      podrás consultar tu información del mes anterior, los días
                      5 de cada mes.
                    </li>
                  </ul>
                </p>
              </div>
              <div className="w-full flex flex-col items-start">
                <div className="flex items-center justify-between text-gray-800 w-full">
                  <p className="text-sm ">Jul 17, 2023</p>
                  <div className="w-8 h-8 rounded-full bg-gray-800 text-white flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-pencil"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                      <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* info 1 end */}
          {/* info 2 starts*/}
          <div className="rounded">
            <div className="w-full  h-100 flex flex-col justify-between bg-[#92d468] rounded-lg border border-[#92d468] mb-6 py-5 px-4">
              <div>
                <h4 className="text-gray-800 font-bold mb-3">
                  REGALO ADICIONAL
                </h4>
                <p className="text-gray-800 text-sm">
                  <ul class="list-disc px-5">
                    <li>
                      Te obsequiamos nuestro PROGRAMA DE BENEFICIOS BÁSICO para
                      tus empleados o a quien tú elijas. Durante el tiempo que
                      mantengas el saldo con nosotros.<td></td>
                      <div className=" text-center cursor-pointer">
                        <a
                          onClick={() => navigate("/planesin")}
                          class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline text-center"
                        >
                          “Conocer más del Programa Básico”
                        </a>
                      </div>
                    </li>
                    <li>
                      De acuerdo a tu saldo será el numero de PROGRAMAS que
                      recibirás.
                    </li>
                    <li>
                      Solamente llena el FORMULARIO adjunto, con los datos de
                      las personas que serán beneficiadas con dicho programa.
                    </li>
                    <li>
                      Por favor, llena el siguiente formulario por cada persona
                      que recibirá el programa básico.
                    </li>
                    <li className=" text-center ">
                      <strong>
                        "En el nombre agregar entre paréntesis a nombre de quien
                        esta la cuenta".
                      </strong>
                    </li>
                  </ul>
                  <div className=" text-center pt-3">
                    <button
                      onClick={() => navigate("/form1")}
                      className="focus:outline-none bg-black transition duration-150 ease-in-out hover:bg-slate-400 rounded text-white px-8 py-3 text-sm font-bold leading-6 mb-5"
                    >
                      Formulario
                    </button>
                  </div>
                </p>
              </div>
              <div>
                <div className="flex items-center justify-between text-gray-800">
                  <p className="text-sm">Jul 17, 2023</p>
                  <div className="w-8 h-8 rounded-full bg-gray-800 text-white flex items-center justify-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="icon icon-tabler icon-tabler-pencil"
                      width={20}
                      height={20}
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      stroke="currentColor"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" />
                      <path d="M4 20h4l10.5 -10.5a1.5 1.5 0 0 0 -4 -4l-10.5 10.5v4" />
                      <line x1="13.5" y1="6.5" x2="17.5" y2="10.5" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* info 2 end */}
        </div>
      </div>
    </div>
  );
};
export default Especial;
