import React, { useState, useContext, Fragment } from "react";
import logosmall from "../assets/logosmall.png";
import user from "../assets/user.png";
import { BiLogOut, BiCaretDown } from "react-icons/bi";
import {
  AiOutlineUsergroupAdd,
  AiOutlineUnorderedList,
  AiOutlineSolution,
  AiOutlineFolderOpen,
  AiOutlineDiff,
  AiOutlineHome,
} from "react-icons/ai";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../context/AuthContext";
import { BsTelegram } from "react-icons/bs";

const SisNavbar = () => {
  const [show, setShow] = useState(false);
  const [product, setProduct] = useState(false);
  const [deliverables, setDeliverables] = useState(false);
  const [profile, setProfile] = useState(false);
  const navigate = useNavigate();

  const { onHandleLogOut, currentUser } = useContext(AuthContext);

  const cerrarSesion = () => {
    onHandleLogOut();
  };

  return (
    <div>
      {/* Navigation starts */}
      {/* Mobile */}
      <div
        className={
          show
            ? "w-full h-full absolute z-40  transform  translate-x-0 "
            : "   w-full h-full absolute z-40  transform -translate-x-full"
        }
      >
        <div
          className="bg-gray-800 opacity-50 inset-0 fixed w-full h-full"
          onClick={() => setShow(!show)}
        />
        <div className="  absolute left-0 z-40 top-0 bg-white shadow flex-col justify-between transition duration-150 ease-in-out h-full ">
          <div className="flex flex-col justify-between h-full">
            <div className="px-6 pt-4">
              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <img className=" h-10" src={logosmall} />
                  <p className="text-bold md:text2xl text-base pl-3 text-[#1e49a6]">
                    Smart Finances
                  </p>
                </div>
                <div
                  id="cross"
                  className=" text-gray-800"
                  onClick={() => setShow(!show)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-x"
                    width={24}
                    height={24}
                    viewBox="0 0 24 24"
                    strokeWidth={1}
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <line x1={18} y1={6} x2={6} y2={18} />
                    <line x1={6} y1={6} x2={18} y2={18} />
                  </svg>
                </div>
              </div>
              <ul className="f-m-m">
                <a>
                  <li className="text-white pt-8">
                    <div className="flex items-center">
                      <div className="md:w-6 md:h-6 w-5 h-5 text-indigo-500 text-xl">
                        <AiOutlineHome />
                      </div>
                      <p
                        className="text-indigo-500 ml-3 text-lg cursor-pointer"
                        onClick={() => navigate("/home")}
                      >
                        Home
                      </p>
                    </div>
                  </li>
                  {currentUser.rol === "admin" && (
                    <li className="text-white pt-8">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5 text-indigo-500 text-xl">
                          <AiOutlineUnorderedList />
                        </div>
                        <p
                          className="text-indigo-500 ml-3 text-lg cursor-pointer"
                          onClick={() => navigate("/adm")}
                        >
                          Database
                        </p>
                      </div>
                    </li>
                  )}
                  {currentUser.rol === "admin" && (
                    <li className="text-white pt-8">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5 text-indigo-500 text-xl">
                          <AiOutlineUsergroupAdd />
                        </div>

                        <p
                          className="text-indigo-500 ml-3 text-lg cursor-pointer"
                          onClick={() => navigate("/us")}
                        >
                          Users
                        </p>
                      </div>
                    </li>
                  )}
                  {currentUser.rol === "admin" && (
                    <li className="text-white pt-8">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5 text-indigo-500 text-xl">
                          <AiOutlineDiff />
                        </div>

                        <p
                          className="text-indigo-500 ml-3 text-lg cursor-pointer"
                          onClick={() => navigate("/estadosdecuenta")}
                        >
                          Reportes
                        </p>
                      </div>
                    </li>
                  )}
                  {currentUser.rol === "especial" && (
                    <li className="text-white pt-8">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5 text-indigo-500 text-xl">
                          <AiOutlineSolution />
                        </div>

                        <p
                          className="text-indigo-500 ml-3 text-lg cursor-pointer"
                          onClick={() => navigate("/user")}
                        >
                          Comprobantes
                        </p>
                      </div>
                    </li>
                  )}
                  {currentUser.rol === "especial" && (
                    <li className="text-white pt-8">
                      <div className="flex items-center">
                        <div className="md:w-6 md:h-6 w-5 h-5 text-indigo-500 text-xl">
                          <AiOutlineFolderOpen />
                        </div>

                        <p
                          className="text-indigo-500 ml-3 text-lg cursor-pointer"
                          onClick={() => navigate("/userestados")}
                        >
                          Reportes
                        </p>
                      </div>
                    </li>
                  )}
                </a>
              </ul>
            </div>

            <div className="w-full">
              <div className="border-t border-gray-300">
                <div className="w-full flex items-center justify-between px-2 pt-1">
                  <a
                    href="https://t.me/Proyesp"
                    className=" text-3xl text-blue-400 cursor-pointer"
                  >
                    <BsTelegram />
                  </a>
                  <div className="flex items-center">
                    <p className=" text-gray-800 text-base leading-4 -ml-8">
                      {currentUser.email}
                    </p>
                  </div>
                  <ul className="flex">
                    <div
                      onClick={cerrarSesion}
                      className="cursor-pointer text-black pt-5 pb-3 pl-3"
                    >
                      <div className=" text-2xl">
                        <BiLogOut />
                      </div>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Mobile */}
      <nav className="w-full mx-auto bg-white shadow">
        <div className="container px-6 justify-between h-16 flex items-center lg:items-stretch mx-auto">
          <div className="h-full flex items-center">
            <div className="mr-10 flex items-center">
              <img className=" h-14" src={logosmall} />
              <h3 className="text-base text-[#1e49a6] font-bold tracking-normal leading-tight ml-3 hidden lg:block">
                Smart Finances
              </h3>
            </div>
            <ul className="pr-12 xl:flex items-center h-full hidden">
              {currentUser.rol === "admin" && (
                <li
                  onClick={() => navigate("/adm")}
                  className="cursor-pointer h-full flex items-center text-sm w-20  tracking-normal border-r px-0"
                >
                  Database
                </li>
              )}
              {currentUser.rol === "admin" && (
                <li
                  onClick={() => navigate("/us")}
                  className=" w-24 cursor-pointer h-full flex items-center text-sm text-gry-800 mx-10 tracking-normal border-r"
                >
                  Usuarios
                </li>
              )}
              {currentUser.rol === "admin" && (
                <li
                  onClick={() => navigate("/estadosdecuenta")}
                  className=" w-28 cursor-pointer h-full flex items-center text-sm text-gry-800 -mx-0 tracking-normal border-r"
                >
                  Reportes
                </li>
              )}
              {currentUser.rol === "especial" && (
                <li
                  onClick={() => navigate("/user")}
                  className=" w-32 cursor-pointer h-full flex items-center text-sm text-gry-800 mr-5 tracking-normal border-r px-5 "
                >
                  Comprobantes
                </li>
              )}
              {currentUser.rol === "especial" && (
                <li
                  onClick={() => navigate("/userestados")}
                  className=" w-20 cursor-pointer h-full flex items-center text-sm text-gry-800 mr-10 tracking-normal border-r "
                >
                  Reportes
                </li>
              )}
            </ul>
          </div>

          <div className="h-full xl:flex items-center justify-end hidden">
            <div className="w-full h-full flex items-center">
              <a
                onClick={() => navigate("/home")}
                class="bg-blue-400 hover:bg-blue-300 text-white font-bold py-5 px-6 rounded inline-flex cursor-pointer"
              >
                <AiOutlineHome />
              </a>
              <div className="w-full h-full flex">
                <div
                  aria-haspopup="true"
                  className="cursor-pointer w-full flex items-center justify-end relative"
                  onClick={() => setProfile(!profile)}
                >
                  {profile ? (
                    <ul className="p-2 w-40 border-r bg-white absolute rounded z-40 left-0 shadow mt-32 ">
                      <li className="cursor-pointer text-gray-600 text-sm leading-3 tracking-normal py-2 hover:text-indigo-700 focus:text-indigo-700 focus:outline-none">
                        <div
                          onClick={cerrarSesion}
                          className="flex items-center"
                        >
                          <BiLogOut />
                          <button className="ml-2">Log out</button>
                        </div>
                      </li>
                    </ul>
                  ) : (
                    ""
                  )}

                  <a
                    href="https://t.me/Proyesp"
                    className=" text-4xl text-blue-400 cursor-pointer px-10"
                  >
                    <BsTelegram />
                  </a>
                  <img
                    className="rounded h-10 w-10 object-cover"
                    src={user}
                    alt="logo"
                  />
                  <p className="text-gray-800 text-sm ml-2">
                    {currentUser.email}{" "}
                  </p>
                  <div className="px-2">
                    <BiCaretDown />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="visible xl:hidden flex items-center relative">
            <svg
              onClick={() => setShow(!show)}
              aria-label="Main Menu"
              aria-haspopup="true"
              xmlns="http://www.w3.org/2000/svg"
              className="icon icon-tabler icon-tabler-menu"
              width={32}
              height={32}
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="#2c3e50"
              fill="none"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path stroke="none" d="M0 0h24v24H0z" />
              <line x1={4} y1={8} x2={20} y2={8} />
              <line x1={4} y1={16} x2={20} y2={16} />
            </svg>
          </div>
        </div>
      </nav>
      {/* Navigation ends */}
    </div>
  );
};

export default SisNavbar;
