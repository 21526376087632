import React, { useContext } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import Indiplan from "./pages/Individualp";
import Indiplan2 from "./pages/Individualp2";
import Pricein from "./pages/Priceindi";
import Priceem from "./pages/Priceempre";
import Priceinte from "./pages/Priceinte";
import CaracteristicasGenerales from "./pages/CaracteristicasGenerales";
import Beneficios from "./pages/Beneficios";
import About from "./pages/Aboutus";

import Planindiv from "./pages/Planesindividuales";
import Planextension from "./pages/Planextensiondeedad";
import Planempresariales from "./pages/PlanesEmpresariales";

import Serv from "./pages/ProcedimientoServicio";
import Shh from "./pages/Proyectoespecial";
import Formempresarial from "./pages/FormEmpresarial";
import FormModalPrincipal from "./pages/FormModalPrincipal";
import FormModalSecundario from "./pages/FormModalsecundario";
import FormMutuus from "./pages/FormMutuus";
import Pass from "./password/Pass";
import FormCompraEmp from "./pages/FormularioContEmp";
import FormRegalo from "./pages/FormRegalo";

import Rendimientos2023 from "./pages/Rendimientos2023";
import Rendimientos2024 from "./pages/Rendimientos2024";

import Signin from "./pages/SignIn";
import Sishome from "./pages/SisHome";
import Userinterface from "./pages/SisUser";
import Userestados from "./pages/SisUserEstadosdeCuenta";
import Admininterface from "./pages/SisAdmin";
import Admininusers from "./pages/SisAdmUsers";
import Admininestados from "./pages/SisAdmEstadosdeCuenta";

import BlogMenu from "./pages/blog/BlogMenu";
import Art1 from "./pages/blog/Artic1";

import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";
import MainLayout from "./layouts/MainLayout";
import SisLayout from "./layouts/SisLayout";
import { AuthContextProvider } from "./context/AuthContext";
import { AuthContext } from "./context/AuthContext";

const RequireAuth = ({ children }) => {
  const { currentUser } = useContext(AuthContext);
  return currentUser ? children : <Navigate to={"/signin"} />;
};

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainLayout />,
    children: [
      {
        path: "/",
        element: <App />,
        errorElement: <h1>error</h1>,
      },

      {
        path: "/especial",
        element: <Shh />,
      },
      {
        path: "/pass",
        element: <Pass />,
      },
      {
        path: "/procedimiento",
        element: <Serv />,
      },
      {
        path: "/planesin",
        element: <Indiplan />,
      },
      {
        path: "/planesem",
        element: <Indiplan2 />,
      },
      {
        path: "/planesinte",
        element: <Priceinte />,
      },
      {
        path: "/caractgen",
        element: <CaracteristicasGenerales />,
      },
      {
        path: "/benefi",
        element: <Beneficios />,
      },
      {
        path: "/pricein",
        element: <Pricein />,
      },
      {
        path: "/priceem",
        element: <Priceem />,
      },
      {
        path: "/aboutus",
        element: <About />,
      },

      {
        path: "/formempresarial",
        element: <Formempresarial />,
      },
      {
        path: "/form1",
        element: <FormModalPrincipal />,
      },
      {
        path: "/form2",
        element: <FormModalSecundario />,
      },
      {
        path: "/mutuus",
        element: <FormMutuus />,
      },
      {
        path: "/contrata",
        element: <FormCompraEmp />,
      },
      {
        path: "/regalo",
        element: <FormRegalo />,
      },
      {
        path: "/rendimientos2023",
        element: <Rendimientos2023 />,
      },
      {
        path: "/rendimientos2024",
        element: <Rendimientos2024 />,
      },
      {
        path: "/blog-menu",
        element: <BlogMenu />,
      },
      {
        path: "/articulo-1",
        element: <Art1 />,
      },
      {
        path: "/planesindividuales",
        element: <Planindiv />,
      },
      {
        path: "/planextension",
        element: <Planextension />,
      },
      {
        path: "/planesempresariales",
        element: <Planempresariales />,
      },
    ],
  },

  {
    path: "/signin",
    element: <Signin />,
  },
  {
    path: "/",
    element: (
      <RequireAuth>
        <SisLayout />
      </RequireAuth>
    ),
    children: [
      {
        path: "/home",
        element: <Sishome />,
      },
      {
        path: "/user",
        element: <Userinterface />,
      },
      {
        path: "/userestados",
        element: <Userestados />,
      },
      {
        path: "/adm",
        element: <Admininterface />,
      },
      {
        path: "/us",
        element: <Admininusers />,
      },
      {
        path: "/estadosdecuenta",
        element: <Admininestados />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <AuthContextProvider>
      <RouterProvider router={router} />
    </AuthContextProvider>
  </React.StrictMode>
);
