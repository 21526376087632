import React from "react";
import Blogm from "../../components/blog/BlogMenu";

function BlogMenu() {
  return (
    <div>
      <Blogm />
    </div>
  );
}

export default BlogMenu;
