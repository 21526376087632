import React, { useState } from "react";
import logogrande from "../assets/logogrande.png";
import { AiOutlineWhatsApp } from "react-icons/ai";
import { useNavigate } from "react-router-dom";

export default function Navbar() {
  const [show, setshow] = useState(false);
  const navigate = useNavigate();
  return (
    <div className=" bg-white ">
      <nav className="2xl:container 2xl:mx-auto sm:py-6 sm:px-7 py-5 px-4">
        {/* For large and Medium-sized Screen */}
        <div className="flex justify-between ">
          <div className=" flex space-x-3 items-center">
            <img
              className=" h-16 cursor-pointer"
              onClick={() => navigate("/")}
              src={logogrande}
            />
          </div>
          <div className="hidden sm:flex flex-row space-x-4 pt-3">
            <a
              className="rounded-md flex space-x-2 w-24 h-10 px-30 font-bold text-2xl leading-3 text-white bg-[#92d468] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#92d468] focus:bg-[#92d468] hover:bg-[#ace788] duration-150 justify-center items-center "
              href="https://api.whatsapp.com/send/?phone=6181386286"
            >
              <AiOutlineWhatsApp />
            </a>
            <a
              className="rounded-md flex space-x-2 w-24 h-10 px-36 font-semibold text-sm leading-3 text-white bg-[#92d468] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#92d468] focus:bg-[#92d468] hover:bg-[#ace788] duration-150 justify-center items-center"
              href="https://multibankfx.com/es/account/live-account?ibNum=333587514"
            >
              REGISTRARSE
            </a>
            <a href="https://smartsfinances.info/blog/">
              <div>
                <button
                  role="button"
                  aria-label="live chat"
                  className="focus:bg-[#92d468] focus:ring-2 focus:ring-offset-2 focus:[#92d468] bg-[#1e49a6]  hover:bg-[#9399e7] text-white px-6 py-2 font-semibold rounded focus:outline-none"
                >
                  Blog
                </button>
              </div>
            </a>
          </div>

          {/* Burger Icon */}
          <div
            id="bgIcon"
            onClick={() => setshow(!show)}
            className={`focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800  justify-center items-center sm:hidden cursor-pointer`}
          >
            <svg
              className={`${show ? "hidden" : ""}`}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                className=" transform duration-150"
                d="M4 6H20"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M4 12H20"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                className=" transform duration-150"
                d="M4 18H20"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
            <svg
              className={`${show ? "block" : "hidden"}`}
              width={24}
              height={24}
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M18 6L6 18"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M6 6L18 18"
                stroke="#1F2937"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </div>
        {/* Mobile and small-screen devices (toggle Menu) */}
        <div
          id="MobileNavigation"
          className={`${show ? "block" : "hidden"} sm:hidden mt-4 mx-auto`}
        >
          <div className="flex flex-col gap-4 mt-4 w-80 mx-auto ">
            <a
              className="rounded-md flex space-x-2 w-full h-10 font-normal text-3xlleading-3 text-white bg-[#92d468] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#92d468] focus:bg-[#92d468] hover:bg-[#ace788] duration-150 justify-center items-center"
              href="https://api.whatsapp.com/send/?phone=6181386286"
            >
              <AiOutlineWhatsApp />
            </a>
            <a
              className="rounded-md flex space-x-2 w-full h-10 font-normal text-sm leading-3 text-white bg-[#92d468] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#92d468] focus:bg-[#92d468] hover:bg-[#ace788] duration-150 justify-center items-center"
              href="https://multibankfx.com/es/account/live-account?ibNum=333587514"
            >
              REGISTRARSE
            </a>
            <a href="https://smartsfinances.info/blog/">
              <div>
                <button
                  role="button"
                  aria-label="live chat"
                  className="rounded-md flex space-x-2 w-full h-10 font-normal text-sm leading-3 text-white bg-[#1e49a6] focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#92d468] focus:bg-[#92d468] hover:bg-[#9399e7] duration-150 justify-center items-center"
                >
                  Blog
                </button>
              </div>
            </a>
          </div>
        </div>
      </nav>
    </div>
  );
}
