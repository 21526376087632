import React from "react";
import Rendi from "../components/Rendimientos2024";

function Rendimientos2024() {
  return (
    <div>
      <Rendi />
    </div>
  );
}

export default Rendimientos2024;
