import React from "react";
import Layout from "../components/HomeInterface";
import Sisbar from "../components/SisNavbar";

function SisHome() {
  return (
    <div>
      <Sisbar />
      <Layout />
    </div>
  );
}

export default SisHome;
