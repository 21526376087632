import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  addDoc,
  serverTimestamp,
  getDocs,
  query,
  orderBy,
} from "firebase/firestore";
import {
  ref,
  uploadBytesResumable,
  getDownloadURL,
  getStorage,
  uploadBytes,
} from "firebase/storage";
import { auth, db, storage } from "../firebase";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { AuthContext } from "../context/AuthContext";
import { AiOutlineFilePdf } from "react-icons/ai";
import Pagination from "../components/Pagination";

const AdmInterfaceEstadosCuenta = () => {
  let urlImDesc;

  const [estados_de_cuenta, setUsers] = useState([]);

  const usersCollection = query(
    collection(db, "estados_de_cuenta"),
    orderBy("timeStamp", "desc")
  );

  const getUsers = async () => {
    const data = await getDocs(usersCollection);

    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log(estados_de_cuenta);
  };
  // funcion para eliminar

  useEffect(() => {
    getUsers();
  }, []);

  const [search, setSearch] = useState("");
  console.log(search);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = estados_de_cuenta.slice(firstPostIndex, lastPostIndex);

  {
    /* Register estado de cuenta */
  }

  const guardarInfo = async (e) => {
    e.preventDefault();

    const nombre = e.target.nombre.value;

    const newComprobante = {
      nombre: nombre,
      imagen: urlImDesc,
    };
    //fucnion
    try {
      await addDoc(collection(db, "estados_de_cuenta"), {
        ...newComprobante,
        timeStamp: new Date().toISOString(),
      });
    } catch (error) {
      console.log(error);
    }

    e.target.nombre.value = "";
    e.target.file.value = "";
  };

  const fileHandler = async (e) => {
    //detectar archivo
    const archivoI = e.target.files[0];
    // cargar esto al storage
    const refArchivo = ref(storage, `Estados_de_Cuenta/${archivoI.name}`);
    await uploadBytes(refArchivo, archivoI);
    // obtener la url de la imagen
    urlImDesc = await getDownloadURL(refArchivo);
  };

  return (
    <div>
      <div className="absolute bg-[#2b3e50]  w-full h-full">
        {/* Page title starts */}
        <div className="my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b border-gray-300">
          <div>
            <h4 className="text-2xl font-bold leading-tight text-white">
              Control de Reportes
            </h4>
            <ul className="flex flex-col md:flex-row items-start md:items-center text-white text-sm mt-3">
              <li className="flex items-center mr-3 mt-3 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-paperclip"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9 l6.5 -6.5" />
                  </svg>
                </span>
                <span>Archivos</span>
              </li>
              <li className="flex items-center mr-3 mt-3 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-trending-up"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="3 17 9 11 13 15 21 7" />
                    <polyline points="14 7 21 7 21 14" />
                  </svg>
                </span>
                <span> Base de datos</span>
              </li>
            </ul>
          </div>
        </div>
        {/* Form register starts */}
        <form onSubmit={guardarInfo}>
          <div className=" flex  items-center justify-center ">
            <div className="     rounded   ">
              <div className="md:flex items-center mt-8">
                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                  <label className="text-base font-semibold leading-none text-white">
                    Correo electrónico:
                  </label>
                  <input
                    tabIndex={0}
                    arial-label="Please input country name"
                    id="nombre"
                    type="text"
                    className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-white border rounded border-gray-200 placeholder-gray-100"
                    required
                  />
                </div>
                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                  <label className="text-base font-semibold leading-none text-white mb-2">
                    Subir Reporte :
                  </label>

                  <input
                    class="block w-full text-lg text-gray-900  border-gray-300 rounded-sm cursor-pointer bg-gray-50  focus:outline-none -mb-7 "
                    id="file"
                    type="file"
                    onChange={fileHandler}
                  />
                </div>
              </div>

              <div className="flex items-center justify-center w-full">
                <button
                  type="submit"
                  className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-blue-400 hover:bg-blue-300 rounded  focus:ring-2 focus:ring-offset-2 focus:ring-[#1e49a6] focus:outline-none mb-10"
                >
                  Subir
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className=" text-white text-center mb-3 font-semibold">
          <h1>Recargar página " F5 " Para visualizar los cambios</h1>
        </div>
        {/* Form register ends */}
        {/* Page title ends */}
        <div className="container mx-auto px-6">
          {/* Remove class [ h-64 ] when adding a card block */}
          {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
          <div className="w-full sm:px-6 rounded">
            <div className=" px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
              <div className="sm:flex items-center justify-between">
                <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                  Reportes
                </p>
              </div>
            </div>
            <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
              {/* search start */}
              <form>
                <label
                  for="default-search"
                  class="mb-2 text-sm font-medium text-gray-900 sr-only "
                >
                  Search
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500 \"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    id="default-search"
                    class="block  p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                    placeholder="Search Email ..."
                    required
                  />
                </div>
              </form>
              {/* search end */}
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="h-16 w-full text-sm leading-none text-gray-800">
                    <th className="font-normal text-center pl-4">Email</th>
                    <th className="font-normal text-center pl-4">Fecha</th>
                    <th className="font-normal text-center pl-4">Reportes</th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {currentPosts
                    .filter((estado_de_cuenta) => {
                      return search.toLocaleLowerCase() === ""
                        ? estado_de_cuenta
                        : estado_de_cuenta.nombre
                            .toLocaleLowerCase()
                            .includes(search);
                    })
                    .map((estado_de_cuenta) => (
                      <tr
                        key={estado_de_cuenta.id}
                        className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
                      >
                        <td className="font-medium  items-center text-center  ">
                          {estado_de_cuenta.nombre}
                        </td>
                        <td className="font-medium  items-center text-center ">
                          {estado_de_cuenta.timeStamp.split("T")[0]}-
                          {
                            estado_de_cuenta.timeStamp
                              .split("T")[1]
                              .split(".")[0]
                          }
                        </td>
                        <div className=" text-center pt-5">
                          <a
                            href={estado_de_cuenta.imagen}
                            class="bg-yellow-400 hover:bg-yellow-300 text-white font-bold py-2 px-4 rounded inline-flex"
                          >
                            <AiOutlineFilePdf />
                          </a>
                        </div>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* Pagination star */}

            <Pagination
              totalPosts={estados_de_cuenta.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
            />
            {/* Pagination  ends */}
          </div>
        </div>
        {/* Page table  ends */}
      </div>
    </div>
  );
};

export default AdmInterfaceEstadosCuenta;
