import React from "react";
import CaractGen from "../components/CaractGen";

function CaracteristicasGenerales() {
  return (
    <div>
      <CaractGen />
    </div>
  );
}

export default CaracteristicasGenerales;
