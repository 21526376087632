import React, { useState } from "react";

const Basico = () => {
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [open5, setOpen5] = useState(false);
  const [open6, setOpen6] = useState(false);

  return (
    <div className=" lg:container lg:mx-auto lg:py-16 md:py-12 md:px-6 py-12 px-4 ">
      <h1 className="text-center lg:text-4xl text-3xl lg:leading-9 leading-7 text-gray-800 font-semibold">
        PROGRAMA BASICO
      </h1>

      <div className=" lg:mt-12 bg-gray-100 md:mt-10 mt-8 lg:py-7 lg:px-6 md:p-6 py-6 px-4 lg:w-8/12 w-full mx-auto ">
        <div className=" flex justify-between md:flex-row flex-col ">
          <div className=" md:mb-0 mb-8 md:text-left text-center">
            <h2 className=" font-medium text-xl leading-5 text-gray-800 lg:mb-2 mb-4">
              6 Beneficios
            </h2>
            <p className=" font-normal text-lg leading-5 text-gray-600   ">
              Los siguientes apartados son todas las características que
              contiene el programa <strong>BÁSICO</strong>
            </p>
          </div>
        </div>
      </div>
      <div className="lg:w-8/12 w-full mx-auto">
        {/* <!-- Question 1 --> */}
        <hr className=" w-full lg:mt-10 md:mt-12 md:mb-8 my-8" />

        <div className="w-full md:px-6  ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className=" ">
              <p className="flex justify-center items-center font-medium text-lg leading-6 md:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  1.
                </span>{" "}
                Bienestar Financiero
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen(!open)}
            >
              <svg
                className={"transform " + (open ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: De 9 am a 6 pm. CDMX de lunes a viernes.</li>
              <li>
                Asesoría sobre: los mejores métodos para generar un bienestar
                financiero que le permita llevar un mejor manejo de sus finanzas
                para corto y largo plazo y mantener una "salud financiera”.{" "}
              </li>
              <li>Medios: vía telefónica o plataforma de video.</li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>Elaborar un presupuesto personal y familiar.</li>
              <li>Planeación de gastos mensuales.</li>
              <li>Conocer opciones de crédito para el consumo. </li>
              <li>Cómo reducir sus deudas de consumo.</li>
              <li>Alternativas de ahorro a corto plazo. </li>
              <li>Cómo crear un fondo para emergencias.</li>
              <li>Establecer objetivos y prioridades financieras. </li>
              <li>Cómo crear un ahorro para la educación de sus hijos. </li>
              <li>Cómo incrementar su fondo para el retiro. </li>
              <li>
                Lograr tener finanzas personales sanas y gozar de "salud
                financiera".{" "}
              </li>
              <li>
                Este servicio no tiene costo ni límite de eventos para el{" "}
                <strong>BENEFICIARIO</strong> durante la vigencia de su programa
                de beneficios.
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                Cualquier otra persona quiera hacer uso del servicio y no tenga
                la calidad del <strong>BENEFICIARIO</strong>.{" "}
              </li>
              <li>
                Si el <strong>BENEFICIARIO</strong> necesita una consulta para
                su contabilidad como persona física o moral.
              </li>
              <li>
                Si el <strong>BENEFICIARIO</strong> solicita este servicio para
                establecer una estrategia fiscal.
              </li>
              <li>
                Se le puede canalizar con un Profesional en la materia, Contador
                / Fiscalista con un costo preferencial.{" "}
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 2 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  2.
                </span>{" "}
                Telemedicina Activa
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen2(!open2)}
            >
              <svg
                className={"transform " + (open2 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open2 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 24 horas del día y los 365 días del año.</li>
              <li>
                Consultas sobre: problemas médicos, dudas con relación a la
                utilización de medicamentos, síntomas y/o molestias que le
                aquejen.
              </li>
              <li>
                Medios: telemedicina vía telefónica o plataforma de video.
              </li>
              <li>
                Resolver dudas respecto a: tratamientos, enfermedades o
                padecimientos, vacunas, medidas de prevención y atención.{" "}
              </li>
              <li>
                Objetivo: identificar el tipo de padecimiento, detectar si es
                crónico degenerativo, para darle un seguimiento continuo.{" "}
              </li>
              <li>
                Enfermedades crónico-degenerativas: Diabetes Mellitus e
                Hipertensión Arterial, o presente factores de riesgo para el
                desarrollo de las mismas.{" "}
              </li>
              <li>
                Atención especial a <strong>BENEFICIARIOS</strong> de la 3a.
                Edad que no recuerdan como tomar los medicamentos, envío de sus
                estudios y tener presente el avance de su enfermedad. Ofrecemos
                una ayuda muy útil para este tipo de pacientes, como su
                "conciencia de su salud".
              </li>
              <li>
                Damos acompañamiento a el <strong>BENEFICIARIO</strong>, le
                informamos el vencimiento del consumo de sus medicamentos y se
                le pregunta si requiere un recordatorio para sus próximos
                estudios, tratamientos o medicamentos.
              </li>
              <li>
                Somos su "médico de cabecera" llevamos un expediente
                electrónico, de su historial clínico, tratamientos y su
                documentación queda registrada.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>
                Video consulta o consulta telefónica de primera vez previa cita,
                bajo el siguiente esquema: Conocimiento médico - paciente.{" "}
                <td></td>Reclamación de datos para la historia clínica.
                <td></td>Estadificación y clasificación de la enfermedad.
                <td></td>Establecimiento de metas individualizadas de acuerdo a
                las condiciones de cada caso.
                <td></td>Educación acerca de la enfermedad para el{" "}
                <strong>BENEFICIARIO</strong> y familiares.
                <td></td>Educación respecto a hábitos alimenticios y de
                actividad física.
              </li>

              <li>
                Seguimiento: mensual, trimestral, semestral o en el período de
                tiempo que amerite cada caso.{" "}
              </li>
              <li>
                Envío de información, educación continua y recordatorios
                respecto al padecimiento, tratamiento y complicaciones, de forma
                individualizada, de acuerdo a las necesidades de cada persona y
                de sus enfermedades.{" "}
              </li>
              <li>
                seguimiento activo vía telefónica o videoconferencia, en caso de
                descontrol, dudas o mal apego al tratamiento.{" "}
              </li>
              <li>
                Posibilidad de incluir apoyo multidisciplinario, médico,
                nutricional, dental, físico y psicológico, por medio de un
                complemento del servicio. (La atención medica dental es
                exclusivo de los niveles Elite y Premium).{" "}
              </li>
              <li>
                Se expedirá receta para medicamentos de venta libre y a la
                aceptación de la misma está sujeta a las políticas comerciales
                por parte de la farmacia donde se pretenda surtir.
              </li>
              <li>
                Incluye el recordatorio vía telefónica para la ingesta de
                medicamentos previa solicitud del <strong>BENEFICIARIO</strong>,
                esta alerta se suspenderá a petición expresa por el mismo medio
                de comunicación.{" "}
              </li>
              <li>
                Este servicio no tiene costo, ni límite de eventos para el
                BENEFICIARIO durante la vigencia de su programa de beneficios{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>BENEFICIARIO</strong> se encuentre en un caso de
                urgencia extrema y tome este servicio como única medida de
                solución.
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> utilice la prestación de este
                servicio para obtener la prescripción de medicamentos
                controlados o psiquiátricos.
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> pretenda el pago o reembolso de
                los medicamentos prescritos.
              </li>
              <li>
                Cualquier otra persona quiera hacer uso del servicio de
                telemedicina activa y no tenga la calidad del{" "}
                <strong>BENEFICIARIO</strong> .
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 3 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  3.
                </span>
                Nutricional
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen3(!open3)}
            >
              <svg
                className={"transform " + (open ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open3 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: 9 am. a 6 pm. CDMX De lunes a viernes.</li>
              <li>
                Asesoría sobre: problemas o dudas que tenga y estén relacionados
                con hábitos alimenticios.
              </li>
              <li>Medios: video consulta o de manera telefónica.</li>
              <li>
                Requisitos previos: tener una cinta métrica y una báscula
                analógica o digital, para dar a la nutrióloga la información de
                apertura de su expediente.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>Cálculo de peso ideal y del índice de masa corporal. </li>
              <li>Diseño de dietas.</li>
              <li>
                Orientación telefónica respecto a suplementos, desórdenes
                alimenticios y referencias con especialistas.{" "}
              </li>
              <li>
                Información sobre eventos de nutrición y alimentación sana.{" "}
              </li>
              <li>
                Consejos sobre nutrición (técnicas o métodos para llevar una
                alimentación sana).
              </li>
              <li>
                Asesoría, recomendaciones para prevención y manejo de factores
                de riesgo para personas que padecen colitis, gastritis,
                diabetes, triglicéridos, colesterol alto, sobrepeso,
                sedentarismo, etc.
              </li>
              <li>Orientación de dieta balanceada.</li>
              <li>
                Orientación para mujeres embarazadas y en período de lactancia.
              </li>
              <li>Incluye plan alimenticio personalizado. </li>
              <li>
                Este servicio no tiene costo, ni límite de eventos durante la
                vigencia de su programa de beneficios.
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>BENEFICIARIO</strong> solicite esta asistencia para
                situaciones críticas o requiera de una consulta en nutrición de
                forma personal.
              </li>
              <li>
                Se le podrá ofrecer un servicio con cita en consultorio con
                costo preferencial para el <strong>BENEFICIARIO</strong>.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 4 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6  ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  4.
                </span>
                Psicologia
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen4(!open4)}
            >
              <svg
                className={"transform " + (open4 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open4 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: de 9 am a 6 pm. CDMX de lunes a sábado. </li>
              <li>
                Asesoría sobre: problemas o dudas que tenga en materia de
                psicología.
              </li>
              <li>Medios: video consulta o de manera telefónica.</li>
            </ul>
            <h1 className="mt-3 font-semibold">SERVICIOS </h1>
            <ol class="list-decimal">
              <li>Desarrollo individual y grupal.</li>
              <li>
                Prevención y ayuda de trastornos psicológicos como depresión,
                ansiedad, estrés, adicciones, etc.{" "}
              </li>
              <li>
                Asesoría psicológica para problemas escolares y cambio de
                hábitos de los hijos.{" "}
              </li>
              <li>
                Apoyo psicológico para resolver problemas de pareja, relaciones
                interpersonales, separaciones, divorcios, violencia
                intrafamiliar, etc.{" "}
              </li>
              <li>
                Referencia de centros de ayuda psicológica estatales y
                particulares.
              </li>
              <li>
                Apoyo a nivel familiar, de pareja o individual, en situaciones
                de tipo emocional con el fin de obtener la ayuda apropiada de
                acuerdo a su caso.
              </li>
              <li>Ayuda en duelo y pérdidas emocionales.</li>
              <li>Salud mental para los trabajadores y estrés laboral. </li>
              <li>
                Este servicio no tiene costo ni límite de eventos para el
                BENEFICIARIO durante la vigencia de su programa de beneficios.
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">EXCLUSIONES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>BENEFICIARIO</strong> recurra a esta asistencia como
                único medio para solicitar ayuda en casos severos o intente
                atentar contra su vida.
              </li>
            </ol>
          </div>
        </div>

        {/* <!-- Question 5 --> */}

        <hr className=" w-full lg:mt-10 my-8" />

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  5.
                </span>
                Fitness
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen5(!open5)}
            >
              <svg
                className={"transform " + (open5 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open5 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>Horario: de 9 am. A 6 pm. CDMX de lunes a sábado.</li>
              <li>
                Asesoría sobre: rutinas, ejercicios o dudas que tenga y estén
                relacionados con el acondicionamiento físico.
              </li>
            </ul>
            <h1 className="mt-3 font-semibold">
              SERVICIOS (El entrenador podrá brindar información sobre:)
            </h1>
            <ol class="list-decimal">
              <li>
                Adaptaciones fisiológicas derivadas de la práctica del
                ejercicio.{" "}
              </li>
              <li>
                Resistencia, fuerza, tono muscular y beneficios para la salud.{" "}
              </li>
              <li>Estructura de una sesión de acondicionamiento físico. </li>
              <li> Aumento en la intensidad de una sesión de ejercicios.</li>
              <li>Características generales de la estructura muscular.</li>
              <li>Medición de los avances físicos que se han tenido. </li>
              <li>
                Medición del índice de masa muscular y porcentaje de grasa.{" "}
              </li>
              <li>Consejos, orientación y seguimiento. </li>
              <li>
                Referencia de cadenas de gimnasios, horarios, precios y
                promociones.{" "}
              </li>
              <li>
                Este servicio no tiene costo ni límite de eventos para el{" "}
                <strong>BENEFICIARIO</strong> durante la vigencia de su programa
                de beneficios{" "}
              </li>
            </ol>
            <h1 className="mt-3 font-semibold">BENEFICIOS ADICIONALES </h1>
            <ol class="list-decimal">
              <li>
                El <strong>BENEFICIARIO</strong> tendrá acceso por medio de la
                página web personalizada, a una plataforma de videos que le
                servirá para comenzar o continuar con la realización de rutinas
                de ejercicios con el fin de ejercitar su cuerpo. Para mejorar
                paulatinamente su condición física, salud y aspecto físico,
                desde la comodidad de su hogar.{" "}
                <a
                  href="https://qualityassist.mx/asistencia-fitness/"
                  class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                >
                  https://qualityassist.mx/asistencia-fitness/
                </a>
              </li>
              <li>
                El <strong>BENEFICIARIO</strong> podrá acceder las veces que sea
                necesario a la plataforma y cualquier horario, para dar
                seguimiento a sus rutinas, para poder trabajar todas las partes
                de su cuerpo y tener un mejor desempeño y resultados en su
                ejercicio.{" "}
              </li>
            </ol>
          </div>
        </div>

        <hr className=" w-full lg:mt-10 my-8" />
        {/* <!-- Question 5 --> */}

        <div className="w-full md:px-6 ">
          <div
            id="mainHeading"
            className="flex justify-between items-center w-full"
          >
            <div className="">
              <p className="flex justify-center items-center font-medium text-lg leading-6 lg:leading-4 text-gray-800">
                {" "}
                <span className="  lg:mr-6 mr-4 lg:text-2xl md:text-xl text-lg leading-6 md:leading-5 lg:leading-4 font-semibold text-gray-800">
                  6.
                </span>
                Club de Descuentos Electronicos y Cupones Digitales
              </p>
            </div>
            <button
              aria-label="toggler"
              className="focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800"
              onClick={() => setOpen6(!open6)}
            >
              <svg
                className={"transform " + (open6 ? "rotate-180" : "rotate-0")}
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6 9L12 15L18 9"
                  stroke="black"
                  strokeWidth="1.33333"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </button>
          </div>
          <div
            id="menu"
            className={"mt-6 w-full " + (open6 ? "block" : "hidden")}
          >
            <ul class="list-disc">
              <li>
                Por medio de su página web el <strong>PRESTADOR</strong> pondrá
                a disposición del <strong>BENEFICIARIO</strong> diversos
                servicios en los comercios que tiene afiliados a su plan de
                descuentos electrónicos y cupones digitales.{" "}
              </li>
              <li>
                Como conocer los descuentos y cupones: los detalles y
                restricciones de las promociones, las podrá consultar en la
                página web del programa, el <strong>BENEFICIARIO</strong> se
                obliga a seguir el procedimiento para hacer efectiva la
                promoción o descuento y atender las restricciones de la oferta y
                beneficio.
              </li>
              <li>
                <strong>IMPORTANTE</strong>: El <strong>BENEFICIARIO</strong>{" "}
                acepta y se da por enterado, que todos los meses se realizan
                bajas y altas de descuentos y cupones con cada comercio, por lo
                que deberá seguir las indicaciones de registro, compra, carga
                digital o impresión del cupón. . Garantía: cuando un comercio y
                promoción esté vigente, contará con la garantía de satisfacción,
                en el caso de que un establecimiento no brinde el descuento a el
                BENEFICIARIO. El PRESTADOR se compromete a otorgar una cortesía
                por el valor igual o superior al descuento no otorgado en un
                plazo máximo de 7 días.{" "}
              </li>
              <li>
                Como hacer efectiva la garantía: el{" "}
                <strong>BENEFICIARIO</strong> deberá proporcionar la información
                completa del descuento o cupón rechazado, enviando al email
                <a className=" text-cyan-600"> info@qualityassist.mx</a> copia
                escaneada del ticket de compra, fotografía o captura del cupón
                no aplicado, negocio, sucursal, hora aproximada de la compra,
                así como la razón por la cual no se le hizo efectivo el
                descuento.{" "}
              </li>
              <li>
                Este servicio es ilimitado durante la vigencia de su programa de
                beneficios para el <strong>BENEFICIARIO</strong>.
              </li>
            </ul>
          </div>
        </div>

        <hr className=" w-full lg:mt-10 my-8" />
      </div>
    </div>
  );
};

export default Basico;
