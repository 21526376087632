import React from "react";
import Layout from "../components/UserInterface";
import Sisbar from "../components/SisNavbar";

function SisUser() {
  return (
    <div>
      <Sisbar />
      <Layout />
    </div>
  );
}

export default SisUser;
