import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  collection,
  serverTimestamp,
  getDocs,
  doc,
  setDoc,
  query,
  orderBy,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { createUserWithEmailAndPassword } from "firebase/auth";
import { BsFillShieldLockFill } from "react-icons/bs";
import Pagination from "../components/Pagination";
import emailjs from "@emailjs/browser";

const Adminusers = () => {
  const form = useRef();
  const navigate = useNavigate();
  const [showpass, setShowPass] = useState(false);
  const [show, setShow] = useState(null);
  const [users, setUsers] = useState([]);

  const usersCollection = query(
    collection(db, "users"),
    orderBy("timeStamp", "desc")
  );

  const getUsers = async () => {
    const data = await getDocs(usersCollection);

    setUsers(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    console.log(users);
  };
  // funcion para eliminar

  useEffect(() => {
    getUsers();
  }, []);

  const [search, setSearch] = useState("");
  console.log(search);

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(6);

  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const currentPosts = users.slice(firstPostIndex, lastPostIndex);

  {
    /* Register user */
  }

  const [data, setData] = useState({});
  const [per] = useState(null);

  const handleInput = (e) => {
    const id = e.target.id;
    const value = e.target.value;

    setData({ ...data, [id]: value });
  };

  const handleAdd = async (e) => {
    e.preventDefault();
    try {
      const res = await createUserWithEmailAndPassword(
        auth,
        data.email,
        data.password
      );
      await setDoc(doc(db, "users", res.user.uid), {
        ...data,
        timeStamp: serverTimestamp(),
      });
    } catch (err) {
      console.log(err);
    }
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_36126sn",
        "template_xjvtvhx",
        form.current,
        "0jsvzs7nYDShvp7lC"
      )
      .then(
        (result) => {
          console.log(result.text);
          form.current.reset();
        },
        (error) => {
          console.log(error.text);
        }
      );
  };

  return (
    <div>
      <div className="absolute bg-[#2b3e50] w-full ">
        {/* Page title starts */}
        <div className="my-6 lg:my-12 container px-6 mx-auto flex flex-col lg:flex-row items-start lg:items-center justify-between pb-4 border-b  border-gray-300">
          <div>
            <h4 className="text-2xl font-bold leading-tight text-white">
              Base de datos de usuarios
            </h4>
            <ul className="flex flex-col md:flex-row items-start md:items-center text-white text-sm mt-3">
              <li className="flex items-center mr-3 mt-3 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-paperclip"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <path d="M15 7l-6.5 6.5a1.5 1.5 0 0 0 3 3l6.5 -6.5a3 3 0 0 0 -6 -6l-6.5 6.5a4.5 4.5 0 0 0 9 9 l6.5 -6.5" />
                  </svg>
                </span>
                <span>Archivos</span>
              </li>
              <li className="flex items-center mr-3 mt-3 md:mt-0">
                <span className="mr-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="icon icon-tabler icon-tabler-trending-up"
                    width={16}
                    height={16}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" />
                    <polyline points="3 17 9 11 13 15 21 7" />
                    <polyline points="14 7 21 7 21 14" />
                  </svg>
                </span>
                <span> Base de datos</span>
              </li>
            </ul>
          </div>
        </div>
        {/* Form register starts */}
        <form onSubmit={handleAdd}>
          <div className=" flex  items-center justify-center ">
            <div className="     rounded  py-10 ">
              <div className="md:flex items-center mt-8">
                <div className="md:w-72 flex flex-col">
                  <label className="text-base font-semibold leading-none text-white">
                    Nombre completo:
                  </label>
                  <input
                    tabIndex={0}
                    id="displayName"
                    type="text"
                    onChange={handleInput}
                    placeholder=""
                    required
                    className="text-base leading-none text-black p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-white border rounded border-gray-200 placeholder-gray-100 "
                  />
                </div>
                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                  <label className="text-base font-semibold leading-none text-white">
                    Correo electrónico:
                  </label>
                  <input
                    tabIndex={0}
                    arial-label="Please input country name"
                    role="input"
                    id="email"
                    type="email"
                    onChange={handleInput}
                    placeholder="example@mail.com"
                    className="text-base leading-none text-black p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-white border rounded border-gray-200 placeholder-gray-100"
                    required
                  />
                </div>
              </div>
              <div className="md:flex items-center mt-8">
                <div className="md:w-72 flex flex-col">
                  <label className="text-base font-semibold leading-none text-white">
                    Celular:
                  </label>
                  <input
                    tabIndex={0}
                    role="input"
                    arial-label="Please input company name"
                    id="phone"
                    type="text"
                    onChange={handleInput}
                    placeholder=""
                    required
                    className="text-base leading-none text-black p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-white border rounded border-gray-200 placeholder-gray-100 "
                  />
                </div>
                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                  <label className="text-base font-semibold leading-none text-white">
                    Contraseña:
                  </label>
                  <div className="relative flex items-center justify-center">
                    <input
                      required
                      id="password"
                      onChange={handleInput}
                      tabIndex={0}
                      type={showpass ? "text" : "password"}
                      className="bg-white border rounded text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                    />
                    <div
                      onClick={() => setShowPass(!showpass)}
                      className="absolute right-0 mt-2 mr-3 cursor-pointer"
                    >
                      <div id="show">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.99978 2C11.5944 2 14.5851 4.58667 15.2124 8C14.5858 11.4133 11.5944 14 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 8C1.41378 4.58667 4.40511 2 7.99978 2ZM7.99978 12.6667C9.35942 12.6664 10.6787 12.2045 11.7417 11.3568C12.8047 10.509 13.5484 9.32552 13.8511 8C13.5473 6.67554 12.8031 5.49334 11.7402 4.64668C10.6773 3.80003 9.35864 3.33902 7.99978 3.33902C6.64091 3.33902 5.32224 3.80003 4.25936 4.64668C3.19648 5.49334 2.45229 6.67554 2.14844 8C2.45117 9.32552 3.19489 10.509 4.25787 11.3568C5.32085 12.2045 6.64013 12.6664 7.99978 12.6667ZM7.99978 11C7.20413 11 6.44106 10.6839 5.87846 10.1213C5.31585 9.55871 4.99978 8.79565 4.99978 8C4.99978 7.20435 5.31585 6.44129 5.87846 5.87868C6.44106 5.31607 7.20413 5 7.99978 5C8.79543 5 9.55849 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.79565 10.6837 9.55871 10.1211 10.1213C9.55849 10.6839 8.79543 11 7.99978 11ZM7.99978 9.66667C8.4418 9.66667 8.86573 9.49107 9.17829 9.17851C9.49085 8.86595 9.66644 8.44203 9.66644 8C9.66644 7.55797 9.49085 7.13405 9.17829 6.82149C8.86573 6.50893 8.4418 6.33333 7.99978 6.33333C7.55775 6.33333 7.13383 6.50893 6.82126 6.82149C6.5087 7.13405 6.33311 7.55797 6.33311 8C6.33311 8.44203 6.5087 8.86595 6.82126 9.17851C7.13383 9.49107 7.55775 9.66667 7.99978 9.66667Z"
                            fill="#71717A"
                          />
                        </svg>
                      </div>
                      <div id="hide" className="hidden">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-eye-off"
                          width={16}
                          height={16}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#27272A"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={3} y1={3} x2={21} y2={21} />
                          <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                          <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
                <a
                  onClick={() => navigate("/pass")}
                  target="_blank"
                  className=" text-3xl text-yellow-400 cursor-pointer px-5 pt-5 "
                >
                  <BsFillShieldLockFill />
                </a>
              </div>

              <div className="md:w-72 flex  items-center pt-5">
                <label className="text-base font-semibold leading-none text-white px-5">
                  Rol
                </label>
                <select
                  tabIndex={0}
                  id="rol"
                  onChange={handleInput}
                  required
                  className="text-base leading-none text-gray-900 p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-bg-white border rounded border-gray-200 placeholder-gray-100"
                >
                  <option value="admin">Admin</option>
                  <option value="especial">Especial</option>
                  <option value="beneficiario">Beneficiario</option>
                  <option value="inversionista">Inversionista</option>
                </select>
              </div>

              <div className="flex items-center justify-center w-full">
                <button
                  disabled={per !== null && per < 100}
                  type="submit"
                  className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-blue-400 hover:bg-blue-300 rounded  focus:ring-2 focus:ring-offset-2 focus:ring-[#1e49a6] focus:outline-none"
                >
                  Registrar
                </button>
              </div>
            </div>
          </div>
        </form>
        <div className=" text-white text-center mb-4 font-semibold ">
          <h1>Recargar página " F5 " Para Terminar de registrar el usuario</h1>
        </div>

        {/* Form register ends */}
        {/* Form email starts */}
        <form ref={form} onSubmit={sendEmail}>
          <div className=" flex  items-center justify-center ">
            <div className="     rounded  py-10  ">
              <div className="md:flex items-center mt-8">
                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                  <label className="text-base font-semibold leading-none text-white">
                    Correo electrónico:
                  </label>
                  <input
                    id="backus_mail"
                    name="backus_mail"
                    type="email"
                    placeholder="example@mail.com"
                    className="text-base leading-none text-black p-3 focus:oultine-none focus:border-indigo-700 mt-4 bg-white border rounded border-gray-200 placeholder-gray-100"
                    required
                  />
                </div>
                <div className="md:w-72 flex flex-col md:ml-6 md:mt-0 mt-4">
                  <label className="text-base font-semibold leading-none text-white">
                    Contraseña:
                  </label>
                  <div className="relative flex items-center justify-center">
                    <input
                      required
                      id="backus_pass"
                      name="backus_pass"
                      type={showpass ? "text" : "password"}
                      className="bg-white border rounded text-xs font-medium leading-none text-gray-800 py-3 w-full pl-3 mt-2"
                    />
                    <div
                      onClick={() => setShowPass(!showpass)}
                      className="absolute right-0 mt-2 mr-3 cursor-pointer"
                    >
                      <div id="show">
                        <svg
                          width={16}
                          height={16}
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M7.99978 2C11.5944 2 14.5851 4.58667 15.2124 8C14.5858 11.4133 11.5944 14 7.99978 14C4.40511 14 1.41444 11.4133 0.787109 8C1.41378 4.58667 4.40511 2 7.99978 2ZM7.99978 12.6667C9.35942 12.6664 10.6787 12.2045 11.7417 11.3568C12.8047 10.509 13.5484 9.32552 13.8511 8C13.5473 6.67554 12.8031 5.49334 11.7402 4.64668C10.6773 3.80003 9.35864 3.33902 7.99978 3.33902C6.64091 3.33902 5.32224 3.80003 4.25936 4.64668C3.19648 5.49334 2.45229 6.67554 2.14844 8C2.45117 9.32552 3.19489 10.509 4.25787 11.3568C5.32085 12.2045 6.64013 12.6664 7.99978 12.6667ZM7.99978 11C7.20413 11 6.44106 10.6839 5.87846 10.1213C5.31585 9.55871 4.99978 8.79565 4.99978 8C4.99978 7.20435 5.31585 6.44129 5.87846 5.87868C6.44106 5.31607 7.20413 5 7.99978 5C8.79543 5 9.55849 5.31607 10.1211 5.87868C10.6837 6.44129 10.9998 7.20435 10.9998 8C10.9998 8.79565 10.6837 9.55871 10.1211 10.1213C9.55849 10.6839 8.79543 11 7.99978 11ZM7.99978 9.66667C8.4418 9.66667 8.86573 9.49107 9.17829 9.17851C9.49085 8.86595 9.66644 8.44203 9.66644 8C9.66644 7.55797 9.49085 7.13405 9.17829 6.82149C8.86573 6.50893 8.4418 6.33333 7.99978 6.33333C7.55775 6.33333 7.13383 6.50893 6.82126 6.82149C6.5087 7.13405 6.33311 7.55797 6.33311 8C6.33311 8.44203 6.5087 8.86595 6.82126 9.17851C7.13383 9.49107 7.55775 9.66667 7.99978 9.66667Z"
                            fill="#71717A"
                          />
                        </svg>
                      </div>
                      <div id="hide" className="hidden">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="icon icon-tabler icon-tabler-eye-off"
                          width={16}
                          height={16}
                          viewBox="0 0 24 24"
                          strokeWidth="1.5"
                          stroke="#27272A"
                          fill="none"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                          <line x1={3} y1={3} x2={21} y2={21} />
                          <path d="M10.584 10.587a2 2 0 0 0 2.828 2.83" />
                          <path d="M9.363 5.365a9.466 9.466 0 0 1 2.637 -.365c4 0 7.333 2.333 10 7c-.778 1.361 -1.612 2.524 -2.503 3.488m-2.14 1.861c-1.631 1.1 -3.415 1.651 -5.357 1.651c-4 0 -7.333 -2.333 -10 -7c1.369 -2.395 2.913 -4.175 4.632 -5.341" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center w-full">
                <button
                  type="submit"
                  value="Submit"
                  className="mt-9 text-base font-semibold leading-none text-white py-4 px-10 bg-blue-400 hover:bg-blue-300 rounded  focus:ring-2 focus:ring-offset-2 focus:ring-[#1e49a6] focus:outline-none"
                >
                  Enviar correo
                </button>
              </div>
            </div>
          </div>
        </form>

        {/* Form email ends */}
        {/* Page title ends */}
        <div className="container mx-auto px-6">
          {/* Remove class [ h-64 ] when adding a card block */}
          {/* Remove class [ border-dashed border-2 border-gray-300 ] to remove dotted border */}
          <div className="w-full sm:px-6 rounded">
            <div className=" px-4 md:px-10 py-4 md:py-7 bg-gray-100 rounded-tl-lg rounded-tr-lg">
              <div className="sm:flex items-center justify-between">
                <p className="text-base sm:text-lg md:text-xl lg:text-2xl font-bold leading-normal text-gray-800">
                  Usuarios
                </p>
              </div>
            </div>
            <div className="bg-white shadow px-4 md:px-10 pt-4 md:pt-7 pb-5 overflow-y-auto">
              {/* search start */}
              <form>
                <label
                  for="default-search"
                  class="mb-2 text-sm font-medium text-gray-900 sr-only "
                >
                  Search
                </label>
                <div class="relative">
                  <div class="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                    <svg
                      class="w-4 h-4 text-gray-500 \"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 20 20"
                    >
                      <path
                        stroke="currentColor"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                        d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                      />
                    </svg>
                  </div>
                  <input
                    onChange={(e) => setSearch(e.target.value)}
                    type="search"
                    id="default-search"
                    class="block  p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 "
                    placeholder="Search Email ..."
                    required
                  />
                </div>
              </form>
              {/* search end */}
              <table className="w-full whitespace-nowrap">
                <thead>
                  <tr className="h-16 w-full text-sm leading-none text-gray-800">
                    <th className="font-normal text-center pl-15">Nombre</th>
                    <th className="font-normal text-center pl-15">Email</th>
                    <th className="font-normal text-center pl-15">
                      Contraseña
                    </th>
                    <th className="font-normal text-center pl-15">Celular</th>
                    <th className="font-normal text-center pl-15">Rol</th>
                  </tr>
                </thead>
                <tbody className="w-full">
                  {currentPosts
                    .filter((user) => {
                      return search.toLocaleLowerCase() === ""
                        ? user
                        : user.email.toLocaleLowerCase().includes(search);
                    })
                    .map((user) => (
                      <tr
                        key={user.id}
                        className="h-20 text-sm leading-none text-gray-800 bg-white hover:bg-gray-100 border-b border-t border-gray-100"
                      >
                        <td className="font-medium  items-center text-center  ">
                          {user.displayName}
                        </td>
                        <td className="font-medium  items-center text-center ">
                          {user.email}
                        </td>
                        <td className="font-medium  items-center text-center ">
                          {user.password}
                        </td>
                        <td className="font-medium  items-center  text-center ">
                          {user.phone}
                        </td>
                        <td className="font-medium  items-center  text-center ">
                          {user.rol}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
            {/* Pagination star */}

            <Pagination
              totalPosts={users.length}
              postsPerPage={postsPerPage}
              setCurrentPage={setCurrentPage}
            />
            {/* Pagination  ends */}
          </div>
        </div>
        {/* Page table  ends */}
      </div>
    </div>
  );
};

export default Adminusers;
