import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const conservadora = [
  { fecha: "ene-24", canualizada: 6, cetes: 11.29 },
  { fecha: "feb-24", canualizada: 18, cetes: 11.05 },
  { fecha: "mzo-24", canualizada: 20.4, cetes: 11.02 },
  { fecha: "abr-24", canualizada: 21.6, cetes: 10.97 },
  { fecha: "may-24", canualizada: 19.2, cetes: 10.99 },
  { fecha: "jun-24", canualizada: 20.4, cetes: 10.97 },
  { fecha: "jul-24", canualizada: 21, cetes: 10.92 },
  { fecha: "Ago-24", canualizada: 21.6, danualizada: 60, cetes: 10.65 },
  { fecha: "Sep-24", canualizada: 21, danualizada: 60, cetes: 10.44 },
  { fecha: "Oct-24", canualizada: 20.4, danualizada: 42, cetes: 10.24 },
];

export const Charts = () => {
  return (
    <div className=' justify-items-center  sm:relative lg:grid'>
      <div className=' sm:w-90 w-90 lg:w-2/3 '>
        <ResponsiveContainer aspect={2}>
          <LineChart
            data={conservadora}
            width={500}
            height={300}
            margin={{ top: 10, right: 10, left: 0, bottom: 5 }}
          >
            <CartesianGrid strokeDasharray='1 1' />
            <XAxis dataKey='fecha' />
            <YAxis />
            <Tooltip />
            <Legend />

            <Line
              type='monotone'
              dataKey='canualizada'
              name='Conservadora tasa anualizada 
              '
              stroke='#14de2c'
              strokeWidth={2}
            />
            <Line
              type='monotone'
              dataKey='danualizada'
              name='Dinámica tasa anualizada 
              '
              stroke='#1e49a6'
              strokeWidth={2}
            />
            <Line
              type='monotone'
              dataKey='cetes'
              name='CETES a 28 días, tasa anualizada 
              '
              stroke='#e3971e'
              strokeWidth={2}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};
export default Charts;
