import React from "react";
import { useNavigate } from "react-router-dom";
import Art1 from "../../assets/img-blog/blog-1.jpg";
import Art2 from "../../assets/img-blog/blog-1.1.jpg";

const Artic1 = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="">
        <div className="dark:bg-gray-900">
          <div className="mx-auto container w-full flex xl:flex-row flex-col justify-between items-start mt-12 px-6 lg:px-0">
            <div className="flex flex-col justify-start items-start xl:w-2/4 ">
              <div>
                <h2 className="text-gray-800 dark:text-white lg:text-3xl text-2xl font-bold leading-7 pt-8">
                  ¿POR QUÉ SALUD INTEGRAL?
                </h2>
                <h2 className="text-gray-800 dark:text-white  text-2xl font-bold leading-7 pt-8">
                  ¡Hola! Hemos iniciado nuestro BLOG compartiendo información
                  que confiamos sea de interés para todos . . .
                </h2>
              </div>
              <div className="mt-8">
                <p className="text-gray-800 dark:text-white lg:text-base text-sm leading-normal">
                  En SMART FINANCES como profesionales en finanzas personales y
                  operadores financieros, hemos desarrollado un proyecto que
                  ofrece una alternativa real y accesible para mejorar nuestra
                  salud financiera y como parte esencial de nuestro bienestar
                  personal hemos incluido el mejor producto en el mercado para
                  cuidar tu salud física, de esta manera te ofrecemos la
                  solución para mejorar tu calidad de vida y la de tus seres
                  queridos.
                </p>
                <p className="text-gray-800 dark:text-white lg:text-base text-sm leading-normal pt-5">
                  Analicemos las dos áreas de nuestro proyecto: salud financiera
                  y salud física, a través de una salud integral.{" "}
                </p>
                <p className="text-gray-800 dark:text-white lg:text-base text-sm leading-normal pt-5">
                  La <strong>salud financiera</strong> se refiere a la capacidad
                  de una persona o empresa para administrar sus recursos
                  financieros de manera efectiva y sostenible.{" "}
                  <a
                    href="https://economipedia.com/definiciones/salud-financiera.html"
                    class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                    target="_blank"
                  >
                    Una buena salud financiera permite conseguir bienestar
                    económico, hacer frente a los gastos presentes y futuros, y
                    a conseguir los objetivos vitales de cada uno.
                  </a>
                </p>
              </div>
              <div className="mt-8 w-full">
                <img className="w-full" src={Art1} alt="office" />
              </div>
              <div className="mt-8 flex justify-start items-start flex-col">
                <div>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm font-semibold leading-none">
                    Recuerda que tener buena salud financiera te permite:
                  </p>
                </div>
                <div className="text-gray-800 dark:text-white mt-4 lg:text-base text-sm leading-normal">
                  <ul>
                    <li className="flex justify-start items-start space-x-1 flex-row">
                      <div>-</div>
                      <div>
                        Pasar más tiempo con las personas que te importan.
                      </div>
                    </li>
                    <li className="flex justify-start items-start space-x-1 flex-row">
                      <div>-</div>
                      <div>Cambiar de trabajo cuando lo necesites.</div>
                    </li>
                    <li className="flex justify-start items-start space-x-1 flex-row">
                      <div>-</div>
                      <div>Dormir tranquilo.</div>
                    </li>
                    <li className="flex justify-start items-start space-x-1 flex-row">
                      <div>-</div>
                      <div>Viajar más o conseguir tus objetivos vitales.</div>
                    </li>
                    <li className="flex justify-start items-start space-x-1 flex-row">
                      <div>-</div>
                      <div>Planificar mejor tu jubilación.</div>
                    </li>
                    <li className="flex justify-start items-start space-x-1 flex-row">
                      <div>-</div>
                      <div>Comprar mejor.</div>
                    </li>
                    <li className="flex justify-start items-start space-x-1 flex-row">
                      - Evitar estafas.
                    </li>
                    <li className="flex justify-start items-start space-x-1 flex-row">
                      - Combatir la inflación.
                    </li>
                  </ul>
                </div>
                <div>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm font-bold leading-none pt-8">
                    Para analizar si tienes una buena salud financiera,
                    considera estos pilares sobre los que se sustentan estos
                    indicadores:
                  </p>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm  leading-none pt-5">
                    <strong>Gastos</strong>
                    <ul>
                      <li className="flex justify-start items-start space-x-1 flex-row">
                        • Gastar menos de lo que ingresa.
                      </li>
                      <li className="flex justify-start items-start space-x-1 flex-row">
                        • Pagar las facturas en tiempo y forma.
                      </li>
                    </ul>
                  </p>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm leading-none pt-5">
                    <strong>Ahorro</strong>
                    <ul>
                      <li className="flex justify-start items-start space-x-1 flex-row">
                        • Tener suficientes ahorros líquidos para cubrir tus
                        gastos (fondo de emergencia).
                      </li>
                      <li className="flex justify-start items-start space-x-1 flex-row">
                        • Tener suficientes activos de largo plazo.
                      </li>
                    </ul>
                  </p>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm  leading-none pt-5">
                    <strong>Deuda</strong>
                    <ul>
                      <li className="flex justify-start items-start space-x-1 flex-row">
                        • Tener un nivel de deuda sostenible.
                      </li>
                      <li className="flex justify-start items-start space-x-1 flex-row">
                        • Tener un alto rating de crédito (el banco o cualquier
                        otro prestamista cuando te va a dar un préstamo evalúa
                        tu solvencia y en base a ello establece un rating).
                      </li>
                    </ul>
                  </p>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm  leading-none pt-5">
                    <strong>Plan</strong>
                    <ul>
                      <li className="flex justify-start items-start space-x-1 flex-row">
                        • Contar con un seguro adecuado.
                      </li>
                      <li className="flex justify-start items-start space-x-1 flex-row">
                        • Tener una buena planificación de gasto.
                      </li>
                    </ul>
                  </p>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm  leading-none pt-5">
                    Fuente: economipedia{" "}
                  </p>
                </div>
                <div className="mt-8 flex-col justify-start items-start">
                  <div>
                    <img className="w-full" src={Art2} alt="girl" />
                  </div>
                  <div className="mt-8">
                    <h2 className="text-gray-800 dark:text-white  text-2xl font-bold leading-7 pt-8">
                      ¿Has considerado que tu salud física es parte de una salud
                      integral? Veamos cómo puedes lograrlo:
                    </h2>
                    <p className="text-gray-800 dark:text-white lg:text-base text-sm leading-normal pt-8 ">
                      <a
                        href="https://www.cultivarsalud.com/psicologia/salud-integral-que-es-importancia-y-como-conseguirla/"
                        class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                        target="_blank"
                      >
                        Es importante cuidar todos los aspectos de nuestra vida.
                        La salud integral se refiere a un estado completo de
                        bienestar que abarca aspectos físicos, mentales,
                        emocionales y sociales. Para alcanzarla, es importante
                        mantener un equilibrio adecuado en cada una de estas
                        dimensiones.
                      </a>
                      Aquí te presentamos algunos consejos para lograr una buena
                      salud integral:
                    </p>
                  </div>
                  <div className="text-gray-800 dark:text-white mt-4 lg:text-base text-sm leading-normal py-8">
                    <ul>
                      <li className=" justify-start items-start space-x-1 flex-row">
                        <strong> • Mantén una alimentación balanceada: </strong>
                        <a
                          href="https://www.nationalgeographicla.com/familia/2022/10/7-habitos-saludables-para-cuidar-el-cuerpo"
                          class=" text-blue-600 underline dark:text-blue-500 hover:no-underline"
                          target="_blank"
                        >
                          La Organización Panamericana de la Salud recomienda
                          consumir al menos cinco porciones de frutas y verduras
                          al día.
                        </a>
                      </li>
                      <li className=" justify-start items-start space-x-1 flex-row">
                        <strong> • Haz ejercicio regularmente: </strong>{" "}
                        <a
                          href="https://www.cultivarsalud.com/psicologia/salud-integral-que-es-importancia-y-como-conseguirla/"
                          class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                          target="_blank"
                        >
                          La OMS recomienda realizar al menos 150 minutos de
                          actividad física moderada o 75 minutos de actividad
                          física vigorosa por semana.
                        </a>
                      </li>
                      <li className=" justify-start items-start space-x-1 flex-row">
                        <strong>• Duerme lo suficiente: </strong>{" "}
                        <a
                          href="https://www.cultivarsalud.com/psicologia/salud-integral-que-es-importancia-y-como-conseguirla/"
                          class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                          target="_blank"
                        >
                          Asegúrate de dormir al menos 7 horas cada noche y
                          trata de mantener un horario regular de sueño.
                        </a>
                      </li>
                      <li className=" justify-start items-start space-x-1 flex-row">
                        <strong>
                          {" "}
                          • Mantén relaciones interpersonales saludables:{" "}
                        </strong>{" "}
                        <a
                          href="https://www.cultivarsalud.com/psicologia/salud-integral-que-es-importancia-y-como-conseguirla/"
                          class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                          target="_blank"
                        >
                          Busca mantener una red de apoyo sólida y relaciones
                          positivas con amigos y familiares.
                        </a>
                      </li>
                      <li className=" justify-start items-start space-x-1 flex-row">
                        <strong> • Maneja el estrés: </strong>{" "}
                        <a
                          href="https://www.cultivarsalud.com/psicologia/salud-integral-que-es-importancia-y-como-conseguirla/"
                          class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                          target="_blank"
                        >
                          Busca formas saludables de manejar el estrés, como la
                          meditación, el yoga o la respiración profunda.
                        </a>
                      </li>
                      <li className=" justify-start items-start space-x-1 flex-row">
                        •{" "}
                        <strong>
                          {" "}
                          Evita el consumo excesivo de sustancias dañinas:{" "}
                        </strong>{" "}
                        <a
                          href="https://www.cultivarsalud.com/psicologia/salud-integral-que-es-importancia-y-como-conseguirla/"
                          class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                          target="_blank"
                        >
                          Trata de limitar tu consumo de estas sustancias o
                          evitarlas por completo.
                        </a>
                      </li>
                      <li className=" justify-start items-start space-x-1 flex-row">
                        •{" "}
                        <strong>
                          Busca ayuda profesional si la necesitas:{" "}
                        </strong>{" "}
                        <a
                          href="https://www.cultivarsalud.com/psicologia/salud-integral-que-es-importancia-y-como-conseguirla/"
                          class="font-medium text-blue-600 underline dark:text-blue-500 hover:no-underline"
                          target="_blank"
                        >
                          Un profesional de la salud puede ayudarte a encontrar
                          las herramientas y recursos que necesitas para mejorar
                          tu salud.
                        </a>
                      </li>
                    </ul>
                  </div>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm leading-normal">
                    Recuerda que la salud integral es un proceso continuo y que
                    requiere tiempo, esfuerzo y paciencia.
                  </p>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm leading-normal">
                    <strong>
                      Porque es importante tener una buena salud integral:
                    </strong>{" "}
                    nuestro bienestar físico y mental es fundamental para poder
                    desarrollar plenamente nuestras actividades, hacerle frente
                    al estrés de la vida diaria, ser productivos en el ámbito
                    laboral y personal, y con ello contribuir positivamente con
                    la sociedad.
                  </p>
                  <p className="text-gray-800 dark:text-white lg:text-base text-sm leading-normal pt-8 py-8">
                    <strong>En resumen</strong>, la salud integral y la salud
                    financiera son importantes porque están interconectadas y
                    afectan directa o indirectamente nuestra calidad de vida.
                    Mantener un equilibrio adecuado en cada una de estas
                    dimensiones es fundamental para lograr un estado de
                    bienestar óptimo.
                  </p>
                </div>
              </div>
            </div>
            <div className="md:px-8 px-4 md:px-0 lg:px-16 mt-10 xl:mt-0 h-full xl:w-2/5 w-full flex justify-center items-center bg-gradient-to-l from-[#1e49a6] to-[#1e49a6]  pt-8 ">
              <div className="flex flex-col lg:justify-start justify-center lg:items-start items-center my-10">
                <div>
                  <p className="md:text-2xl text-lg font-semibold text-center lg:text-left leading-normal text-white">
                    Compártenos tu opinión a contacto@smartsfinances.info
                  </p>
                </div>
                <div className="mt-8">
                  <p className="md:text-base text-xs text-center lg:text-left leading-normal text-white">
                    “¿Buscas consejos prácticos para mejorar tu salud financiera
                    y física? ¡Tenemos justo lo que necesitas!
                  </p>
                </div>
                <div className="mt-8 flex flex-row justify-start items-center space-x-4">
                  <div className>
                    <button
                      className="btn focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1e49a6] text-xs lg:text-base border border-white py-2 px-4 md:py-4 md:px-8 bg-white rounded-sm text-[#1e49a6] hover:bg-gray-100"
                      onClick={() => navigate("/")}
                    >
                      Inicio
                    </button>
                  </div>
                  <div className>
                    <button
                      className="btn focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#1e49a6] text-xs lg:text-base border border-white py-2 px-4 md:py-4 md:px-8 text-white rounded-sm hover:bg-white hover:text-[#1e49a6]"
                      onClick={() => navigate("/blog-menu")}
                    >
                      Consulta mas de nuestros articulos
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Artic1;
