import React from "react";
import { useNavigate } from "react-router-dom";

function Index() {
  const navigate = useNavigate();
  return (
    <div className="pt-0">
      <div className="bg-gradient-to-tl from-green-400 to-indigo-900 w-full py-30 px-4 pt-10">
        <div className="container mx-auto">
          <div className="w-4/5 mx-auto mb-12">
            <h1 className="xl:text-4xl text-3xl text-center text-white mb-4 font-extrabold">
              PROGRAMAS DE BENEFICIOS PARA PLANES EMPRESARIALES{" "}
            </h1>
            <p className="text-xl text-center text-white font-normal">
              Como parte de la Norma Oficial Mexicana (NOM) 035, obliga a las
              empresas a atender los riesgos psicosociales en los espacios de
              trabajo y cuidar la integridad, salud física y mental de los
              trabajadores, todo estos aspectos que benefician a las
              organizaciones. Te ofrecemos nuestra amplia gama de PROGRAMAS DE
              BENEFICIO para cuidar de tus colabordores y cumplir con la norma
              oficial.{" "}
            </p>
            <div
              className="flex justify-center mt-8 items-center"
              role="button"
            >
              <p className="mr-2 text-lg font-bold text-white">NIVELES</p>
            </div>
          </div>
          <div className="w-11/12 mx-auto">
            <div className="xl:flex lg:flex items-end">
              <div className="xl:flex lg:flex md:flex sm:flex shadow">
                <div className="bg-white pt-8 pb-8 lg:mb-0 xl:mb-0 md:mb-0 sm:mb-0 mb-2 pl-6 pr-6 flex flex-col xl:w-1/3 lg:w-1/3 justify-center items-center border-r border-l border-gray-200">
                  <div className="mb-6">
                    <img src="https://cdn.tuk.dev/assets/paper-plane.png" alt />
                  </div>
                  <p className="text-center text-2xl font-bold text-gray-800 mb-3">
                    Básico
                  </p>
                  <p className="text-center text-sm text-black mb-6 font-normal w-full">
                    Cubre tu salud financiera de manera personalizada y la salud
                    fisica integral.
                  </p>
                  <button
                    onClick={() => navigate("/priceem")}
                    className="focus:outline-none bg-[#1e49a6] transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-6 py-2 text-sm border"
                  >
                    Contratar
                  </button>
                </div>
                <div className="bg-white pt-8 pb-8 lg:mb-0 xl:mb-0 md:mb-0 sm:mb-0 mb-2 pl-6 pr-6 flex flex-col xl:w-1/3 lg:w-1/3 justify-center items-center border-r border-l border-gray-200">
                  <div className="mb-5">
                    <img src="https://cdn.tuk.dev/assets/plane.png" alt />
                  </div>
                  <p className="text-center text-2xl font-bold text-gray-800 mb-3">
                    Intermedio
                  </p>
                  <p className="text-center text-sm text-black mb-6 font-normal w-full">
                    Adicional incluimos tu atención por emergencias, te cuidamos
                    legalmente y atendemos a tu mascota.
                  </p>
                  <button
                    onClick={() => navigate("/priceem")}
                    className="focus:outline-none bg-[#1e49a6] transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-6 py-2 text-sm border"
                  >
                    Contratar
                  </button>
                </div>
                <div className="bg-white pt-8 pb-8 lg:mb-0 xl:mb-0 md:mb-0 sm:mb-0 mb-2 pl-6 pr-6 flex flex-col xl:w-1/3 lg:w-1/3 justify-center items-center border-r border-l border-gray-200">
                  <div className="mb-6">
                    <img
                      src="https://cdn.tuk.dev/assets/start-button.png"
                      alt
                    />
                  </div>
                  <p className="text-center text-2xl font-bold text-gray-800 mb-3">
                    Elite
                  </p>
                  <p className="text-center text-sm text-black mb-6 font-normal w-full">
                    Extendemos los beneficios para tu familia en salud,
                    asistencia funeraria integral dispositivos digitales ,
                    emergencias en tu hogar, servicio de grúa y mucho más.
                  </p>
                  <button
                    onClick={() => navigate("/priceem")}
                    className="focus:outline-none bg-[#1e49a6] transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-6 py-2 text-sm border"
                  >
                    Contratar
                  </button>
                </div>
                <div className="bg-white pt-8 pb-8 lg:mb-0 xl:mb-0 md:mb-0 sm:mb-0 mb-2 pl-6 pr-6 flex flex-col xl:w-1/3 lg:w-1/3 justify-center items-center border-r border-l border-gray-200">
                  <div className="mb-6">
                    <img
                      src="https://cdn.tuk.dev/assets/start-button.png"
                      alt
                    />
                  </div>
                  <p className="text-center text-2xl font-bold text-gray-800 mb-3">
                    Premium{" "}
                  </p>
                  <p className="text-center text-sm text-black mb-6 font-normal w-full">
                    Nuestro Programa más completo, atiende a tu familia,
                    adicionalmente te aseguramos a ti como titular. Los cuidamos
                    con asistencia vial, en viajes por carretera y aereos dentro
                    de la republica mexicana.
                  </p>
                  <button
                    onClick={() => navigate("/priceem")}
                    className="focus:outline-none bg-[#1e49a6] transition duration-150 ease-in-out hover:bg-indigo-600 rounded text-white px-6 py-2 text-sm border"
                  >
                    Contratar
                  </button>
                </div>
              </div>
            </div>
            <div className="shadow">
              <div>
                <div className="flex items-center w-full">
                  <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white w-3/12">
                    Info{" "}
                  </p>
                  <p className="w-3/12 text-sm text-center text-white font-bold ">
                    Cubre
                  </p>
                  <p className="w-3/12 text-sm text-center text-white font-bold ">
                    Básico
                  </p>
                  <p className="w-3/12 text-sm text-center text-white font-bold ">
                    Intermedio
                  </p>
                  <p className="w-3/12 text-sm text-center text-white font-bold ">
                    Elite
                  </p>
                  <p className="w-3/12 text-sm text-center text-white font-bold ">
                    Premium
                  </p>
                </div>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm font-bold text-gray-800 break-words">
                        A quienes incluimos:
                      </td>
                      <td className="w-3/12 border bg-gray-200  p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Titular
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Titular
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        *Titular Soltero
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        *Titular Soltero y sus Padres
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border bg-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border bg-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border bg-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        {" "}
                      </td>
                      <td className="w-3/12 border bg-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        {" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        *Titular Casado Conyuge e hijos{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        *Titular Casado Conyuge e hijos{" "}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Finanzas
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        Bienestar Financiero
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="text-center pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  SALUD
                </p>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Ambulancia
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        Servicio por emergencia médica o accidente
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        {" "}
                        2 eventos SIN costo al año
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Salud
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        Telemedicina activa
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        Nutricional
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        Ayuda psicológica
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        Fitness con plataforma de videos Wellness
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Asistencia Dental
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * 1er. Consulta gratis de diagnostico
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Radiografías Periapicales sin costo
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Estudios y tratamientos hasta con 30% de descuento
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Asistencia Vision
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Consulta diagnóstico gratis
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Demás tratamientos a costo preferencial
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Lentes niños armazón-estuche $399
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Lentes adultos armazón-estuche $799
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Red Medica con costos preferenciales
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        *Estudios de laboratorio (descuentos del 10 al 50%)
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Medicamentos (descuentos solo farmacias on-line)
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Clínicas y Hospitales (descuentos del 5 al 15%)
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Consultas medicas
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * A domicilio de médico general{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por familia, costo $200
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Médico general de $200 a $300{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Médico especialista de $350 a $450
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Médico alta especialidad de $450 a $650
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Asistencia Funeraria
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border font-bold border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Costo del servicio{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        $30,000 MXN{" "}
                      </td>

                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        $50,000 MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Asesoría legal y administrativa En caso de
                        fallecimiento violento, el DEUDO RESPONSABLE podrá
                        solicitar la presencia de un abogado para gestionar los
                        tramites legales{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Sucesoria y pensiones (para los deudos en primer
                        grado) o DEUDO RESPONSABLE, en materias : civil,
                        familiar, mercantil y laboral.{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Tanatológica Ayuda en el duelo y la pérdida emocional
                        por el deceso de su ser querido.
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        ** Ataúd{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Acondicionamiento estético del cuerpo
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Traslado del cuerpo al lugar de velación
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Sala de velación o implementos para velación en su
                        casa{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Traslado al cementerio{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * En su caso, servicio de Cremación y Urna estándar para
                        cenizas
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Actualización constante a los deudos del finado{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Asistencia telefónica ilimitada 24/7
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Repatriación del extranjero, incluye tramite
                        administrativos y legales{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>

                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        ** Ataúd estándar
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos por período
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Club de descuentos y cupones
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Descuentos del 10 al 30% en: Papelería, educación,
                        accesorios para el automóvil, arts. Para el hogar, arts.
                        Y servicios para mascotas, belleza y cuidado personal,
                        entretenimiento, cines, teatros, escuelas, academias,
                        cursos, restaurantes, viajes y turismo{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Plataforma E-Learning
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Contenidos exclusivos de cursos completos en video de:
                        estilo de vida, fotografía, música, negocios, salud y
                        fitness.{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Proteccion Patrimonial
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        ** Asistencia emergencia en el hogar Plomero, cerrajero,
                        electricista, cambio de vidrios{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos al año combinados{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        $800 MXN{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        $1,000 MXN
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Teleconsulta legal En materia penal, civil, familiar y
                        mercantil.
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Asistencia Dispositivos Digitales Si el usuario tiene
                        problemas técnicos con su computadora o algún otro
                        dispositivo digital de uso personal, podrá solicitar
                        asistencia por medio de llamada telefónica o de chat
                        para que pueda que un técnico pueda ayudarle.
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Seguros
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Accidentes personales Muerte accidental $150,000 MXN
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Gastos médicos por accidente $20,000 MXN
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Hasta agotar la suma asegurada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Vida por $100,000
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Proteccion Mascotas (Cobertura a nivel nacional, en las
                  principales ciudades del país.)
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Orientación veterinaria telefónica de emergencia{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Asistencia para localización
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Asistencia legal telefónica por tenencia del perro
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Descuentos en artículos y accesorios
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Consultas a costo preferencial
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Consultas veterinarias
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos sin costo
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Vacuna antirrábica o desparasitación interna
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos sin costo
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Hotel para mascotas
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos de 10 dias sin costo
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Pet Funeral Servicio de cremación{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento sin costo
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Asistencia Vial
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Grúa{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos hasta por $1,500 MXN
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Asistencia legal automovilística por el hecho de
                        tránsito (sólo aplica para vehículos de uso particular y
                        cuyo peso sea menor a 1.5 toneladas).{" "}
                        <strong>
                          {" "}
                          Cuando derivado de un accidente de tránsito se inicie
                          una carpeta de investigación, se asignará a un abogado
                          para que se constituya como defensor del usuario. En
                          caso de requerir la libertad del conductor o de la
                          unidad, las garantías deben ser aportadas por el
                          Usuario.{" "}
                        </strong>{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Con costo preferencial
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Taxi seguro por accidente o por robo del automóvil.{" "}
                        <strong>
                          {" "}
                          Una vez que se haya iniciado la carpeta de
                          investigación que corresponda, se asignará un taxi ya
                          sea de aplicación o convencional para que traslade al
                          usuario por única vez de la agencia del ministerio
                          público a su domicilio.{" "}
                        </strong>
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Ilimitada hasta un costo de $200 por evento
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Cerrajero automotriz.{" "}
                        <strong>
                          Cuando el usuario extravíe la llave de su vehículo o
                          la haya olvidado dentro del mismo, se asignará a un
                          cerrajero automotriz para qué abra su automóvil.{" "}
                        </strong>
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento SIN costo por año
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Asistencia Vial{" "}
                        <strong>
                          {" "}
                          Paso de corriente, envío de gasolina, cambio de
                          llanta.{" "}
                        </strong>
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 eventos al año a elegir, SIN costo{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Asistencia en viajes nacionales ( Cobertura individual con
                  duración máxima de 7 días, km. 100 )
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Arrastre de grúa al taller más cercano
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento hasta $1,500
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Referencia de talleres mecánicos y eléctricos
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        sin limite durante el viaje
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Localización de refacciones mecánicas y/o eléctricas.{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        sin limite durante el viaje
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Regreso anticipado por fallecimiento de un familiar en
                        primer grado
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento hasta $3,000
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Médico general al hotel
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento hasta $1,500
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Asistencia dental por urgencia
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento hasta $1,500
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Hotel por convalecencia
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Hasta 3 noches, hasta $4,500
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Traslado de un familiar por convalecencia
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento hasta $3,000
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Concierge de viaje
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Sin límite durante el viaje
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Envío de datos celulares por urgencia
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        2 Gigas{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <div>
                <p className="pl-4 pt-3 pb-3 font-bold text-sm text-white">
                  Asistencia en viajes nacionales aereos
                </p>
                <table className="sm:table-fixed table-auto w-full bg-white">
                  <tbody>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Localización y envío de equipaje
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        Sin límite durante el viaje{" "}
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                    <tr>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-xs sm:text-sm text-gray-800 break-words">
                        * Alimentos y accesorios de viaje por vuelo cancelado
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        1 evento hasta $500
                      </td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800"></td>
                      <td className="w-3/12 border border-gray-200 p-2 sm:p-4 text-center text-xs sm:text-sm text-gray-800">
                        <div className="h-2 w-2 rounded-full bg-indigo-700 mx-auto" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Index;
